import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Article from '../Article/Article';

const CardContent = ({
  type,
  children,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const pendingHolidaylist = useCallback(() => {
    if (otherProps.list !== undefined) {
      const start = 0;
      const end = otherProps.list.length < (5) ? otherProps.list.length : 5;

      const tab = [];

      for (let i = start; i < end; i++) {
        const item = otherProps.list[i];

        tab.push(
          <li
            key={i}
            className="card-listItem--holiday"
          >
            <p className="card-listItem--holidayElement">{item.startDate}</p>
            <i className="fas fa-arrow-right card-listItem--holidayElement" />
            <p className="card-listItem--holidayElement">{item.endDate}</p>
            <p className="card-listItem--holidayElement">{item.holidayType}</p>
            <p className="card-listItem--holidayElement">{item.username}</p>
          </li>,
        );
      }
      return tab;
    }
  }, [otherProps.list]);

  switch (type) {
    case 'list':
      return (
        <div>
          <ul>
            {otherProps.list.map((item, key) => (
              <li key={key} className="card-listItem">{item}</li>
            ))}
          </ul>
        </div>
      );

    case 'article':
      return (
        <Article
          isWiki={false}
          isCard
          isBlog
          title={otherProps.articleTitle}
          contributor={otherProps.contributor}
          date={otherProps.date}
        />
      );

    case 'pendingHolidays':
      return (
        <>
          <>
            {otherProps.list.length >= 1
              ? <ul>{pendingHolidaylist()}</ul> : (
                <li className="card-listItem">{t('dashboard.noPendingHolidays')}</li>
              )}
          </>
        </>
      );

    default:
      return (children);
  }
};

CardContent.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

CardContent.defaultProps = {
  type: '',
  children: null,
};

const Card = ({
  type,
  title,
  onClick,
  children,
  prepareScrollRight,
  ...otherProps
}) => (
  <div
    className={`card card-container ${prepareScrollRight === true && 'prepare-scroll-right'}
      ${type === 'pendingHolidays' && 'pendingHolidays'}`}
    onClick={onClick}
  >
    {title && (
    <div className="card-title">
      <h2 className="title is-2">{title}</h2>
    </div>
    )}
    <CardContent
      type={type}
      {...otherProps}
    >
      {children}
    </CardContent>
  </div>
);

Card.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  prepareScrollRight: PropTypes.bool,

};

Card.defaultProps = {
  type: '',
  title: '',
  onClick() {},
  children: null,
  prepareScrollRight: false,

};

export default Card;
