import { rootloader } from 'common/components/RootLoader/RootLoader';
import { useAuth } from 'common/contexts/auth';
import Page404 from 'common/pages/Page404';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const getUserRoutes = (routes, user) => routes.map(route => {
  let { component } = route;
  const {
    path, roles = null, exact = true,
  } = route;

  if (roles) {
    if (user && user.role) {
      if (!roles.includes(user.role.name)) {
        component = Page404;
      }
    } else {
      return (
        <Route
          key={path}
          path={path}
          render={() => (
            <Redirect to="/login" />
          )}
        />
      );
    }
  }

  return <Route key={path} path={path} exact={exact} component={component} />;
});

const Routes = ({ routes }) => {
  const { user, refreshUser, isUserRefreshing } = useAuth();

  useEffect(() => {
    refreshUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userRoutes = useMemo(() => getUserRoutes(routes, user), [routes, user]);

  return isUserRefreshing ? rootloader()
    : (
      <Switch>
        {userRoutes}
      </Switch>
    );
};

Routes.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Routes;
