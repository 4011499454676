import useFetch from 'common/hooks/useFetch';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from '../Select/Select';

// filters: ['paramKey']

export const RemoteSelect = ({
  onDataLoaded, url, filters, processOptions,
  additionnalOptions, ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [params, setParams] = useState({});

  let paramsUpdated = false;
  const newParams = {};

  filters.forEach((f) => {
    newParams[f] = rest[f];
    if (newParams[f] !== params[f]) {
      paramsUpdated = true;
    }
  });

  if (paramsUpdated) {
    setParams(newParams);
  }

  const getUrl = () => {
    const esc = encodeURIComponent;
    const queryParams = Object.keys(params)
      .filter((key) => params[key] != null)
      .map((key) => `${esc(key)}=${esc(params[key])}`).join('&');

    return `${url}${queryParams ? '?' : ''}${queryParams}`;
  };

  const { data, isFetching } = useFetch(getUrl());

  useEffect(() => {
    let newData = data && Array.isArray(data) ? [...data] : [];

    if (data && !newData.length) newData.push(data);
    onDataLoaded && onDataLoaded(newData);

    if (additionnalOptions.length && Array.isArray(newData)) {
      newData = [...additionnalOptions, ...newData];
    }

    const opts = processOptions ? processOptions(newData) : newData;

    setOptions(opts);
  }, [data, setOptions, additionnalOptions, processOptions, onDataLoaded]);

  return (
    <Select
      options={options}
      isLoading={isFetching}
      {...rest}
    />
  );
};

RemoteSelect.propTypes = {
  url: PropTypes.string.isRequired,
  filters: PropTypes.array,
  processOptions: PropTypes.func,
  onDataLoaded: PropTypes.func,
  additionnalOptions: PropTypes.array,
};

RemoteSelect.defaultProps = {
  filters: [],
  processOptions: () => {},
  onDataLoaded: () => {},
  additionnalOptions: [],
};

export default RemoteSelect;
