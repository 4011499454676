import moment from './moment';

const formatDate = (translator, item, format = 'D') => {
  if (moment(item.start_date).isSame(item.end_date)) {
    return `Le ${moment(item.start_date).format(format)} ${translator(`date.${item.start_date_type}`)} `;
  }

  return translator('date.full_string', {
    startDate: moment(item.start_date).format(format),
    startDateType: item.start_date_type === 'afternoon' ? translator('date.midday') : translator('date.morning'),
    endDate: moment(item.end_date).format(format),
    endDateType: item.end_date_type === 'morning' ? translator('date.midday') : translator('date.evening'),
  });
};

export default formatDate;
