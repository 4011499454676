import React from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import MenuLink from '../MenuLink';

const ManagerMenu = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();

  return (
    <>
      <ul className="menu-list">
        <li>
          <MenuLink to="/">
            <span className="icon">
              <i className="fas fa-table" />
            </span>
            <span>{t('dashboard.title')}</span>
          </MenuLink>

          { !currentCompany.disabledModules?.timeReport && (
          <MenuLink to="/holidays-planner">
            <span className="icon">
              <i className="fas fa-calendar" />
            </span>
            <span>{t('common.planning')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.trombi && (
          <MenuLink to="/trombinoscope">
            <span className="icon">
              <i className="fas fa-users" />
            </span>
            <span>{t('common.trombinoscope')}</span>
          </MenuLink>
          )}

          <hr />

          { !currentCompany.disabledModules?.holidays && (
          <MenuLink to="/holidays">
            <span className="icon">
              <i className="fas fa-plane-departure" />
            </span>
            <span>{t('common.vacations')}</span>
          </MenuLink>
          )}

          {/* si manager peut remplir le CRA des employés, pas besoin que le timeReport du manager soit "true" */}
          { !currentCompany.disabledModules?.timeReport && (
          <MenuLink to="/working-days">
            <span className="icon">
              <i className="fas fa-clock" />
            </span>
            <span>{t('common.enter')}</span>
          </MenuLink>
          )}

          { (!currentCompany.disabledModules?.holidays
            || !currentCompany.disabledModules?.timeReport) && (
            <MenuLink to="/summary" className="manager-menu-item">
              <span className="icon">
                <i className="fas fa-calculator" />
              </span>
              <span>{t('common.summary')}</span>
            </MenuLink>
          )}

          <hr />

          { !currentCompany.disabledModules?.tasks && (
          <MenuLink to="/my-tasks">
            <span className="icon">
              <i className="fas fa-check" />
            </span>
            <span>{t('common.mytodos')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.tasks && (
          <MenuLink to="/todos" className="manager-menu-item">
            <span className="icon">
              <i className="fas fa-arrow-circle-right" />
            </span>
            <span>{t('common.todos')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.timeReport && (
          <MenuLink to="/projects" className="manager-menu-item">
            <span className="icon">
              <i className="fas fa-drafting-compass" />
            </span>
            <span>{t('common.projects')}</span>
          </MenuLink>
          )}

          <hr />

          { !currentCompany.disabledModules?.blog && (
          <MenuLink to="/blog">
            <span className="icon">
              <i className="fas fa-bookmark" />
            </span>
            <span>{t('blog.headingTitle')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.wiki && (
          <MenuLink to="/wiki">
            <span className="icon">
              <i className="fas fa-info" />
            </span>
            <span>{t('blog.wiki')}</span>
          </MenuLink>
          )}

          <hr />
        </li>
      </ul>

    </>
  );
};

export default ManagerMenu;
