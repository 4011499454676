/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (result) => result.map((job) => ({ value: job.id, label: job.name, job }));

const JobSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="jobs"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.job') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default JobSelect;
