import {
  useCallback, useEffect,
} from 'react';
import useFetch from './useFetch';

const useItem = (url, itemId) => {
  const {
    fetchData,
    data,
    error,
    isFetching,
    isPreviousData,
    setData,
  } = useFetch(url, { sharePromise: false, manual: true });

  const fetchItem = useCallback((id) => fetchData({ url: `${url}/${id}`, method: 'GET' }),
    [fetchData, url]);

  const saveItem = useCallback((payload) => fetchData({ method: 'POST', payload }),
    [fetchData]);

  const removeItem = useCallback((id) => fetchData({ url: `${url}/${id}`, method: 'DELETE' }),
    [fetchData, url]);

  const updateItem = useCallback((id, payload) => fetchData({ url: `${url}/${id}`, method: 'PUT', payload }),
    [fetchData, url]);

  useEffect(() => {
    if (itemId) {
      fetchItem(itemId);
    }
    setData(null);
  }, [fetchItem, setData, itemId]);

  return {
    isFetching,
    item: data,
    error,
    isPreviousItem: isPreviousData,
    fetchItem,
    saveItem,
    updateItem,
    removeItem,
  };
};

export default useItem;
