import moment from 'moment';

const dateRange = (
  t, startDate, endDate, startDateType, endDateType,
) => {
  let startDateState = '';

  let endDateState = '';

  let range = '';

  if (startDateType === 'afternoon' && startDate === endDate) {
    startDateState = t('common.afternoon');
    range = `${moment(startDate).format('DD/MM')} - ${startDateState}`;
  } else if (endDateType === 'morning' && startDate === endDate) {
    startDateState = t('common.morning');
    range = `${moment(startDate).format('DD/MM')} - ${startDateState}`;
  } else if (startDate === endDate) {
    range = moment(startDate).format('DD/MM');
  }

  if (startDate !== endDate) {
    if (endDateType === 'morning') {
      endDateState = `- ${t('common.morning')}`;
    }
    if (startDateType === 'afternoon') {
      startDateState = `- ${t('common.afternoon')}`;
    }
    range = `${t('date.from')} 
    ${moment(startDate).format('DD/MM')} 
    ${startDateState} 
    ${t('date.to')} 
    ${moment(endDate).format('DD/MM')} 
    ${endDateState}`;
  }

  return range;
};

export default dateRange;
