import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import useItem from 'common/hooks/useItem';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectForm from 'requests/pages/Projects/ProjectForm';
import Loader from '../../../common/components/Loader/Loader';

const Project = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id: projectId } = router.match.params;
  const { setAlert } = useContext(AlertsContext);
  const {
    saveItem, updateItem, item, error, isFetching,
  } = useItem('projects', projectId);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/projects');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async project => {
    if (project.id) {
      await updateItem(project.id, project);
      back();
    } else {
      await saveItem(project);
      back();
    }
  }, [updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/projects',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      {isFetching ? (
        <Loader isScreen processing={isFetching} />
      ) : (
        <section className="section form-page has-cards">
          <ProjectForm onSubmit={onSubmit} project={item} />
        </section>
      )}
    </>
  );
};

export default Project;
