import Loader from 'common/components/Loader/Loader';
import Modal from 'common/components/Modal/Modal';
import useList from 'common/hooks/useList';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import { Link } from 'react-router-dom';

const SelectModelModal = ({
  closeModal,
}) => {
  const { t } = useTranslation();

  const {
    items,
    isFetching,
  } = useList('todo-templates', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'todotemplatesmodal' });

  return (
    <Modal closeModal={closeModal} className="select-task-modal">

      <div className="modal-top">
        <h1>
          {t('todolist.selectTaskModel')}
        </h1>
        <p>{t('todolist.selectTaskModelLegend')}</p>
      </div>

      <div className="modal-main">
        {isFetching ? (
          <Loader isScreen permanant />
        ) : (
            items?.length > 0 ? (
              items.map((item, i) => (
                <Link
                  className="select-model-item"
                  to={{ pathname: '/todos/add', query: { item } }}
                  icon="plus"
                  key={`template-${i}`}
                >
                  <div className="columns">
                    <div className="column">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          className="crud-item-avatar"
                        >
                          <span>{item.name[0]}</span>
                        </div>
                        <b>{item.name}</b>
                      </div>
                    </div>

                    <div className="column">
                      {item.subitems?.length > 0 ? (
                        <ul className="table-inner-list">
                          { item.subitems.map((subitem, j) => (
                            <li className="table-inner-list-item" key={`template-${i}-${j}`}>
                              <i className="fas fa-check color-primary" />
                              <span>{subitem.name}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <span>{t('todolist.noSubitems')}</span>
                      )}
                    </div>

                    <div className="column">
                      <button className="discret-button">
                        {t('todolist.selectThisModel')}
                      </button>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="no-model">
                <p>{t('todolist.noTemplate')}</p>
                <Link className="button is-submit" to="/todo-templates/add">
                  <i className="fas fa-pen" style={{ marginRight: '0.5rem' }} />
                  {t('todolist.createFirstModel') }
                </Link>
              </div>

            )
        )}

      </div>
    </Modal>
  );
};

SelectModelModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default SelectModelModal;
