import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const ActivitiesReports = createContext();
const useActivitiesReports = () => useContext(ActivitiesReports);

export const ActivitiesReportsProviders = (props) => (
  <ContextProvider
    url="activities-reports"
    context={ActivitiesReports}
    {...props}
  />
);

export default useActivitiesReports;
