import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Select from 'common/components/Select/Select';
import Table from 'common/components/Table/Table';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';
import SelectModelModal from './SelectModelModal/SelectModelModal';

const Todos = () => {
  const { t } = useTranslation();
  const { query } = useLocation();

  const { user: currentUser, isAdmin, isManager } = useAuth();
  const [selectUser, setSelectUser] = useState(null);
  const {
    fetchItems: fetchTodos,
    items: todos,
    isFetching,
    filters,
    setFilter,
    setFilters,
    setPageIndex,
    pageIndex,
    pageCount,
    removeListItem,
  } = useList('todos', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'todos' });

  useEffect(() => {
    if (query?.myList) {
      setSelectUser(currentUser.id);
    }
  }, [query, currentUser]);

  useEffect(() => {
    setFilters({});
    if (selectUser !== null) {
      setFilters({
        user: selectUser,
      });
    }
  }, [selectUser, setFilters]);

  const {
    removeItem,
    updateItem,
  } = useItem('todos');
  const { push } = useRouter();

  const handleDelete = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    fetchTodos();
  }, [removeListItem, fetchTodos, removeItem]);

  const handleChangeTodoStatus = useCallback(async (row) => {
    await updateItem(row.original.id, { ...row.original, state: !row.original.state });
    fetchTodos();
  }, [updateItem, fetchTodos]);

  const handleChangeSubitemStatus = useCallback(async (row, index) => {
    const updatedSubitems = row.original.subitems;

    updatedSubitems[index].state = !updatedSubitems[index].state;
    const missingCheck = updatedSubitems.filter(val => val.state).length < updatedSubitems.length;

    await updateItem(row.original.id, { ...row.original, state: !missingCheck, subitems: updatedSubitems });

    fetchTodos();
  }, [updateItem, fetchTodos]);

  const [showSelectModelModal, setShowSelectModelModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowSelectModelModal(false);
  }, [setShowSelectModelModal]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      className: 'table-start',
      Cell: ({ row: { original: { id, name, user } } }) => (
        <>
          <div
            className="user-avatar"
            style={{
              backgroundImage: user.avatar
                ? `url(${process.env.REACT_APP_API_URL}${user?.avatar.url})` : 'none',
            }}
            onClick={() => push(`/todos/edit/${id}`)}
          >
            {!user.avatar && (
            <span>{user?.username[0]}</span>
            )}
          </div>

          <span className="clickable" onClick={() => push(`/todos/edit/${id}`)}>{name}</span>
        </>
      ),
    },
    {
      Header: t('common.user'),
      accessor: 'user.username',
      filterName: 'user.username_contains',
      hidden: selectUser,
      Cell: ({ row: { original: { id, user } } }) => (
        <span className="clickable" onClick={() => push(`/todos/edit/${id}`)}>{user?.username}</span>
      ),
    },
    {
      Header: t('todolist.state'),
      filterName: 'state',
      filterComponent: (props) => (
        <Select
          options={[{ value: 'true', label: t('todolist.state') },
            { value: 'false', label: t('todolist.stateFalse') }]}
          placeholder={t('todolist.state')}
          isFilter
          {...props}
        />
      ),
      Cell: ({
        row, row: {
          original: {
            state, user, author, subitems,
          },
        },
      }) => {
        const canEdit = (isAdmin || isManager || currentUser?.id === author);
        const assignedToMe = currentUser?.id === user?.id;

        return (
          <Button
            onClick={() => handleChangeTodoStatus(row)}
            icon={state ? 'fas fa-check-circle' : 'dar fa-times-circle'}
            color={state ? 'success' : 'danger'}
            transparent
            disabled={(!canEdit && !assignedToMe) || subitems.length}
            style={{ padding: 0, pointerEvents: subitems.length ? 'none' : 'all' }}
            label={t(`todolist.${state ? 'state' : 'stateFalse'}`)}
          />
        );
      },
    },
    {
      Header: t('todolist.subitems'),
      Cell: ({ row, row: { original: { subitems, user, author } } }) => {
        const countSubitemsDone = subitems?.filter((obj) => obj.state === true).length || 0;
        const canEdit = (isAdmin || isManager || currentUser?.id === author);
        const assignedToMe = currentUser?.id === user?.id;

        return (
          <div>
            {subitems?.length > 0 ? (
              <>
                <span>{countSubitemsDone} / {subitems.length} {t('todolist.subitemsDone')}</span>
                <ul className="table-inner-list">
                  { subitems.map((subitem, index) => (
                    <li className="table-inner-list-item" key={`subitem-list-${index}`}>
                      <Button
                        icon={subitem.state ? 'fas fa-check-circle' : 'dar fa-times-circle'}
                        color={subitem.state ? 'success' : 'danger'}
                        transparent
                        style={{ padding: 0 }}
                        onClick={() => handleChangeSubitemStatus(row, index)}
                        label={subitem.name}
                        disabled={!canEdit && !assignedToMe}
                      />

                    </li>
                  ))}

                </ul>
              </>
            ) : (
              <span>{t('todolist.noSubitems')}</span>
            )}
          </div>
        );
      },
    },
    {
      Header: t('todolist.modifiedOn'),
      Cell: ({ row: { original: { updated_at: updatedAt } } }) => (
        <span>
          <i className="fas fa-clock color-primary" /> &nbsp;
          {moment(updatedAt).format('DD/MM/YY, HH:mm')}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line
      Cell: ({ row: { original: { id, author } } }) => {
        const canEdit = (isAdmin || isManager || currentUser?.id === author);

        return (
          <>
            <Button
              onClick={() => push(`/todos/edit/${id}`)}
              icon="fa-pencil-alt"
              color="primary"
              transparent
            />

            {canEdit && (
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={() => handleDelete(id)}
              icon="fa-trash-alt"
              color="danger"
              confirm
              transparent
              confirmMessage={t('common.delete')}
            />
            )}

          </>
        );
      },
    },
  ], [t, push, handleDelete, handleChangeTodoStatus,
    handleChangeSubitemStatus, isManager, isAdmin, currentUser, selectUser]);

  return (
    <>
      { showSelectModelModal && (
      <SelectModelModal
        closeModal={handleCloseModal}
      />
      )}
      <PageHeader
        title={t('common.todos')}
        link={{ url: '/todos/add', icon: 'plus', label: t('common.add') }}
      >
        <div className="page-header--link">
          <button onClick={() => setShowSelectModelModal(true)}>
            <span className="page-header--link-label">  {t('todolist.addFromModel')}</span>
            <span className="icon page-header--link-button button is-light">
              <i className="fas fa-tag" />
            </span>
          </button>
        </div>
      </PageHeader>

      <section className="section list-page list-action todos-page">
        <Table
          data={todos ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default Todos;
