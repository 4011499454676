import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const RootLoader = ({
  processing, timeoutDuration, isScreen, permanant,
}) => {
  const [state, setState] = useState('idle');
  const timeout = useRef(null);

  useEffect(() => {
    if (processing && state === 'idle') {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        setState('display');
      }, timeoutDuration);
      setState('delay');
    }

    if (processing === false) {
      clearTimeout(timeout.current);
      setState('idle');
    }

    return () => clearTimeout(timeout.current);
  }, [state, timeoutDuration, processing]);

  if (state === 'display' || permanant) {
    return (
      <div className="data-root-loader-box">
        {isScreen ? (
          <div className="data-root-loader">
            <i className="fas fa-spinner fa-2x rotate" />
            <p className="text">Loading content...</p>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }

  return null;
};

RootLoader.propTypes = {
  isScreen: PropTypes.bool,
  processing: PropTypes.bool,
  timeoutDuration: PropTypes.number,
  permanant: PropTypes.bool,
};

RootLoader.defaultProps = {
  isScreen: false,
  processing: true,
  timeoutDuration: 400,
  permanant: false,
};

export default RootLoader;

export const rootloader = () => <RootLoader />;
