import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Select from 'common/components/Select/Select';
import Table from 'common/components/Table/Table';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import useItem from 'common/hooks/useItem';
import useList from 'common/hooks/useList';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useEffect, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
    error,
    removeListItem,
  } = useList('projects', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'projects' });
  const {
    removeItem,
    updateItem,
  } = useItem('projects');

  const { push } = useRouter();
  const { isAdmin, isManager } = useAuth();

  const handleDelete = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    fetchItems();
  }, [removeListItem, fetchItems, removeItem]);

  const handleChangeProjectStatus = useCallback(async (row) => {
    await updateItem(row.original.id, { ...row.original, in_progress: !row.original.in_progress });
    fetchItems();
  }, [updateItem, fetchItems]);

  const { setAlert } = useContext(AlertsContext);

  useEffect(() => {
    if (error) {
      setAlert(error, 'danger');
    }
  }, [error, setAlert]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      className: 'table-start',
      Cell: ({
        row: {
          original: {
            id, name, icon, color,
          },
        },
      }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            onClick={() => push(`/projects/detail/${id}`)}
            className="icon-picker-circle is-edited crud-item-avatar"
            style={{
              backgroundColor: `${color || '#aaaaaa'}`,
            }}
          >
            {icon ? (
              <i className={`fas fa-${icon}`} />
            ) : (
              <span>{name[0]}</span>
            )}

          </div>
          <span className="clickable" onClick={() => push(`/projects/detail/${id}`)}>{name}</span>
        </div>

      ),
    },
    {
      Header: t('common.dailyBilling'),
      filterName: 'daily_billing',
      filterComponent: (props) => (
        <Select
          isFilter
          placeholder={t('common.dailyBilling')}
          {...props}
          options={
        [
          { value: '0', label: t('common.no') },
          { value: '1', label: t('common.yes') },
        ]

      }
        />
      ),
      Cell: ({ row: { original: { daily_billing: dailyBilling } } }) => dailyBilling
        ? (
          <span>{t('common.yes')}</span>
        ) : (
          <span>{t('common.no')}</span>
        ),
    },
    {
      Header: t('projects.daysEstimated'),
      Cell: ({ row: { original: { days_estimated: daysEstimated } } }) => daysEstimated
        ? (
          <>
            <i className="fas fa-clock" style={{ color: '#39D9A9', textAlign: 'center' }} />
            &nbsp;<span>{daysEstimated}</span>&nbsp;<span>{t('projects.days')}</span>
          </>
        ) : (
          <span>{t('common.undefined')}</span>
        ),
    },
    {
      Header: t('projects.inProgress'),
      filterName: 'in_progress',
      filterComponent: (props) => (
        <Select
          isFilter
          placeholder={t('projects.inProgress')}
          {...props}
          options={
        [
          { value: '0', label: t('projects.unactive') },
          { value: '1', label: t('projects.inProgress') },
        ]

      }
        />
      ),
      Cell: ({ row, row: { original: { in_progress: inProgress } } }) => (
        <Button
          onClick={() => handleChangeProjectStatus(row)}
          icon={inProgress ? 'fas fa-check-circle' : 'dar fa-times-circle'}
          color={inProgress ? 'success' : 'danger'}
          confirm
          transparent
          confirmMessage={inProgress ? t('projects.confirmDisable') : t('projects.confirmUndisable')}
          style={{ padding: 0 }}
        />
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line
      Cell: ({ row: { original: { id} } }) => {
        if (!isAdmin && !isManager) return null;

        return (
          <>
            <Button
              onClick={() => push(`/projects/detail/${id}`)}
              icon="fa-eye"
              color="primary"
              transparent
            />

            <Button
              onClick={() => push(`/projects/edit/${id}`)}
              icon="fa-pencil-alt"
              color="primary"
              transparent
            />
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={() => handleDelete(id)}
              icon="fa-trash-alt"
              color="danger"
              confirm
              transparent
              confirmMessage={t('common.delete')}
            />

          </>
        );
      },
    },
  ], [t, push, handleDelete, handleChangeProjectStatus, isAdmin, isManager]);

  return (
    <>
      <PageHeader
        title={t('common.projects')}
        link={{ url: '/projects/add', icon: 'plus', label: t('common.add') }}
        style={{ backgroundColor: 'red' }}
      />
      <section className="section list-page list-action">
        <Table
          data={items ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default Projects;
