import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import fetchJSON from 'common/utils/fetchJSON';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import useSites from 'requests/contexts/sites';
import createPersistedState from 'use-persisted-state';

const AuthContext = createContext();
const usePersistedState = createPersistedState('user');

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const [user, setUser] = usePersistedState(null);
  const { setAlert } = useContext(AlertsContext);
  const router = useRouter();
  const { setCurrentCompany, fetchItem: fetchCompany } = useCompanies();
  const { setCurrentSite } = useSites();
  const [isUserRefreshing, setIsUserRefreshing] = useState(true);
  const isAdmin = useMemo(() => ['superadmin', 'admin'].includes(user?.role?.name), [user]);
  const isManager = useMemo(() => user?.role.name === 'manager', [user]);

  const isSubscriber = useMemo(() => {
    const activeSub = user?.subscriptions?.find(sub => sub.active === true);

    return !!activeSub;
  }, [user]);

  const signin = useCallback(async (email, password) => {
    const payload = {
      identifier: email,
      password,
    };

    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/local`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.ok) {
      const resJson = await res.json();

      window.localStorage.setItem('jwt', resJson.jwt);
      setUser(resJson.user);

      if (resJson?.user && resJson?.user.role !== 'superadmin') {
        setCurrentCompany(resJson.user.company);
        setCurrentSite(resJson.user.site);
      }

      router.push('/');
    } else {
      setAlert(t('auth.badCredentials'), 'danger');
    }
  }, [router, setCurrentCompany, setCurrentSite, setAlert, setUser, t]);

  const signup = useCallback(async ({
    name, email, password, company,
  }) => {
    const payload = {
      username: email,
      name,
      email,
      password,
      role: 'admin',
      company,
    };

    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/local/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.ok) {
      const resJson = await res.json();

      setUser(resJson.user);

      if (resJson?.user && resJson?.user.role !== 'superadmin') {
        setCurrentCompany(resJson.user.company);
        setCurrentSite(resJson.user.site);
      }

      router.push('/');
    }

    // TODO handle register error
  }, [router, setCurrentCompany, setCurrentSite, setUser]);

  const forgotPassword = async (email) => {
    const payload = {
      email,
    };
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      const resJson = await res.json();

      return resJson;
    }

    throw new Error();
  };

  const resetPassword = async (payload) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      const resJson = await res.json();

      return resJson;
    }

    throw new Error();
  };

  const signout = useCallback(() => {
    window.localStorage.removeItem('jwt');
    user && setUser(null);
    window.localStorage.removeItem('currentCompany');
    window.localStorage.removeItem('currentSite');
    window.localStorage.removeItem('user');
  }, [user, setUser]);

  const refreshUser = useCallback(async () => {
    try {
      const token = window.localStorage.getItem('jwt');

      if (token) {
        const res = await fetchJSON({
          url: 'users/me',
          method: 'GET',
        });

        if (res.statusCode) {
          signout();
        } else {
          setUser(res);

          if (res?.role?.name !== 'superadmin') {
            const company = await fetchCompany(res.company);

            setCurrentCompany(company);
            setCurrentSite(res.site);
          }
        }
      } else {
        signout();
      }
    } catch {
      // if users/me is not accessible then the token expired
      signout();
    } finally {
      setIsUserRefreshing(false);
    }
  }, [setUser, signout, setCurrentSite, setCurrentCompany, fetchCompany]);

  const sendConfirmation = useCallback(async (payload = { id: null, email: '' }) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/users/confirm-mail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (res.status === 200) {
      const resJson = await res.json();

      return resJson;
    }

    return res;
  }, []);

  const value = useMemo(() => ({
    user,
    signin,
    signup,
    signout,
    forgotPassword,
    resetPassword,
    refreshUser,
    isUserRefreshing,
    isAdmin,
    isManager,
    isSubscriber,
    sendConfirmation,
  }), [
    user,
    signin,
    signup,
    signout,
    refreshUser,
    isUserRefreshing,
    isAdmin,
    isManager,
    isSubscriber,
    sendConfirmation,
  ]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default useAuth;
