import useWindowSize from 'common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React from 'react';
import CardLayoutTable from './CardLayoutTable';
import DesktopTable from './DesktopTable';

const Table = ({
  data,
  columns,
  isFetching,
  pageCount: controlledPageCount,
  filters,
  setFilter,
  pageIndex,
  setPageIndex,
  showDesktopFilters,
}) => {
  const size = useWindowSize();

  return (
    <>
      {size.isDesktop && (
        <DesktopTable
          data={data}
          columns={columns}
          isFetching={isFetching}
          pageCount={controlledPageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      )}

      {(size.isMobile || size.isTablet) && (
        <CardLayoutTable
          data={data}
          columns={columns}
          isFetching={isFetching}
          pageCount={controlledPageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
          showDesktopFilters={showDesktopFilters}
        />
      )}
    </>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isFetching: PropTypes.bool,
  pageCount: PropTypes.number,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  setPageIndex: PropTypes.func,
  showDesktopFilters: PropTypes.bool,
};

Table.defaultProps = {
  filters: {},
  setFilter() {},
  setPageIndex() {},
  isFetching: false,
  pageCount: 1,
  showDesktopFilters: false,
};

export default Table;
