import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const CheckboxCardGroup = ({
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  required,
  options,
  className,
  contents,
  customTexts,
  ...rest
}) => {
  const handleChange = useCallback((val) => {
    if (multiple) {
      if (!Array.isArray(value)) {
        onChange([val]);
      } else if (value.includes(val)) {
        const newValue = value.filter(v => v !== val);

        if (clearable || newValue.length > 0) {
          onChange(newValue);
        }
      } else {
        onChange([...value, val]);
      }
    } else {
      onChange(val);
    }
  }, [onChange, multiple, clearable, value]);

  return (
    <div className="checkbox-cards" {...rest}>
      { options?.map(({ value: val, label }, index) => {
        let selected = false;

        if (multiple) {
          selected = Array.isArray(value) && value.includes(val);
        } else {
          selected = val === value;
        }

        return (
          <div
            key={index}
            onClick={() => { handleChange(val); }}
            className={selected ? 'checkbox-card is-selected' : 'checkbox-card'}
          >

            <div className="pseudo-checkbox">
              <input
                id={label}
                defaultValue={false}
                type="checkbox"
                className="is-checkradio"
                checked={selected}
                readOnly
              />
              <label />
            </div>
            <div className="checkbox-card-content">
              {contents[index].iconUrl && contents[index].iconType && (
                <img src={contents[index].iconUrl} alt={contents[index].iconType} />
              )}

              <div className="checkbox-card-content-right">
                <span className="label">{label}</span>
                {customTexts && customTexts[index] && customTexts[index].content && (
                  <div className="custom-text">
                    {customTexts[index].content}
                  </div>
                )}
                <p>{contents[index].description}</p>
              </div>
            </div>
          </div>

        );
      })}
    </div>
  );
};

CheckboxCardGroup.propTypes = {
  value: PropTypes.any,
  children: PropTypes.element,
  customTexts: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  'data-cy': PropTypes.string,
};

CheckboxCardGroup.defaultProps = {
  value: null,
  onChange() {},
  className: null,
  children: null,
  customTexts: null,
  clearable: false,
  multiple: false,
  disabled: false,
  required: false,
  'data-cy': '',
};

export default CheckboxCardGroup;
