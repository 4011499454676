import MenuContext from 'common/contexts/menu';
import useRouter from 'common/hooks/use-router';
import useWindowSize from 'common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const PageHeader = ({
  title, link, subLink, extraLink, children, goBack,
}) => {
  const { toggleMenu } = useContext(MenuContext);
  const size = useWindowSize();
  const router = useRouter();

  const handleSubLink = (e) => {
    e.preventDefault();
    if (goBack) {
      if (router.history.action === 'POP') {
        router.push(subLink.url);
      } else {
        router.history.goBack();
      }
    } else {
      router.push(subLink.url);
    }
    return false;
  };

  return (
    <header className="page-header">

      {!size.isDesktop && (
      <button onClick={toggleMenu} className="menu-open-button"><i className="fas fa-bars" />
      </button>
      )}
      <div>
        <h2 className="page-header--title title">{title}</h2>
        { subLink && (
        <span className="sublink">
          <a href={subLink.url} onClick={handleSubLink}>
            <span className="icon">
              <i className={`fas fa-${subLink.icon}`} />
            </span>
            <span>{subLink.label}</span>
          </a>
        </span>
        ) }
      </div>

      <div className="page-header-right">
        {extraLink && (
        <div className="page-header--link">
          <Link as="button" to={extraLink.url}>
            <span className="page-header--link-label">{extraLink.label}</span>
            <span className="icon page-header--link-button button is-light">
              <i className={`fas fa-${extraLink.icon}`} />
            </span>
          </Link>
        </div>
        )}
        {link && (
        <div className="page-header--link">
          <Link as="button" to={link.url}>
            <span className="page-header--link-label">{link.label}</span>
            <span className="icon page-header--link-button button is-light">
              <i className={`fas fa-${link.icon}`} />
            </span>
          </Link>
        </div>
        )}
        {children}
      </div>

    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.any,
  subLink: PropTypes.object,
  extraLink: PropTypes.any,
  children: PropTypes.node,
  goBack: PropTypes.bool,
};

PageHeader.defaultProps = {
  link: null,
  subLink: null,
  extraLink: null,
  children: null,
  goBack: false,
};

export default PageHeader;
