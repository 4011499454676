import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useMemo
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import useRouter from '../../../common/hooks/use-router';
import useItem from '../../../common/hooks/useItem';
import WikiCategorySelect from './WikiCategorySelect';

const WikiForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { user: { id } = {}, user } = useAuth();
  const { setAlert } = useContext(AlertsContext);

  const { id: articleId } = router.match.params;
  const {
    saveItem, updateItem, item, error,
  } = useItem('article', articleId);

  const initialValues = useMemo(() => item?.id ? ({
    ...item,
    company: item?.company?.id,
    user: item?.user?.id,
  })
    : {
      user: id,
      title: '',
      content: '',
      image: [],
      isWiki: true,
      published: true,
    }, [item, id]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/wiki');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (data) => {
    const article = { ...data };

    article.isWiki = true;
    article.created_at = moment().startOf('day').format();
    article.category = {
      id: data.category,
    };
    article.company = user.company;

    if (article.id) {
      await updateItem(article.id, article);
      back();
    } else {
      await saveItem(article);
      back();
    }
  }, [updateItem, back, saveItem, user]);

  return (
    <>
      <PageHeader
        title={t('common.add')}
        subLink={{
          url: '/wiki',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <Form
          onSubmit={onSubmit}
          subscription={{ values: true, submitting: true, pristine: true }}
          initialValues={initialValues}
          render={({
            handleSubmit, submitting, pristine,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />
              <div className="columns">
                <div className="column is-two-thirds">
                  <Card>
                    <div className="flex-fields">
                      <div style={{ flex: 2 }}>
                        <FormInput
                          name="title"
                          label={t('blog.title')}
                          required
                        />
                        <FormInput
                          name="content"
                          type="richtext"
                          label={t('blog.content')}
                          required
                        />

                      </div>
                    </div>
                  </Card>
                </div>
                <div className="column is-one-third contains-image-modal">
                  <div style={{ flex: 1 }}>
                    <Card>
                      <FormInput
                        name="published"
                        type="checkbox"
                        label={t('blog.publish')}
                      />
                      <FormInput
                        type="custom"
                        name="category"
                        label={t('blog.category')}
                        required
                        style={{ flex: 1 }}
                      >
                        <WikiCategorySelect />

                      </FormInput>
                      <FormInput
                        name="image"
                        label={t('blog.image')}
                        type="image"
                      />
                    </Card>
                  </div>
                </div>
              </div>

              <div className="stickySubmit">
                <FormInput
                  type="submit"
                  label={t('common.send')}
                  icon="paper-plane"
                  disabled={submitting || pristine}
                  isSubmitting={submitting}
                />
              </div>

            </form>
          )}
        />
      </section>
    </>
  );
};

export default WikiForm;
