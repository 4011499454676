/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import arrayMove from 'array-move';
import PropTypes from 'prop-types';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import DefaultFilesList from './DefaultFilesList';

const UploadFiles = ({
  multiple,
  value,
  accept,
  uploadUrl,
  children,
  onChange,
  nbCols,
  label,
  maxSize,
  max,
  name,
  'data-cy': dataCy,
  ...rest
}) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState({});

  const onRemoveFile = useCallback((removeFile) => () => {
    const newFiles = files.filter((f) => f.hash !== removeFile.hash);

    onChange(multiple ? newFiles : newFiles[0]);
  }, [multiple, files, onChange]);

  const sendFile = useCallback(async (file) => new Promise((resolve) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        setUploadingFiles((prevFiles) => ({
          ...prevFiles,
          [file.name]: {
            ...file,
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          },
        }));
      } else {
        console.log('not computable');
      }
    });

    req.upload.addEventListener('load', () => {
      setUploadingFiles((prevFiles) => ({
        ...prevFiles,
        [file.name]: {
          ...file,
          state: 'done',
          percentage: 100,
        },
      }));
    });

    req.upload.addEventListener('error', () => {
      setUploadingFiles((prevFiles) => ({
        ...prevFiles,
        [file.name]: {
          ...prevFiles[file.name],
          state: 'error',
          percentage: 0,
        },
      }));
      resolve(null);
    });

    // eslint-disable-next-line func-names
    req.addEventListener('load', function () {
      try {
        // eslint-disable-next-line react/no-this-in-sfc
        const response = JSON.parse(this.response);

        resolve({
          ...response[0],
          state: 'done',
          percentage: 100,
          filename: response.filename,
        });
      } catch (e) {
        resolve({
          ...file,
          state: 'error',
          percentage: 0,
          filename: 'titi',
        });
      }
    });

    const token = window.localStorage.getItem('jwt');
    const formData = new FormData();

    formData.append('files', file.file.inputFile);
    req.open('POST', uploadUrl);
    req.setRequestHeader('Authorization', `Bearer ${token}`);
    req.send(formData);
  }), [uploadUrl, setUploadingFiles]);

  const handleFileSelected = (async ({ target: { files: inputFiles } }) => {
    if (!inputFiles.length || (files.length + inputFiles.length) > max) {
      return;
    }

    const fileslist = [];

    for (let i = 0; i < inputFiles.length; i++) {
      const maxIndex = Math.max(...fileslist.map((currentFile) => currentFile.index));
      const file = {
        index: fileslist.length ? maxIndex + 1 : 0,
        inputFile: inputFiles.item(i),
        name: inputFiles.item(i).name,
        url: URL.createObjectURL(inputFiles.item(i)),
      };

      if (file.inputFile.size <= maxSize) {
        fileslist.push(file);
      }
    }

    const filesToUpload = {};

    for (const file of fileslist) {
      filesToUpload[file.name] = {
        name: file.name,
        url: file.url,
        file,
        type: file.inputFile.type,
        state: 'pending',
        percentage: 0,
      };
    }
    setUploadingFiles(filesToUpload);

    const promiseFiles = [];

    Object.values(filesToUpload).forEach((f) => {
      promiseFiles.push(sendFile(f));
    });
    const uploadedFiles = await Promise.all(promiseFiles);

    if (multiple) {
      const newFiles = [...files];

      uploadedFiles.forEach((file) => {
        if (file) newFiles.push(file);
      });
      setFiles(newFiles);
      onChange(newFiles);
    } else {
      setFiles(uploadedFiles);
      onChange(uploadedFiles[0]);
    }

    setUploadingFiles({});
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newFiles = arrayMove(files, oldIndex, newIndex);

    newFiles[0].updated_at = new Date();
    setFiles(newFiles);
    onChange([...newFiles]);
  };

  const ufiles = Object.values(uploadingFiles) ?? [];
  const fileslist = [...files, ...ufiles];

  useEffect(() => {
    if (value) {
      setFiles(multiple ? value : [value]);
    } else {
      setFiles([]);
    }
  }, [value, setFiles, multiple]);

  if (multiple) {
    return (
      <div {...rest}>
        <div className="multiple-uploader">
          <label className="input input-uploader">
            {label || t('uploader.uploadFiles')}
          </label>
          <input
            name={name}
            ref={inputEl}
            data-cy={dataCy}
            type="file"
            accept={accept}
            className="file-upload"
            onChange={handleFileSelected}
            multiple
          />
          {(children
            ? children({ items: fileslist, onRemoveFile, nbCols })
            : (
              <DefaultFilesList
                axis="xy"
                nbCols={nbCols}
                onSortEnd={onSortEnd}
                items={fileslist}
                onRemoveFile={onRemoveFile}
              />
            )
          )}
        </div>
      </div>
    );
  }

  const style = (fileslist[0] && (fileslist[0].ext === '.png'
  || fileslist[0].ext === '.jpg'))
    ? { backgroundImage: `url(${process.env.REACT_APP_API_URL}${fileslist[0].url})` } : null;
  const classNames = `single-file-uploader ${fileslist[0] ? 'image-load' : ''}`;

  return (
    <div {...rest}>
      <div className={classNames} style={style}>
        <label className="input input-uploader">
          {label || t('uploader.uploadFile')}
        </label>
        {fileslist[0] && fileslist[0].percentage < 100 && (
          <div className="ProgressBar">
            <div className="Progress" style={{ width: `${fileslist[0].percentage}%` }} />
          </div>
        )}
        <input
          name={name}
          ref={inputEl}
          type="file"
          accept={accept}
          className="file-upload"
          onChange={handleFileSelected}
          multiple={multiple}
        />
        { fileslist[0] && fileslist[0].ext === '.pdf' && (
          <DefaultFilesList
            axis="xy"
            nbCols={nbCols}
            onSortEnd={onSortEnd}
            items={fileslist}
            onRemoveFile={onRemoveFile}
          />
        )}
      </div>
    </div>
  );
};

UploadFiles.propTypes = {
  uploadUrl: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  nbCols: PropTypes.number,
  max: PropTypes.number,
  maxSize: PropTypes.number,
  children: PropTypes.element,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  'data-cy': PropTypes.string,

};

UploadFiles.defaultProps = {
  uploadUrl: `${process.env.REACT_APP_API_URL}/upload`,
  onChange() { },
  value: null,
  max: Infinity,
  maxSize: Infinity,
  nbCols: 3,
  multiple: false,
  label: null,
  accept: 'application/pdf',
  children: null,
  name: '',
  'data-cy': '',
};

export default UploadFiles;
