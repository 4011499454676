import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const JobsContext = createContext();
const useJobs = () => useContext(JobsContext);

export const JobsProvider = (props) => <ContextProvider url="jobs" context={JobsContext} {...props} />;

export default useJobs;
