import Moment from 'moment';
import 'moment/locale/fr';

import { extendMoment } from 'moment-range';
import 'moment-weekday-calc';

const moment = extendMoment(Moment);

moment.locale('fr');

export default moment;
