import { useAuth } from 'common/contexts/auth';
import MenuContext from 'common/contexts/menu';
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import useCompanies from 'requests/contexts/companies';
import PositionBottom from './sections/PositionBottom';
import PositionTop from './sections/PositionTop';
import AdminMenu from './sections/RoleAdmin';
import EmployeeMenu from './sections/RoleEmployee';
import ManagerMenu from './sections/RoleManager';

const Menu = () => {
  const { menuActive } = useContext(MenuContext);
  const { isAdmin, isManager } = useAuth();
  const { currentCompany } = useCompanies();

  return (
    <aside
      data-test="component-aside"
      className={`menu is-dark ${menuActive
        && 'is-open'}`}
    >
      <PositionTop />

      <div className="menu-container">
        {(isAdmin || isManager) ? (
            currentCompany?.id ? (
              <>
                <ManagerMenu />
                <AdminMenu />
              </>
            ) : (
              isAdmin && <AdminMenu />
            )

        ) : (
            currentCompany?.id && <EmployeeMenu />
        )}
        <PositionBottom />
      </div>
    </aside>
  );
};

export default withRouter(Menu);
