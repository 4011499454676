import React from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import PageHeader from '../../components/PageHeader/PageHeader';
import HomeArticles from './sections/HomeArticles';
import HomeCra from './sections/HomeCra';
import HomeLeaves from './sections/HomeLeaves';
import HomePendingVacations from './sections/HomePendingVacations';
import HomeSoldes from './sections/HomeSoldes';
import HomeTodos from './sections/HomeTodos';

const Home = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();

  return (
    <>
      <PageHeader
        title={t('dashboard.title')}
        link={{ url: '/holidays/add', icon: 'plus', label: t('user.askLeave') }}
      />

      <section className="section section-dashboard">
        <div style={{ display: 'flex' }} className="dashboard-top">
          { !currentCompany.disabledModules?.tasks && (
          <div className="dashboard-top-left welcome-card">
            <HomeTodos />
          </div>
          )}
          <div className="dashboard-top-right">
            <div>
              { !currentCompany.disabledModules?.holidays && (
              <HomeLeaves />
              )}
              { !currentCompany.disabledModules?.timeReport && (
              <HomeCra />
              )}
            </div>

            { !currentCompany.disabledModules?.holidays && (
            <div style={{ marginTop: '1.35rem' }}>
              <HomePendingVacations />
              <HomeSoldes />
            </div>
            )}
          </div>
        </div>
        { !currentCompany.disabledModules?.blog && (
        <HomeArticles />
        )}
      </section>
    </>
  );
};

export default Home;
