import Card from 'common/components/Card/Card';
import HolidaysSoldesByTypes from 'common/components/HolidaysSoldesByTypes/HolidaysSoldesByTypes';
import HolidaysUserHistorical from 'common/components/HolidaysUserHistorical/HolidaysUserHistorical';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useRouter from 'common/hooks/use-router';
import useFetch from 'common/hooks/useFetch';
// import holidays from 'common/utils/holidays';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../common/contexts/auth';
import useItem from '../../../common/hooks/useItem';

const UserDetail = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isAdmin, isManager, user: currentUser } = useAuth();
  const { id: userId } = router.match.params;
  const {
    item: user,
  } = useItem(
    'users',
    (parseInt(userId, 10) !== currentUser.id && (isAdmin || isManager)) ? userId : currentUser.id,
  );

  const { data: userHistoricalHolidays } = useFetch(
    `holidays?user=${userId}`,
  );

  return (
    <>
      {user && (
      <>
        <PageHeader
          title={user.username}
          link={{ url: `/users/edit/${user.id}`, icon: 'pencil-alt', label: t('user.editUser') }}
          subLink={{
            url: ((isAdmin || isManager) && user.id !== currentUser.id) ? '/users' : '/',
            icon: 'arrow-left',
            goBack: true,
            label: t('common.back'),
          }}
        />
        <section className="section profile-page">
          <div style={{ display: 'flex', width: '100%' }} className="profile-top">
            <div className="profile-left">
              <Card>
                <div className="user-intro">
                  <div className="user-intro-left">
                    <div
                      className="user-intro-avatar"
                      style={{
                        backgroundImage: user.avatar
                          ? `url(${process.env.REACT_APP_API_URL}${user.avatar.url})` : 'none',
                      }}
                    >
                      {!user.avatar && (
                      <i className="fa fa-user" />
                      )}
                    </div>
                  </div>
                  <div className="user-intro-right">
                    <h2 className="title is-2">{user.username}</h2>
                    <div className="profile-section">
                      <p>
                        <strong>{t('user.phone')} : </strong> {user.phone
                          ? <a href={`tel:${user.phone}`}>{user.phone}</a>
                          : t('common.undefined')}
                      </p>
                      <p>
                        <strong>{t('common.email')} : </strong> {user.email
                          ? <a href={`mailto:${user.email}`}>{user.email}</a>
                          : t('common.undefined')}
                      </p>
                      <p>
                        <strong>{t('common.address')} : </strong>
                        {(user.streetAndNumber || user.postalCode || user.city) ? (
                          <>
                            {user.streetAndNumber} {user.postalCode} {user.city}
                          </>
                        ) : (
                          <>
                            {t('common.undefined')}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <h2 className="title is-3">{t('user.complementaryInformations')}</h2>
                <div className="profile-section">
                  <p>
                    <strong>{t('user.trustedPersonName')} : </strong> {user.trustedPersonName || t('common.undefined')}
                  </p>
                  <p>
                    <strong>{t('user.trustedPersonPhone')} : </strong>
                    {user.trustedPersonNumber || t('common.undefined')}
                  </p>
                  <p>
                    <strong> {t('user.children')} : </strong> {user.children || t('common.undefined')}
                  </p>
                </div>

                <hr />
                <div className="files-box">
                  <h2 className="title is-3">{t('user.diplomasFiles')}</h2>
                  <div className="profile-section">
                    {user.diplomasFiles?.length ? (
                      <div className="documents-list">
                        { user.diplomasFiles.map(({
                          name, url,
                        }, index) => (
                          <div
                            key={index}
                            onClick={() => { }}
                            className="document-item"
                          >
                            <a href={`${process.env.REACT_APP_API_URL}${url}`}>
                              <span>{name}</span>
                              <button className="discret-button">{t('user.fileDownload')}</button>
                            </a>

                          </div>
                        ))}
                      </div>
                  ) : (
                    <p>{(t('user.noDiplomasFiles'))}</p>
                  )}
                  </div>

                  <h2 className="title is-3">{t('user.contractsFiles')}</h2>
                  <div className="profile-section">
                    {user.contractsFiles?.length ? (
                      <div className="documents-list">
                        { user.contractsFiles.map(({
                          name, url,
                        }, index) => (
                          <div
                            key={index}
                            onClick={() => { }}
                            className="document-item"
                          >
                            <a href={`${process.env.REACT_APP_API_URL}${url}`}>
                              <span>{name}</span>
                              <button className="discret-button">{t('user.fileDownload')}</button>
                            </a>

                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>{(t('user.noContractsFiles'))}</p>
                  )}
                  </div>
                </div>

                <div className="user-footer-left">
                  <Link className="button is-submit" to={`/users/edit/${user.id}`}>
                    <i className="fas fa-pencil-alt" style={{ marginRight: '0.5rem' }} />
                    {t('user.editUser') }
                  </Link>
                </div>
              </Card>
            </div>
            <div className="profile-right">
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">{t('holidays.holidaysSolde')}</h2>
                </div>
                <HolidaysSoldesByTypes balances={user?.holidayBalances} allowAsk />
              </Card>
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">
                    {t('dashboard.lastLeaves')}
                  </h2>
                </div>
                <HolidaysUserHistorical limit="5" balances={user?.holidayBalances} holidays={userHistoricalHolidays} />
              </Card>
            </div>
          </div>
        </section>
      </>
      )}
    </>
  );
};

export default UserDetail;
