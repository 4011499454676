import ContextProvider from 'common/contexts/crud';
import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => (
  <ContextProvider url="users" context={UsersContext}>{children}</ContextProvider>
);

UsersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useUsers = () => useContext(UsersContext);

export default useUsers;
