/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (result) => result.map((team) => ({ value: team.id, label: team.name, team }));

const TeamSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="teams"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.teams') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default TeamSelect;
