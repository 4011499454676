import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useEffect, useMemo
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import useRouter from '../../../common/hooks/use-router';
import useCategory from '../../contexts/category';

const CategoryForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const {
    save, update, error, item, fetchItem,
  } = useCategory();
  const { user: currentUser } = useAuth();
  const { currentCompany } = useCompanies();

  const initialValues = useMemo(() => item?.id ? ({
    ...item,
    company: item?.company?.id,
  }) : ({
    name: item?.name,
    company: currentCompany.id,
    ...item,
  }), [item, currentCompany]);

  const { id: categoryId } = router.match.params;

  useEffect(() => {
    fetchItem(categoryId);
  }, [categoryId, fetchItem]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/wiki');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (data) => {
    const category = { ...data };

    category.created_at = moment().startOf('day').format();

    if (category.id) {
      await update(category.id, category);
      back();
    } else {
      await save(category);
      back();
    }
  }, [update, back, save]);

  return (
    <>
      <PageHeader
        title={t('common.add')}
        subLink={{
          url: '/wiki',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <Form
          onSubmit={onSubmit}
          subscription={{ values: true, submitting: true, pristine: true }}
          initialValues={initialValues}
          render={({
            handleSubmit, submitting, pristine,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />
              <div className="columns">
                <div className="column">
                  <Card>
                    { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                    <FormInput
                      type="custom"
                      name="company"
                      label={t('common.company')}
                    >
                      <CompanySelect />
                    </FormInput>
                    )}
                    <FormInput
                      name="name"
                      label={t('blog.title')}
                      required
                    />
                    <FormInput
                      type="submit"
                      label={t('common.send')}
                      icon="paper-plane"
                      disabled={submitting || pristine}
                      isSubmitting={submitting}
                    />
                  </Card>
                </div>
              </div>

            </form>
          )}
        />
      </section>
    </>
  );
};

export default CategoryForm;
