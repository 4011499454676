import React, { createContext, useContext, useState } from 'react';

import ContextProvider from 'common/contexts/crud';
import fetchJSON from 'common/utils/fetchJSON';

const ACTIVITY_REPORTS_URL = 'activity-reports';

const ActivityReport = createContext();
const useActivityReport = () => useContext(ActivityReport);

const request = async req => {
  try {
    const res = await fetchJSON(req);

    if (res.error) {
      throw new Error('Fetch Error');
    }
    return res;
  } catch (e) {
    throw new Error('Fetch Error');
  }
};

const deleteItems = payload => request({
  url: `${ACTIVITY_REPORTS_URL}/delete-multiple/`,
  method: 'POST',
  payload,
});

const downloadCSVReports = payload => request({
  url: `${ACTIVITY_REPORTS_URL}/csv`,
  method: 'POST',
  payload,
});

const saveActivityReports = payload => request({
  url: `${ACTIVITY_REPORTS_URL}/save`,
  method: 'POST',
  payload,
});

export const ActivityReportProvider = props => {
  const [mode, setMode] = useState('week');

  return (
    <ContextProvider
      url="activity-reports"
      context={ActivityReport}
      value={{
        mode,
        setMode,
        deleteItems,
        downloadCSVReports,
        saveActivityReports,
      }}
      {...props}
    />
  );
};

export default useActivityReport;
