import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Button from '../Button/Button';

const ButtonGroup = ({
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  required,
  options,
  className,
  ...rest
}) => {
  const handleChange = useCallback((val) => {
    if (multiple) {
      if (!Array.isArray(value)) {
        onChange([val]);
      } else if (value.includes(val)) {
        const newValue = value.filter(v => v !== val);

        if (clearable || newValue.length > 0) {
          onChange(newValue);
        }
      } else {
        onChange([...value, val]);
      }
    } else {
      onChange(val);
    }
  }, [onChange, multiple, clearable, value]);

  const divClassName = `segment-buttons buttons has-addons ${className}`;

  return (
    <div className={divClassName} {...rest}>
      { options.map(({ value: val, ...button }, index) => {
        let selected = false;

        if (multiple) {
          selected = Array.isArray(value) && value.includes(val);
        } else {
          selected = val === value;
        }

        return (
          <Button key={index} {...button} color={selected ? 'primary' : null} onClick={() => { handleChange(val); }} />
        );
      })}
    </div>
  );
};

ButtonGroup.propTypes = {
  value: PropTypes.any,
  children: PropTypes.element,
  className: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  'data-cy': PropTypes.string,
};

ButtonGroup.defaultProps = {
  value: null,
  onChange() {},
  className: null,
  children: null,
  clearable: false,
  multiple: false,
  disabled: false,
  required: false,
  'data-cy': '',
};

export default ButtonGroup;
