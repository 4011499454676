import Card from 'common/components/Card/Card';
import CircleProgress from 'common/components/CircleProgress/CircleProgress';
import Loader from 'common/components/Loader/Loader';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useRouter from 'common/hooks/use-router';
import useList from 'common/hooks/useList';
import fetchJSON from 'common/utils/fetchJSON';
import React, {
  // eslint-disable-next-line comma-dangle
  useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useProjects from 'requests/contexts/projects';

const Project = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { item: project, fetchItem: fetchProject } = useProjects();
  const { items: users } = useList('users', {
    defaultLimit: -1,
    cachePrefix: 'projectdetailsusers',
  });
  const [taskTypes, setTaskTypes] = useState([]);
  const { id: projectId } = router.match.params;

  const {
    items: activities,
  } = useList('activity-reports', {
    defaultLimit: -1,
    defaultFilters: {
      project: projectId,
      timeSpent_gt: 0,
    },
    cachePrefix: 'projectdetailsactivities',
  });

  useEffect(() => {
    fetchProject(projectId);
  }, [fetchProject, projectId]);

  useEffect(() => {
    const daysByTask = (taskType) => activities
    && activities.reduce((acc, report) => {
      if (report.timeSpent && report.task_type.id === taskType.id) {
        return acc + report.timeSpent;
      }
      return acc;
    }, 0);

    fetchJSON({
      url: 'task-types',
      method: 'GET',
    }).then((results) => {
      setTaskTypes(results.map(taskT => ({
        ...taskT,
        count: daysByTask(taskT),
      })));
    });
  }, [activities]);

  const totalSpent = useMemo(() => activities
  && activities.reduce((acc, report) => {
    if (report.timeSpent) {
      return acc + report.timeSpent;
    }

    return acc;
  }, 0), [activities]);

  const balance = useMemo(() => (project?.days_estimated || 0) - totalSpent, [project, totalSpent]);

  const progress = project?.days_estimated ? (totalSpent / project?.days_estimated) * 100 : 100;

  const usersWithDays = useMemo(() => {
    const daysByUser = (user) => activities
    && activities.reduce((acc, report) => {
      if (report.timeSpent && report.user.id === user.id) {
        return acc + report.timeSpent;
      }

      return acc;
    }, 0);

    return users?.map(user => ({
      ...user,
      count: daysByUser(user),
    }));
  }, [users, activities]);

  return (
    <>
      {project
        ? (
          <>
            <PageHeader
              title={project.name}
              link={{ url: `/projects/edit/${project.id}`, icon: 'pencil-alt', label: t('projects.editProject') }}
              subLink={{
                url: '/projects',
                icon: 'arrow-left',
                goBack: true,
                label: t('common.back'),
              }}
            />
            <section className="section">
              <div className="project-details">
                <Card>
                  <div className="card-flex">
                    <div className="card-column">
                      <div className="card-custom-header">
                        <div
                          className="icon-picker-circle is-edited crud-item-avatar"
                          style={{
                            backgroundColor: `${project.color || '#aaaaaa'}`,
                          }}
                        >
                          {project.icon ? (
                            <i className={`fas fa-${project.icon}`} />
                          ) : (
                            <span>{project.name[0]}</span>
                          )}
                        </div>
                        <h2 className="title is-2">
                          {project.name}
                        </h2>
                      </div>
                      <p>{project.description || t('projects.noDescription')}</p>
                      <p>
                        <strong>{t('common.dailyBilling')} : </strong>
                        {project.daily_billing ? t('common.yes') : t('common.no')}
                      </p>
                      <p>
                        <strong>{t('projects.inProgress')} : </strong>
                        {project.in_progress ? t('common.yes') : t('common.no')}
                      </p>
                    </div>
                    <div className="card-column">
                      <div className="card-custom-header">
                        <h2 className="title is-2">
                          {t('projects.balance')}
                        </h2>
                      </div>
                      <div className="days">
                        {project.days_estimated && (
                        <div className="days-count days-estimate">
                          <p>{t('projects.daysEstimated')}</p>
                          <p className="value">{t('common.daysCount', { count: project.days_estimated?.toFixed(2) })}
                          </p>
                        </div>
                        )}

                        <div className="days-count days-total">
                          <span>{t('projects.totalDaysSpent')}</span>
                          <span className="value">{t('common.daysCount', { count: totalSpent?.toFixed(2) })}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-column">
                      {project.days_estimated && (
                      <div className="circle-progress-box">
                        <CircleProgress progress={progress} color={balance > 0 ? '#00DBE0' : '#ff7575'} />

                        <div className="icon balance">
                          <span style={{ color: balance > 0 ? '#00DBE0' : '#ff7575' }}>
                            {t('common.daysCount', { count: balance.toFixed(2) })}
                          </span>
                        </div>
                      </div>
                      )}

                    </div>
                  </div>

                </Card>

                {taskTypes.length > 0 && (
                  <>
                    <p className="subtitle">{t('projects.daysByTask')}</p>
                    <table className="table is-striped is-fullwidth">
                      <thead>
                        <tr>
                          <th>{t('common.name')}</th>
                          <th>{t('common.total')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taskTypes.map(task => (
                          <React.Fragment key={task.id}>
                            {task.count > 0 && (
                            <tr>
                              <td>
                                <div
                                  className="crud-item-avatar"
                                >
                                  <span>{task.name[0]}</span>
                                </div>
                                <span>{task.name}</span>

                              </td>
                              <td className="has-text-bold">
                                {t('common.daysCount', { count: task.count?.toFixed(2) })}
                              </td>
                            </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}

                {usersWithDays?.length > 0 && (
                  <>
                    <p className="subtitle">{t('projects.daysByUser')}</p>
                    <table className="table is-striped is-fullwidth">
                      <thead>
                        <tr>
                          <th>{t('common.name')}</th>
                          <th>{t('common.total')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usersWithDays.map(user => (
                          <React.Fragment key={user.id}>
                            {user.count > 0 && (
                            <tr>
                              <td>
                                <div
                                  className="user-avatar"
                                  style={{
                                    backgroundImage: user.avatar
                                      ? `url(${process.env.REACT_APP_API_URL}${user.avatar.url})` : 'none',
                                  }}
                                >
                                  {!user.avatar && (
                                  <span>{user.username[0]}</span>
                                  )}
                                </div>
                                <span>{user.username}</span>
                              </td>
                              <td className="has-text-bold">
                                {t('common.daysCount', { count: user.count?.toFixed(2) })}
                              </td>
                            </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </section>
          </>
        )
        : <Loader />}
    </>
  );
};

export default Project;
