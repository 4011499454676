/* eslint-disable react/prop-types */
import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BalanceField = ({
  activeContract, balances, isAdmin, isManager,
}) => {
  const { t } = useTranslation();

  return (
    <Card title={t('user.balance')}>
      {activeContract ? activeContract.holidayTypeConfig.map((holidayConfig, key) => {
        if (holidayConfig.config?.unlimited === 'unlimited') {
          return null;
        }

        const index = balances?.findIndex(
          (balance) => balance.holidays_type.id === holidayConfig.holidays_type.id && balance.time_period === 'n',
        );
        const indexPreviousPeriod = balances?.findIndex(
          (balance) => balance.holidays_type.id === holidayConfig.holidays_type.id && balance.time_period === 'n1',
        );

        if (index === -1) {
          return null;
        }

        return (

          <div key={holidayConfig.id}>
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.1em' }}>
              <i
                className={`fas fa-${holidayConfig.holidays_type.icon}`}
                style={{
                  color: `${holidayConfig.holidays_type.color}`,
                }}
              />&nbsp;
              <b>{holidayConfig.holidays_type.name}</b>
            </div>

            <div style={{ display: 'flex', marginTop: '0.5rem' }}>

              <div style={{ flex: 1, paddingRight: indexPreviousPeriod !== -1 ? '5px' : 0 }}>
                <FormInput
                  type="number"
                  name={`holidayBalances[${index}].balance`}
                  required
                  label={`${t('user.balance')} (N)`}
                  disabled={!isAdmin && !isManager}
                />
              </div>
              {indexPreviousPeriod !== -1 && (
                <div style={{ flex: 1, paddingLeft: '5px' }}>
                  <FormInput
                    type="number"
                    name={`holidayBalances[${indexPreviousPeriod}].balance`}
                    required
                    label={`${t('user.balance')} (N-1)`}
                    disabled={!isAdmin && !isManager}
                  />
                </div>
              )}
            </div>
            {key < activeContract.holidayTypeConfig.length - 1 && (
              <hr />
            )}

          </div>

        );
      }) : (
        <p>{t('user.noContractsYet')}</p>
      )}
    </Card>
  );
};

export default BalanceField;
