import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Loader = ({
  processing, timeoutDuration, isScreen, permanant,
}) => {
  const [state, setState] = useState('idle');
  const timeout = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (processing && state === 'idle') {
      clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        setState('display');
      }, timeoutDuration);
      setState('delay');
    }

    if (processing === false) {
      clearTimeout(timeout.current);
      setState('idle');
    }

    return () => clearTimeout(timeout.current);
  }, [state, timeoutDuration, processing]);

  if (state === 'display' || permanant) {
    return (
      <div className="data-loader-box">
        {isScreen ? (
          <div className="data-loader">
            <i className="fas fa-spinner fa-2x rotate" />
            <p className="text">{t('common.loading')}</p>
          </div>
        ) : (
          <div>{t('common.loading')}</div>
        )}
      </div>
    );
  }

  return null;
};

Loader.propTypes = {
  isScreen: PropTypes.bool,
  processing: PropTypes.bool,
  timeoutDuration: PropTypes.number,
  permanant: PropTypes.bool,
};

Loader.defaultProps = {
  isScreen: false,
  processing: true,
  timeoutDuration: 400,
  permanant: false,
};

export default Loader;

export const loader = () => <Loader />;
