/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (result) => result.map((type) => ({ value: type.id, label: type.name, type }));

const WikiCategorySelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="category"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('blog.category') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default WikiCategorySelect;
