import Button from 'common/components/Button/Button';
import Card from 'common/components/Card/Card';
import PageHeader from 'common/components/PageHeader/PageHeader';
import formatDate from 'common/utils/formatDate';
import moment from 'common/utils/moment';
import React, {
  forwardRef,
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useActivityReport from 'requests/contexts/activityReport';
import getItems, { getOpenDays } from 'requests/pages/Summary/summary.utils';
import useFetch from '../../../common/hooks/useFetch';
import useList from '../../../common/hooks/useList';
import DatePicker from 'common/components/DatePicker/DatePicker';

const getCSV = (reports, date) => {
  const csv = `data:text/csv;charset=utf-8,${reports.map(e => Object.values(e).join(',')).join('\n')}`;

  const encodedUri = encodeURI(csv);
  const link = document.createElement('a');

  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${moment(date).year()}.csv`);
  document.body.appendChild(link);
  link.click();
};

const Summary = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const [dataLoading, setDataLoading] = useState(false);
  const [initDone, setInitDone] = useState(false);

  const DateButton = forwardRef(({ onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      style={{
        textAlign: 'middle',
      }}
    >
      {t('activityReports.month', {
        month: days[0].format('MMMM YYYY'),
      })}
    </div>
  ));

  const {
    items: holidays,
    setFilters: setHolidayFilters,
    isFetching: holidaysIsFetching,
  } = useList('holidays', date ? {
    defaultLimit: -1,
    defaultFilters: {
      'holidays_status.name': 'agreed',
      start_date_lte: moment(date)
        .endOf('month')
        .toISOString(),
      end_date_gte: moment(date)
        .startOf('month')
        .toISOString(),
    },
    cachePrefix: 'summaryholidays',
  } : {});

  const {
    fetchItems: fetchActivityReports,
    items: activityReports,
    setFilters: setActivityReportFilters,
    downloadCSVReports,
  } = useActivityReport();

  const {
    items: users,
  } = useList('users', { defaultFilters: { active: 1 }, defaultSort: 'username:ASC', cachePrefix: 'summaryusers' });

  const {
    data: holidayTypes,
  } = useFetch('holidays-types');

  const handleDownloadCSV = useCallback(async () => {
    setDataLoading(true);
    const reports = await downloadCSVReports({ date });

    setDataLoading(false);
    getCSV(reports, date);
  }, [downloadCSVReports, date]);

  const openDays = useMemo(() => getOpenDays({
    start_date: moment(date).startOf('month'),
    end_date: moment(date).endOf('month'),
  }), [date]);

  const renderSummaryItem = (items) => {
    const memo = items.map((user, index) => {
      const isValid = openDays - user.total === 0;
      const isMore = openDays - user.total < 0;

      return (
        <div className="trombinoscope-item" key={`user-${index}`}>
          <div
            className="user-avatar"
            style={{
              backgroundImage: user.avatar
                ? `url(${process.env.REACT_APP_API_URL}${user.avatar.url})` : 'none',
            }}
          >
            {!user.avatar && (
            <span>{user.username[0]}</span>
            )}
          </div>
          <Card>
            <div className="card-custom-header">
              <h2 className="title is-2">
                {user.username}
                {user.job && (
                <>
                  <br />
                  <small>{user.job.name}</small>
                </>
                )}

              </h2>

              <div className="summary-bar">
                <div
                  className="summary-progress"
                  style={{
                    backgroundColor: isMore ? '#9180E8' : (
                      isValid ? '#39D9A9' : '#ff7575'
                    ),
                  }}
                >
                  <div
                    className="summary-progress-inner"
                    style={{
                      width: `${((user.total / openDays) * 100)}%`,
                    }}
                  />
                </div>
                <p>
                  <b>{isMore ? t('summary.isMore') : (isValid ? t('common.valid') : t('common.invalid'))}</b>
                  {' '}
                  <span style={{
                    color: isValid || isMore ? '#46658B'
                      : '#ff7575',
                  }}
                  >{`(${user.total}/${openDays})`}
                  </span>
                </p>

              </div>

            </div>

            <div className="item-main">
              <div className="box-projects">
                <h2 className="is-2 title">
                  <p>{t('summary.projects')}</p>
                </h2>
                {user.projects.length > 0 ? (
                  <ul>
                    {user.projects.map(({
                      name, icon, timeSpent, dailyBill,
                    }) => (
                      <li key={name}>

                        <div
                          className="status-tag pending"
                          style={{ position: 'relative', top: -2, marginRight: '0.5rem' }}
                        >
                          {dailyBill && (
                          <i className="fas fa-money" />
                          )}
                          {t('common.daysCount', { count: timeSpent })}
                        </div>

                        <i
                          className={`fas fa-${icon || 'calendar-day'}`}
                          style={{
                            color: '#003A9B',
                            marginRight: '.25rem',
                          }}
                        />

                        <b>{name}</b>

                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>{t('summary.noUserProject')}</p>
                )}

              </div>

              <div className="box-holidays">
                <h2 className="is-2 title">
                  <p>{t('summary.holidays')}</p>
                </h2>
                {user.holidays.length > 0 ? (
                  <ul>
                    {holidayTypes && holidayTypes.map((ht, index) => {
                      const typedHolidays = user.holidays.filter(h => h.holidays_type.name === ht.name);
                      const total = typedHolidays.reduce((acc, { computedDays }) => acc + computedDays, 0);

                      return (

                        <li key={`holiday-list-${index}`}>
                          {total > 0 && (
                            <>
                              <div
                                className="status-tag pending"
                                style={{ position: 'relative', top: -2, marginRight: '0.5rem' }}
                              >   {t('common.daysCount', { count: total })}
                              </div>
                              <b>{ht.name}</b>
                            </>
                          )}

                          <ul>
                            {typedHolidays.map((h, key) => (
                              <li
                                key={`holiday-list-${index}-${key}`}
                                style={{ display: 'flex', alignItems: 'center' }}
                              >

                                <div
                                  className="status-tag in-progress"
                                  style={{ position: 'relative', top: -2, marginRight: '0.5rem' }}
                                >   {t('common.daysCount', { count: h.computedDays })}
                                </div>

                                <i className={`fas fa-${ht.icon}`} style={{ color: ht.color, marginRight: '.25rem' }} />
                                {formatDate(t, h)}
                                {' '}

                                {h.description && (
                                <div className="help-btn ml" data-tooltip-top={h.description}>
                                  <i className="fas fa-question" />
                                </div>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p>{t('summary.noUserLeaves')}</p>
                )}

              </div>

              <div className="box-total">
                <h2 className="is-2 title">
                  <p>{t('summary.synthesis')}</p>
                </h2>
                <Link className="discret-button" to="/working-days">{t('summary.seeCra')}</Link>

              </div>
            </div>

          </Card>

        </div>
      );
    });

    return (
      <div className="trombinoscope-list summary-list">{memo}</div>
    );
  };

  const items = useMemo(() => {
    if (holidaysIsFetching === false && users) {
      return getItems(
        users,
        holidays,
        activityReports,
        date,
      );
    }
  }, [holidaysIsFetching, users, holidays, activityReports, date]);

  useEffect(() => {
    if (initDone) {
      fetchActivityReports({ pageSize: -1 });
    }
  }, [fetchActivityReports, initDone]);

  useEffect(() => {
    if (date) {
      const endMonth = moment(date).endOf('month').toISOString();
      const startMonth = moment(date).startOf('month').toISOString();

      setActivityReportFilters({
        date_gte: startMonth,
        date_lte: endMonth,
      });
      setHolidayFilters({
        'holidays_status.name': 'agreed',
        start_date_lte: endMonth,
        end_date_gte: startMonth,
      });

      setInitDone(true);
    }
  }, [date, setActivityReportFilters, setHolidayFilters]);

  const increaseDate = useCallback(() => {
    setDate((date) => moment(date).add(1, 'month').toDate());
  }, [setDate]);

  const decreaseDate = useCallback(() => {
    setDate((date) => moment(date).subtract(1, 'month').toDate());
  }, [setDate]);

  const days = useMemo(() => Array.from({ length: moment(date).daysInMonth() }).map((a, index) => {
    const day = moment(date).startOf('month').date(index + 1);

    return day;
  }), [date]);

  return (
    <>
      <PageHeader title={t('common.summary')}>
        <Button
          className="export-button"
          onClick={handleDownloadCSV}
          label={`${t('summary.exportSelectedYear')} ${moment(date).year()} ${t('summary.toCsv')}`}
          loading={dataLoading}
        />
      </PageHeader>
      <section className="section summary list-page">
        <div className="head-filter-box">
          <div className="head-filter-item">
            <div className="dateGhostSelector">
              <i className="fas fa-angle-left" onClick={decreaseDate} />
              <div className="dateGhostSelector-element">
                <DatePicker
                  className="react-date-picker"
                  value={date}
                  onChange={setDate}
                  clearIcon={null}
                  customInput={<DateButton />}
                  selectedTimeUnit="month"
                />
              </div>
              <i className="fas fa-angle-right" onClick={increaseDate} />
            </div>
          </div>
        </div>

        {items?.length > 0 && renderSummaryItem(items)}
      </section>
    </>
  );
};

export default Summary;
