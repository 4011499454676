import PropTypes from 'prop-types';
import React from 'react';

const CircleProgress = ({
  progress, color,
}) => {
  const r = 90;
  const perimeter = Math.PI * (r * 2);

  let pct = perimeter;

  pct = ((100 - progress) / 100) * perimeter;

  return (
    <div className="cont">
      <svg
        className="svg"
        width="200"
        height="200"
        viewport="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="white-circle"
          r="90"
          cx="100"
          cy="100"
          fill="white"
          strokeDasharray="565.48"
          strokeDashoffset="0"
        />

        <circle
          className="back-circle"
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
          style={{
            stroke: color,
          }}
        />
        <circle
          className="front-circle"
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          strokeDasharray="565.48"
          strokeDashoffset="0"
          style={{
            stroke: color,
            strokeDashoffset: pct,
          }}
        />
      </svg>
    </div>

  );
};

CircleProgress.propTypes = {
  progress: PropTypes.number,
  color: PropTypes.string,

};

CircleProgress.defaultProps = {
  progress: 100,
  color: '#aaaaaa',
};

export default CircleProgress;
