import MenuContext from 'common/contexts/menu';
import useWindowSize from 'common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const MenuLink = ({
  to, className, children,
}) => {
  const { toggleMenu } = useContext(MenuContext);
  const size = useWindowSize();

  return (
    <Link to={to} onClick={(size.isMobile || size.isTablet) ? toggleMenu : null} className={className}>
      {children}
    </Link>
  );
};

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

MenuLink.defaultProps = {
  children: null,
  className: null,
};

export default MenuLink;
