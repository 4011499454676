import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useItem from '../../../common/hooks/useItem';
import TodoTemplateForm from './TodoTemplateForm';

const TodoTemplate = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { id: todoId } = router.match.params;
  const {
    saveItem, updateItem, item, error,
  } = useItem('todo-templates', todoId);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/todo-templates');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (data) => {
    const todo = { ...data };

    if (todo.id) {
      await updateItem(todo.id, todo);
      back();
    } else {
      await saveItem(todo);
      back();
    }
  }, [updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/todo-templates',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <TodoTemplateForm onSubmit={onSubmit} todo={item} />
      </section>
    </>
  );
};

export default TodoTemplate;
