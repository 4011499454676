/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const processOptions = (result) => result.map((company) => ({ value: company.id, label: company.name, company }));

const CompanySelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="companies"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.company') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default CompanySelect;
