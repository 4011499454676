import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';

const Wizard = ({
  steps,
  currentStep,
  changeStep,
  displayButtons = true,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className="wizard-box">
      <ul className="wizard-stepper">
        { steps.map((step) => (
          <li>
            <Button
              className={currentStep === step.id ? 'wizard-stepper-button is-current'
                : (currentStep < step.id ? 'wizard-stepper-button is-next' : 'wizard-stepper-button is-done')}
              // onClick={() => changeStep(step.id)}
              icon={currentStep > step.id ? 'fa-check' : step.icon}
              data-tooltip-bottom={step.name}
            />
          </li>
        ))}
      </ul>
      {children}

      { displayButtons && (
        <div className="wizard-footer">
          {currentStep > 1 && (
          <button className="button is-light" onClick={() => changeStep(currentStep - 1)}>
            {t('wizard.prevStep')}
          </button>
          )}

          {currentStep < steps.length ? (
            <button className="button is-submit" onClick={() => changeStep(currentStep + 1)}>
              {t('wizard.nextStep')}
            </button>
          ) : (
            <button className="button is-submit">
              {t('wizard.finish')}
            </button>
          )}
        </div>
      )}

    </div>
  );
};

Wizard.propTypes = {
  steps: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default Wizard;
