import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const CategoryContext = createContext();
const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = (props) => <ContextProvider url="category" context={CategoryContext} {...props} />;

export default useCategory;
