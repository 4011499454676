import useWindowSize from 'common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, {
  // eslint-disable-next-line comma-dangle
  createContext, useEffect, useMemo, useState
} from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const size = useWindowSize();
  const [active, setActive] = useState(false);

  const [collapsible] = useState(false);

  useEffect(() => {
    setActive(size.isDesktop);
  }, [size]);

  const value = useMemo(() => ({
    menuActive: active,
    collapsible,
    toggleMenu: () => setActive(!active),
  }), [
    active,
    collapsible,
  ]);

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MenuContext;
