import DebounceInput from 'common/components/DebounceInput/DebounceInput';
import useWindowSize from 'common/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useSortBy, useTable } from 'react-table';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';
import Select from '../Select/Select';

const getValue = (obj, path) => {
  const paths = path.split('.');
  const len = paths.length;

  for (let i = 0; i < len; i++) {
    if (obj) {
      obj = obj[paths[i]];
    }
  }
  return obj;
};

const CardLayoutTable = ({
  data,
  columns,
  isFetching,
  filters,
  setFilter,
  pageCount: controlledPageCount,
  pageIndex,
  setPageIndex,
  showDesktopFilters,
  groupByOptions,
  setGroupBy,
  groupBy,
}) => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex, pageSize: 10 },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(currentPageIndex);
    }
  }, [currentPageIndex, pageIndex, setPageIndex]);

  const handleChangeFilter = useCallback(
    (name, value) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      setFilter(name, value);
    },
    [gotoPage, setFilter],
  );

  let currentGroup = '';

  return (
    <div className={controlledPageCount !== 1
      ? 'table-box mobile-table-box has-paginator' : 'mobile-table-box table-box'}
    >

      <div className="table-padding mobile-table-list" {...getTableProps()}>
        <div {...getTableBodyProps()} style={{ flex: 1 }}>

          {showDesktopFilters && !size.isMobile && (

            headerGroups.map((headerGroup, index) => (
              <div
                className="head-filter-box head-filter-box-trombi"
                {...headerGroup.getHeaderGroupProps()}
                key={`header-${index}`}
              >
                {headerGroup.headers.map((column, key) => (column.hidden || !column.filterName) ? null : (
                  <div className="head-filter-item" key={`column-${key}`}>
                    {column.filterName
                      && (column.filterComponent ? (
                        <column.filterComponent
                          value={filters[column.filterName]}
                          data-cy={column.filterName}
                          onChange={(value) => {
                            column.customFormat
                              ? handleChangeFilter(
                                column.filterName,
                                column.customFormat(value),
                              )
                              : handleChangeFilter(column.filterName, value);
                          }}
                        />
                      ) : (
                        <DebounceInput
                          className="input is-filter"
                          placeholder={column.render('Header')}
                          value={filters[column.filterName]}
                          onChange={(value) => handleChangeFilter(column.filterName, value)}
                        />
                      ))}

                  </div>
                ))}

                {groupByOptions && (
                <div className="head-filter-item">
                  <Select
                    options={groupByOptions}
                    onChange={setGroupBy}
                    value={groupBy}
                    placeholder={t('common.sortBy')}
                  />
                </div>
                )}

              </div>
            ))

          )}

          {data.length === 0 && isFetching && (
          <Loader isScreen permanant />
          )}
          {!isFetching && !page.length && (
            <p style={{ textAlign: 'center', marginTop: '3rem' }}>
              <span className="table-no-result">{t('common.noResult')}</span>
            </p>

          ) }

          <div className="cards-list">
            {
              page.map((row, index) => {
                prepareRow(row);
                const itemActions = row.cells.find((cell) => cell.column.className === 'table-actions');

                const createLabel = () => {
                  if (groupBy) {
                    const group = getValue(row.values, groupBy.split(':')[0]);

                    if (currentGroup !== group) {
                      currentGroup = group;
                      return (
                        <h2 className="title-section">
                          {groupByOptions.find((o) => o.value === groupBy).label} : &nbsp;
                          {t(group)}
                        </h2>
                      );
                    }
                  }
                };

                return (
                  <React.Fragment key={index}>
                    {createLabel()}

                    <Card key={`row-${index}`} data-tr={row.name} {...row.getRowProps()}>
                      {row.cells.map((cell, key) => (cell.column.hidden
                    || cell.column.className === 'table-actions') ? null : (
                      <div
                        className={`mobile-table-item-column ${cell.column.className}`}
                        key={`cell-${key}`}
                        data-cy={row.name}
                        data-row={cell.column.name}
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.column.className !== 'table-start' && (
                        <strong>{cell.column.Header} : </strong>
                        )}
                        {cell.render('Cell')}
                      </div>
                        ))}
                      {
                      itemActions && (
                        <div
                          className="mobile-table-item-column table-actions"
                          {...itemActions.getCellProps({
                            className: itemActions.column.className,
                          })}
                        >
                          {itemActions.render('Cell')}
                        </div>
                      )
                    }

                    </Card>
                  </React.Fragment>
                );
              })
            }
          </div>
        </div>
        {!isFetching && page.length > 0 && controlledPageCount !== 1 && (
        <div className="mobile-table-paginator">
          <nav className="pagination">
            <ul className="pagination-nav">
              <li>
                <button
                  className="pagination-previous"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage || isFetching}
                >
                  <span className="icon">
                    <i className="fas fa-angle-double-left" />
                  </span>
                </button>
              </li>
            </ul>
            <ul className="pagination-list">
              {Array.from({ length: pageOptions.length }, (page, i) => (
                <li key={`page-${i}`}>
                  <button
                    className={`pagination-link ${
                      pageIndex === i && 'is-current'
                    }`}
                    disabled={pageIndex !== i && isFetching}
                    onClick={() => gotoPage(i)}
                  >
                    <span>{i + 1}</span>
                  </button>
                </li>
              ))}
            </ul>
            <ul className="pagination-nav">
              <li>
                <button
                  className="pagination-next"
                  onClick={() => nextPage()}
                  disabled={!canNextPage || isFetching}
                >
                  <span className="icon">
                    <i className="fas fa-angle-double-right" />
                  </span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        )}
      </div>

    </div>

  );
};

CardLayoutTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  isFetching: PropTypes.bool,
  pageCount: PropTypes.number,
  setPageIndex: PropTypes.func,
  showDesktopFilters: PropTypes.bool,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  groupBy: PropTypes.string,
  setGroupBy: PropTypes.func,
  groupByOptions: PropTypes.array,
};

CardLayoutTable.defaultProps = {
  fetchData() {},
  setPageIndex() {},
  isFetching: false,
  pageCount: 1,
  showDesktopFilters: false,
  filters: {},
  setFilter() {},
  groupBy: null,
  setGroupBy() {},
  groupByOptions: null,
};

export default CardLayoutTable;
