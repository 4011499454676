import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import CompanyForm from 'requests/pages/Companies/CompanyForm';
import useItem from '../../../common/hooks/useItem';

const Company = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { id: companyId } = router.match.params;
  const { currentCompany } = useCompanies();
  const {
    saveItem, updateItem, item, error,
  } = useItem('companies', companyId);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      currentCompany?.id ? router.push('/configure') : router.push('/companies');
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, currentCompany, t]);

  const onSubmit = useCallback(async (data) => {
    const company = { ...data };

    if (company.id) {
      await updateItem(company.id, company);
      back();
    } else {
      await saveItem(company);
      back();
    }
  }, [updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/companies',
          goBack: true,
          icon: 'arrow-left',
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <CompanyForm onSubmit={onSubmit} company={item} />
      </section>
    </>
  );
};

export default Company;
