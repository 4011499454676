import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WizardStep = ({
  children,
  name,
  id,
  total,
}) => {
  const { t } = useTranslation();

  return (
    <div className="wizard-step-box">
      <h2 className="is-2 title">
        {t('wizard.step')} {id} {t('wizard.stepOf')} {total} : {name}
      </h2>

      {children}
    </div>
  );
};

WizardStep.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,

};

WizardStep.defaultProps = {
  children: null,

};

export default WizardStep;
