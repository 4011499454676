import ContextProvider from 'common/contexts/crud';
import React, { createContext, useContext } from 'react';

const ContractTypesContext = createContext();
const useContractType = () => useContext(ContractTypesContext);

export const ContractTypesProvider = (props) => (
  <ContextProvider url="contract-types" context={ContractTypesContext} {...props} />
);

export default useContractType;
