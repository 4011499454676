import Button from 'common/components/Button/Button';
import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useState, useCallback, useContext,
} from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import CompanySelect from 'requests/components/CompanySelect';
import JobSelect from 'requests/components/JobSelect';
import SiteSelect from 'requests/components/SiteSelect';
import TeamSelect from 'requests/components/TeamSelect';
import useCompanies from 'requests/contexts/companies';
import useRoles from 'requests/contexts/roles';
import BalanceField from 'users/components/BalanceField';

const DragHandle = SortableHandle(() => (
  <span className="icon" style={{ cursor: 'move' }}>
    <i className="fas fa-ellipsis-v" />
  </span>
));

const ContractItem = SortableElement(({
  value, name, fields, contractTypes, isAdmin, isManager, isOwner, contractChange,
}) => {
  const { t } = useTranslation();

  const opts = contractTypes.map((contractType) => ({ value: contractType.id, label: contractType.name }));

  return (
    <div className="sortable-item">
      {(isAdmin || isManager) && !isOwner && (
        <div className="sortable-item-start">
          <DragHandle />
          <Button
            onClick={() => fields.remove(value)}
            icon="fa-trash-alt"
            color="danger"
            confirm
            transparent
            confirmMessage={t('common.delete')}
            style={{ padding: 0 }}
          />
        </div>
      )}

      <div className="sortable-item-main">
        <div className="sortable-item-main-row">
          <div
            className="sortable-item-main-row-left"
            style={{
              flex: 1,
              display: 'flex',
              pointerEvents: ((!isAdmin && !isManager) || isOwner) ? 'none' : 'initial',
              opacity: ((!isAdmin && !isManager) || isOwner) ? 0.6 : 1,
            }}
          >
            <FormInput
              type="date"
              name={`${name}.startDate`}
              label={t('common.startDate')}
              required
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={(!isAdmin && !isManager) || isOwner}
              onChange={(val) => { contractChange({ startDate: val }, value); }}
            />
            <FormInput
              type="date"
              name={`${name}.endDate`}
              label={t('contract.endDate')}
              required={!fields.value[value]?.active}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              disabled={(!isAdmin && !isManager) || isOwner}
              onChange={(val) => { contractChange({ endDate: val }, value); }}
            />

            <div style={{ flex: 1 }}>
              <FormInput
                type="select"
                name={`${name}.contractType`}
                label={t('common.contractType')}
                required
                options={opts}
                disabled={(!isAdmin && !isManager) || isOwner}
              />
            </div>

          </div>

          <div data-tooltip-bottom={t('common.active')}>
            <FormInput
              name={`${name}.active`}
              className="list-item-field contract-active-field"
              type="checkbox"
              disabled
            />
          </div>
        </div>

      </div>

    </div>
  );
});

const ContractList = SortableContainer(({
  items, contractTypes, isAdmin, isManager, isOwner, contractChange,
}) => (
  <div className="sortable-subform has-calendars-bottom">
    {items && items.map((name, index) => (
      <ContractItem
        key={`item-${index}`}
        index={index}
        value={index}
        contractTypes={contractTypes}
        name={name}
        fields={items}
        isAdmin={isAdmin}
        isManager={isManager}
        isOwner={isOwner}
        contractChange={contractChange}
      />
    ))}
  </div>
));

const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex);
};

/** Ajuste les balances par rapport au type de congés du contrat actif */
const getUpdatedBalances = (balances, holidayConfigs) => {
  const newBalances = [];

  // eslint-disable-next-line no-unused-expressions
  holidayConfigs?.forEach((holidayConfig) => {
    const index = balances.findIndex(
      (balance) => balance.holidays_type.id === holidayConfig.holidays_type.id && balance.time_period === 'n',
    );

    if (index === -1) {
      newBalances.push({ balance: 0, holidays_type: holidayConfig.holidays_type, time_period: 'n' });
    } else {
      newBalances.push(balances[index]);
    }

    if (holidayConfig.config.used_period !== 'n') {
      const indexPreviousPeriod = balances.findIndex(
        (balance) => balance.holidays_type.id === holidayConfig.holidays_type.id && balance.time_period === 'n1',
      );

      if (indexPreviousPeriod === -1) {
        newBalances.push({ balance: 0, holidays_type: holidayConfig.holidays_type, time_period: 'n1' });
      } else {
        newBalances.push(balances[indexPreviousPeriod]);
      }
    }
  });

  return newBalances;
};

const UserForm = ({ onSubmit, user }) => {
  const { t } = useTranslation();
  const {
    user: currentUser, isAdmin, isManager, sendConfirmation,
  } = useAuth();
  const { currentCompany } = useCompanies();
  const [shouldUpdatePassword, toggleUpdatePassword] = useState(false);
  const { roleContents, roleOptions } = useRoles();
  const { setAlert } = useContext(AlertsContext);

  const handlePassword = () => toggleUpdatePassword((val) => !val);

  const [contractTypes, setContractTypes] = useState([]);
  const [activeContract, setActiveContract] = useState();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const res = await fetchJSON({
          url: 'contract-types',
          method: 'GET',
        });

        setContractTypes(res);
      } catch (e) {
        console.log(e);
      }
    };

    fetchItems();
  }, []);

  // regarde si le type du contrat actif a changé et si oui mais à jour les balances
  const updateBalances = (form, oldValues, values) => {
    const userContract = values?.contracts?.find(contract => contract.active === true);
    const userOldContract = oldValues?.contracts?.find(contract => contract.active === true);

    if (userOldContract?.contractType !== userContract?.contractType && contractTypes) {
      const aContract = contractTypes.find(
        contract => contract.id === userContract?.contractType,
      );

      setActiveContract(aContract);
      const newBalances = getUpdatedBalances(values.holidayBalances, aContract?.holidayTypeConfig);

      // setTimeOut : car sans lui, oldValues n'aura pas les anciennes valeurs
      // il faut donc un setTimeout qui permet que le code sera executé après
      // il semble que l'on peut editer directement values (mais bon c'est jamais une bonne pratique)
      setTimeout(() => {
        form.mutators.setFieldValue({ field: 'holidayBalances', value: newBalances });
      }, 0);
    }
  };

  const handleSendConfirmation = useCallback(async () => {
    const res = await sendConfirmation({ id: user.id, email: user.email });

    if (res?.status === 406) {
      setAlert(t('auth.userAlreadyConfirmed'), 'danger');
    } else {
      setAlert(t('auth.confirmationMailSent'), 'success');
    }
  }, [sendConfirmation, setAlert, t, user]);

  const initialValues = useMemo(() => user?.id ? ({
    ...user,
    role: user.role.id,
    job: user?.job?.id,
    company: user?.company?.id,
    site: user?.site?.id,
    teams: user?.teams?.map((team) => team.id),
    contracts:
      user?.contracts?.map((contract) => ({ ...contract, contractType: contract.contractType.id })),
    managedTeams: user?.managedTeams?.map((team) => team.id),
    managedSites: user?.managedSites?.id,
  }) : ({
    role: 1,
    username: '',
    email: '',
    password: '',
    jnt: 0,
    payedVacation: 0,
    job: '',
    teams: [],
    contracts: [],
    company: currentCompany?.id,
    managedTeams: [],
    time_report: true,
    holidayBalances: [],
    // contratTypes doit etre rajouté pour que l'updateBalance sur change Values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [user, currentCompany, contractTypes]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field], state, utils) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        contractChange: ([changes, index], state, utils) => {
          const currentContract = state.lastFormState.values.contracts[index];
          const contract = { ...currentContract, ...changes };
          const now = moment();

          if (contract.startDate !== null
            && now.isAfter(moment(contract.startDate).startOf('day'))
            && (contract.endDate == null || now.isBefore(moment(contract.endDate).endOf('day')))) {
            contract.active = true;
          } else {
            contract.active = false;
          }
          if (contract.active !== currentContract.active) {
            utils.changeValue(state, `contracts[${index}].active`, () => contract.active);
          }
        },
      }}
      render={({
        handleSubmit, submitting, pristine, values, form,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormSpy
            subscription={{ values: true }}
            onChange={(val) => {
              updateBalances(form, values, val.values);
            }}
          />
          <FormScrollError />
          <div className="columns">
            <div className="column is-two-thirds">
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">{t('user.memberInformations')}</h2>
                  {(isAdmin || isManager) && user?.id && !user?.confirmed && (
                    <Button
                      label={t('auth.sendConfirmationMail')}
                      onClick={handleSendConfirmation}
                    />
                  )}
                  <FormInput
                    name="confirmed"
                    label={t('auth.confirmed')}
                    type="checkbox"
                    disabled={!isAdmin && !isManager}
                  />
                  <FormInput
                    name="active"
                    label={t('common.active')}
                    type="checkbox"
                    disabled={!isAdmin && !isManager}
                  />
                </div>
                <div className="inputs-bar">

                  <div style={{ flex: 1 }}>
                    <FormInput
                      name="avatar"
                      label={t('user.avatar')}
                      type="image"
                    />
                  </div>

                  <div style={{
                    flex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  >
                    <FormInput
                      name="username"
                      label={t('common.name')}
                      required
                      icon="user"
                    />

                    <FormInput
                      type="email"
                      name="email"
                      required
                      label={t('common.email')}
                      icon="envelope"
                    />

                    <FormInput
                      type="number"
                      name="phone"
                      required
                      label={t('user.phone')}
                      icon="mobile"
                    />

                    <FormInput
                      name="shouldUpdatePassword"
                      value={shouldUpdatePassword}
                      checked={shouldUpdatePassword}
                      onClick={handlePassword}
                      label={t('auth.updatePassword')}
                      type="checkbox"
                    />
                    {(!user?.id || shouldUpdatePassword) && (
                      <FormInput
                        placeholder={user?.id ? t('auth.newPassword') : t('auth.password')}
                        type="password"
                        name="password"
                        required={!user?.id}
                        label={user?.id ? t('auth.newPassword') : t('auth.password')}
                        icon="lock"
                      />
                    )}
                    <FormInput
                      name="time_report"
                      label={t('user.timeReport')}
                      type="checkbox"
                      disabled={!isAdmin}
                    />
                  </div>
                </div>

                {isAdmin && (
                  <FormInput
                    type="custom"
                    name="company"
                    label={t('common.company')}
                    disabled={currentUser?.role?.name !== 'superadmin'}
                  >
                    <CompanySelect />
                  </FormInput>
                )}

                {isAdmin && values.company && (
                  <FormInput
                    type="custom"
                    name="site"
                    label={t('common.site')}
                  >
                    <SiteSelect
                      company={values.company}
                    />
                  </FormInput>
                )}

                <div className="inputs-bar">
                  <div style={{ flex: 1 }}>
                    <FormInput
                      type="custom"
                      name="job"
                      label={t('common.job')}
                      disabled={!isAdmin}
                    >
                      <JobSelect />
                    </FormInput>
                  </div>
                  <div style={{ flex: 1 }}>
                    <FormInput
                      type="custom"
                      name="teams"
                      label={t('common.teams')}
                      disabled={!isAdmin}
                    >
                      <TeamSelect multiple />
                    </FormInput>
                  </div>
                </div>
                {isAdmin
                  && user?.id !== currentUser.id
                  && (
                    <FormInput
                      type="checkboxcardgroup"
                      name="role"
                      label={t('common.role')}
                      options={roleOptions}
                      contents={roleContents}
                    />
                  )}
                {values.role === 5 && (
                  <>
                    <FormInput
                      type="custom"
                      name="managedTeams"
                      label={t('common.managedTeams')}
                      disabled={!isAdmin}
                    >
                      <TeamSelect multiple />
                    </FormInput>
                    <FormInput
                      type="custom"
                      name="managedSites"
                      label={t('common.managedSite')}
                      disabled={!isAdmin}
                    >
                      <SiteSelect
                        company={values.managedSites}
                      />
                    </FormInput>
                  </>
                )}
              </Card>
              <Card title={t('user.contracts')}>
                <FieldArray name="contracts">
                  {({ fields }) => (
                    <div className="sortable-box">
                      <ContractList
                        items={fields}
                        contractTypes={contractTypes}
                        useDragHandle
                        onSortEnd={sortEnd(fields.move)}
                        isAdmin={isAdmin}
                        isManager={isManager}
                        isOwner={user?.id === currentUser.id}
                        contractChange={form.mutators.contractChange}
                      />

                      {(isAdmin || isManager)
                        && user?.id !== currentUser.id
                        && (
                          <Button
                            onClick={() => fields.push({ active: false })}
                            icon="fa-plus"
                            label={t('user.addContract')}
                            className="button is-light"
                          />
                        )}
                    </div>
                  )}
                </FieldArray>
              </Card>
            </div>
            <div className="column is-one-third">

              <Card title={t('user.trustedPersonName')}>
                <FormInput
                  name="trustedPersonName"
                  label={t('user.trustedPersonName')}
                />
                <FormInput
                  name="trustedPersonPhone"
                  label={t('user.trustedPersonPhone')}
                />
              </Card>

              <Card title={t('user.complementaryInformations')}>
                <FormInput
                  name="children"
                  label={t('user.children')}
                />

                <FormInput
                  name="streetAndNumber"
                  label={t('user.streetAndNumber')}
                />
                <div className="inputs-bar">
                  <div style={{ flex: 1 }}>
                    <FormInput
                      name="postalCode"
                      label={t('user.postalCode')}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <FormInput
                      name="city"
                      label={t('user.city')}
                    />
                  </div>
                </div>

              </Card>

              <Card title={t('user.files')}>
                <FormInput
                  name="diplomasFiles"
                  label={t('user.diplomasFiles')}
                  icon="file"
                  type="file"
                  multiple
                />
                <FormInput
                  name="contractsFiles"
                  label={t('user.contractsFiles')}
                  icon="file"
                  type="file"
                  multiple
                />
              </Card>

              <BalanceField
                activeContract={activeContract}
                balances={values.holidayBalances}
                isAdmin={isAdmin}
                isManager={isManager}
              />
            </div>
          </div>

          <div className="stickySubmit">
            <FormInput
              type="submit"
              label={t(`users.${user && user.id ? 'edit' : 'add'}User`)}
              icon={`user-${user && user.id ? 'edit' : 'plus'}`}
              disabled={submitting || pristine}
              isSubmitting={submitting}
            />
          </div>

        </form>
      )}
    />
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

UserForm.defaultProps = {
  user: {},
};

export default UserForm;
