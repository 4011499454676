import WelcomeModal from 'account/pages/WelcomeModal/WelcomeModal';
import iconInvoice from 'assets/icon-invoice.png';
import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import PageHeader from 'common/components/PageHeader/PageHeader';
import CardEdit from 'account/pages/Subscribe/Card';
import Table from 'common/components/Table/Table';
import { useAuth } from 'common/contexts/auth';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useFetch from '../../../common/hooks/useFetch';
import useItem from '../../../common/hooks/useItem';
import ConfirmWrap from 'common/components/ConfirmWrap/ConfirmWrap';
import useList from 'common/hooks/useList';
import useCompanies from 'requests/contexts/companies';
import Modal from 'common/components/Modal/Modal';

const Account = () => {
  const { t } = useTranslation();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);
  const { itemsCount: nbMembers } = useList('users', {
    defaultLimit: -1,
    defaultFilters: {
      active: 1,
    },
  });

  const {
    currentCompany, update, fetchItem, setCurrentCompany,
  } = useCompanies();

  const {
    items: invoices,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
  } = useList('invoices', {
    defaultLimit: 5,
    defaultSort: 'created_at:desc',
  });

  const handleCloseModal = useCallback(() => {
    setShowWelcomeModal(false);
  }, [setShowWelcomeModal]);

  const switchSubscription = useCallback(async () => {
    const res = await update(currentCompany.id,
      {
        ...currentCompany,
        stopRenew: !currentCompany.stopRenew,
      });

    setCurrentCompany(res);
  }, [setCurrentCompany, currentCompany, update]);

  const refreshCompany = async () => {
    const company = await fetchItem(currentCompany.id);

    setCurrentCompany(company);
  };

  const downloadInvoice = (path) => {
    const link = document.createElement('a');

    link.download = path.substring(path.lastIndexOf('/') + 1);
    link.href = process.env.REACT_APP_API_URL + path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const description = useCallback(() => (
    <div className="subscription-resume">
      <div className="subscription-resume-left">
        <img src={iconInvoice} alt={t('subscription.title')} />
      </div>
      <div className="subscription-resume-right">
        <p>
          <b>{t('subscription.authorizedAccounts')} :</b>
          <span>{ t('subscription.nbMembers', { nb: nbMembers }) }</span>
        </p>
      </div>
    </div>
  ), [t, nbMembers]);

  const columns = [
    {
      Header: 'Période',
      Cell: ({ row: { original: { startDate, endDate } } }) => (
        <span>
          {`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}
        </span>
      ),
    },
    {
      Header: t('subscription.nbUsers'),
      Cell: ({ row: { original: { nbUsers } } }) => (
        <span>{nbUsers}</span>
      ),
    },
    {
      Header: t('subscription.editedAt'),
      Cell: ({ row: { original: { created_on } } }) => (
        <span>{moment(created_on).format('LLLL')}</span>
      ),
    },
    {
      Header: t('subscription.invoicePrice'),
      Cell: ({ row: { original: { amount } } }) => (
        <span>{amount} €</span>
      ),
    },
    {
      Header: t('subscription.invoice'),
      Cell: ({ row: { original: { invoicePath } } }) => (
        <button className="button is-gradient" onClick={() => downloadInvoice(invoicePath)}>
          <i className="fas fa-pen" />
          {t('subscription.download')}
        </button>
      ),
    },
  ];

  let status = 'active';

  let nbDays;

  if (!currentCompany.stripePaymentMethod && currentCompany.active) {
    status = 'demo';
    nbDays = moment(currentCompany.endDate).diff(moment(), 'day');
  } else if (currentCompany.active === false) {
    status = 'expired';
  }

  let validCard = false;

  if (currentCompany?.stripeExpMonth && currentCompany?.stripeExpYear) {
    const expirationDate = moment(`${currentCompany.stripeExpYear}-${currentCompany.stripeExpMonth}-01`)
      .add('1', 'month');

    if (expirationDate.isAfter(moment())) {
      validCard = true;
    }
  }

  return (
    <>
      { showCardForm && (
      <Modal closeModal={() => { setShowCardForm(false); }} className="welcome-modal">
        <div className="modal-main">
          <CardEdit onSuccess={async () => {
            await refreshCompany();
            setShowCardForm(false);
          }}
          />
        </div>
      </Modal>
      )}
      { showWelcomeModal && (
      <WelcomeModal
        closeModal={handleCloseModal}
      />
      )}
      <PageHeader
        title={t('common.account')}
      />
      <section className="section account-page">
        <div className="columns account-page-top">
          <div className="column is-half">
            {status === 'active' && (
              <Card>
                <div className="card-custom-header card-custom-header-column">
                  <h2 className="title is-2">{t('subscription.title')}</h2>
                  <span><i className="fas fa-clock color-primary" />
                  &nbsp;
                    {t('subscription.since')}&nbsp;
                    {moment(currentCompany.created_on).format('LL')} -
                    <b>
                      {t('subscription.nextInvoice')} :
                      <span className="color-primary">
                        { moment(currentCompany.endDate).subtract(7, 'day').format('LL') }
                      </span>
                    </b>
                  </span>
                </div>
                <div>
                  {description()}
                </div>
                <div className="card-footer">
                  { currentCompany.stopRenew && (
                    <p className="is-wrong">
                      { t('subscription.warningCancel') }
                    </p>
                  )}
                  <ConfirmWrap message={t('createAccount.stopSubscriptionAlert')}>
                    <button className="discret-button" onClick={() => switchSubscription()}>
                      {currentCompany.stopRenew ? t('subscription.activeRenew') : t('subscription.subscribeCancel')}
                    </button>
                  </ConfirmWrap>
                  {/* <Button
                    onClick={() => switchSubscription()}
                    className="discret-button"
                    confirm
                    transparent
                    confirmMessage={t('common.delete')}
                  />
                    {t('subscription.subscribeCancel')}
                  </Button>
                  */}
                </div>
              </Card>
            )}
            { status === 'demo' && (
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">{t('subscription.freePeriodTitle')} : &nbsp;
                    <span className="font-light">
                      {t('subscription.freePeriodRestingBefore')}
                    </span>
                    &nbsp;<b className="day-count">{nbDays} {t('common.days')}</b>&nbsp;
                    {t('subscription.freePeriodRestingAfter')}
                  </h2>
                </div>

                <div className="free-period-content">
                  <div className="free-period-left">
                    <img src={iconInvoice} alt={t('subscription.title')} />
                  </div>
                  <div className="free-period-right">

                    <p>
                      {t('subscription.freePeriodLegendBefore')}
                      &nbsp;
                      <b>{nbDays} {t('common.days')}</b>.
                      <br />
                      {t('subscription.freePeriodLegendAfter')}
                    </p>
                  </div>
                </div>

                <div className="card-footer">
                  <Link
                    to="/subscribe"
                    className="button is-gradient"
                  >
                    <span>
                      {t('subscription.subscribeNow')}
                    </span>
                  </Link>
                </div>
              </Card>
            )}
            { status === 'expired' && (
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">{t('subscription.expiredTitle')}

                  </h2>
                </div>
                <div className="free-period-content">
                  {t('subscription.expiredDescription')}
                </div>
                <div className="card-footer">
                  <Link
                    to="/subscribe"
                    className="button is-gradient"
                  >
                    <span>
                      {t('subscription.renewButton')}
                    </span>
                  </Link>
                </div>
              </Card>
            )}
          </div>

          <div className="column is-half">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2">{t('subscription.paymentInformations')}</h2>
                {currentCompany.stripeExpMonth && (
                <button className="button is-gradient" onClick={() => setShowCardForm(true)}>
                  <i className="fas fa-pen" />
                  {t('subscription.paymentModifyCard')}
                </button>
                )}

              </div>
              <div className="payment-resume">
                <div className="payment-resume-left">
                  { validCard ? (
                    <p className="is-valid">{t('subscription.paymentCardValid')}</p>
                  ) : currentCompany.stripeExpMonth ? (
                    <p className="is-wrong">{t('subscription.paymentCardInvalid')}</p>
                  ) : (
                    <p className="is-wrong">{t('subscription.paymentNoCard')}</p>
                  )}
                </div>
                <div className="payment-resume-right">
                  <span className="payment-card-edited">
                    **** **** **** {currentCompany?.stripeLast4 ? currentCompany?.stripeLast4 : '****'}
                  </span>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="columns account-page-bottom">
          <div className="column is-full">
            <div className="invoices-table">
              <Table
                data={invoices ?? []}
                columns={columns}
                isFetching={isFetching}
                pageCount={pageCount}
                filters={filters}
                setFilter={setFilter}
                setPageIndex={setPageIndex}
                pageIndex={pageIndex}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
