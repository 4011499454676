import React from 'react';

import {
  Elements,
} from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import CardContent from './CardContent';

// eslint-disable-next-line max-len
const stripePromise = loadStripe('pk_test_51JijuRHCJXVdgTaFQmA5oFFZLMstmmdlqVvH2Cev1tZH8HjSYi9m0BnkU9VTDkMtdmQyVsVwaqKfTIZqWlq0IcXW00YjIVomSv');

const Card = (props) => (
  <Elements stripe={stripePromise}>
    <CardContent {...props} />
  </Elements>

);

export default Card;
