import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import Wizard from 'common/components/Wizard/Wizard';
import WizardStep from 'common/components/Wizard/WizardStep';
import AlertsContext from 'common/contexts/alerts';
import React, { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useRouter from '../../hooks/use-router';
import useFetch from '../../hooks/useFetch';

const SignIn = () => {
  const { t } = useTranslation();
  const { setAlert } = useContext(AlertsContext);
  const router = useRouter();
  const {
    fetchData,
  } = useFetch('companies');

  const onSubmit = async (data) => {
    const payload = {
      name: data?.name,
      address: data?.address,
      zipcode: data?.companyZip,
      city: data?.companyCity,
      username: data?.username,
      email: data?.email,
      password: data?.password,
    };

    const res = fetchData({ url: 'companies/createAdmin', method: 'POST', payload });

    if (res) {
      setAlert(t('auth.loginSuccess'), 'success');
      router.push('/login');
    }
  };

  const stepUserFields = () => (
    <div className="columns">
      <div className="column is-one-third">
        <FormInput
          name="avatar"
          label={t('user.avatar')}
          type="image"
        />
      </div>
      <div className="column is-two-thirds">
        <FormInput
          name="username"
          label={t('common.name')}
          required
          icon="user"
        />
        <FormInput
          type="email"
          name="email"
          required
          label={t('common.email')}
          icon="envelope"
        />
        <FormInput
          placeholder={t('auth.password')}
          type="password"
          name="password"
          required
          label={t('auth.password')}
          icon="lock"
        />
      </div>
    </div>

  );

  const stepCompanyFields = () => (
    <div className="columns">
      <div className="column is-one-third">
        <FormInput
          name="image"
          label={t('company.image')}
          type="image"
        />
      </div>
      <div className="column is-two-thirds">
        <FormInput
          name="name"
          label={t('company.name')}
          required
        />
        <FormInput
          name="address"
          label={t('common.address')}
          required
        />
        <FormInput
          name="companyZip"
          label={t('common.zipcode')}
          type="number"
          required
        />
        <FormInput
          name="companyCity"
          label={t('common.city')}
          required
        />
      </div>
    </div>
  );

  const steps = [{
    id: 1, name: t('createAccount.stepCompany'), icon: 'fa-building', fields: stepCompanyFields(),
  },
  {
    id: 2, name: t('createAccount.stepAccount'), icon: 'fa-user', fields: stepUserFields(),
  }];
  const [currentStep, setcurrentStep] = useState(steps[0].id);

  const handleChangeStep = async (target) => {
    setcurrentStep(target);
  };

  return (
    <section className="section create-account-page">
      <Card>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            companyLogo: '',
            companyName: '',
            companyZip: '',
            companyCity: '',
            userAvatar: '',
            userName: '',
            email: '',
            password: '',
          }}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit} noValidate>
                <Wizard steps={steps} currentStep={currentStep} changeStep={handleChangeStep}>
                  { steps.map((step) => (
                    <div style={{ display: currentStep === step.id ? 'block' : 'none' }}>
                      <WizardStep
                        id={step.id}
                        name={step.name}
                        total={steps.length}
                        isCurrent={currentStep === step.id}
                      >
                        {step.fields}
                      </WizardStep>
                    </div>

                  ))}
                </Wizard>
              </form>
            </>
          )}
        />
      </Card>
    </section>
  );
};

export default SignIn;
