import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AlertHeader = ({
  message, actionPath, actionText, children,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.getElementById('root').classList.add('has-header-alert');
    return () => document.getElementById('root').classList.remove('has-header-alert');
  }, []);

  return ReactDOM.createPortal(
    <article className="header-alert">
      {message ? (
        <p>{message}</p>
      ) : (
        <div>{children}</div>
      )}

      {actionPath && (
      <Link to={actionPath} className="discret-button">
        {actionText || t('clickHere')}
      </Link>
      )}

      <span>{alert.message}</span>
    </article>,
    document.getElementById('root'),
  );
};

AlertHeader.propTypes = {
  message: PropTypes.object,
  actionText: PropTypes.string,
  actionPath: PropTypes.string,
  children: PropTypes.node,
};

AlertHeader.defaultProps = {
  message: null,
  actionText: null,
  actionPath: null,
  children: null,
};

export default AlertHeader;
