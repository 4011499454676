import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const HolidaysContext = createContext();
const useHolidays = () => useContext(HolidaysContext);

export const HolidaysProvider = (props) => <ContextProvider url="holidays" context={HolidaysContext} {...props} />;

export default useHolidays;
