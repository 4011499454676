import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Wizard from 'common/components/Wizard/Wizard';
import WizardStep from 'common/components/Wizard/WizardStep';
import AlertsContext from 'common/contexts/alerts';
import { useAuth } from 'common/contexts/auth';
import React, {
  useCallback, useContext, useState,
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../common/hooks/useFetch';
import useItem from '../../../common/hooks/useItem';
import useRouter from '../../../common/hooks/use-router';
import stepCardFields from './Card';
import moment from 'moment';

const Subscribe = () => {
  const { t } = useTranslation();
  const router = useRouter();
  // const auth = useAuth();
  const { user } = useAuth();
  const { setAlert } = useContext(AlertsContext);
  const {
    data: subscriptionsTypes,
  } = useFetch('subscription-types');
  const { id: subscriptionId } = router.match.params;
  const {
    saveItem,
    updateItem,
    error,
  } = useItem('subscription', subscriptionId);
  const {
    updateItem: updateUser,
  } = useItem('users', user.id);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/account');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const stepTypeFields = useCallback(() => {
    const typeOptions = subscriptionsTypes && subscriptionsTypes?.map(type => ({ value: type.id, label: type.name }));

    const typeContents = subscriptionsTypes && subscriptionsTypes?.map(type => ({ description: type.desc }));

    const typeCustomTexts = subscriptionsTypes && subscriptionsTypes?.map(type => (
      {
        content: (
          <>
            <b><span className="color-primary">{type.price}€</span> {t('subscription.perPerson')}</b>
            <br />
            <b><span className="color-primary">{type.nbMembers}</span> {t('common.users')}</b>
          </>
        ),
      }
    ));

    return (
      <>
        <FormInput
          name="type"
          type="checkboxcardgroup"
          options={typeOptions}
          contents={typeContents}
          customTexts={typeCustomTexts}
        />
      </>
    );
  }, [subscriptionsTypes, t]);

  const stepDatasFields = () => {
    const dataOptions = [
      { value: 1, label: t('subscription.subscriptionDatasLabelExemple') },
      { value: 2, label: t('subscription.subscriptionDatasLabelKeep') },
    ];
    const dataContents = [
      {
        description: t('subscription.subscriptionDatasDescExemple'),
      },
      {
        description: t('subscription.subscriptionDatasDescKeep'),
      },
    ];

    return (
      <>
        <FormInput
          name="datas_gestion"
          type="checkboxcardgroup"
          options={dataOptions}
          contents={dataContents}
        />
      </>
    );
  };

  const steps = [/* {
    id: 1, name: t('subscription.subscriptionStepType'), icon: 'fa-tag', fields: stepTypeFields,
  }, */
    {
      id: 1,
      name: t('subscription.subscriptionStepCard'),
      icon: 'fa-credit-card',
      fields: stepCardFields,
      onSuccess: () => { setcurrentStep(2); },
    },
    {
      id: 2, name: t('subscription.subscriptionStepDatas'), icon: 'fa-database', fields: stepDatasFields,
    },
  ];
  const [currentStep, setcurrentStep] = useState(steps[0].id);

  const handleChangeStep = (target) => {
    console.log('changeStep:', target);
    setcurrentStep(target);
  };

  const onSubmit = useCallback(async (data) => {
    // const user = await auth.signin(email, password);
    if (currentStep === 3 && data.datas_gestion !== '') {
      const type = await subscriptionsTypes.find(elem => data.type === elem.id);
      const endDate = moment().add(type?.duration, 'd');

      console.log('onSubmit:', data);

      const subscription = {
        user,
        company: user.company,
        subscription_type: data.type,
        active: true,
        payed: true,
        startDate: moment(new Date()).toISOString(),
        endDate: moment(endDate).toISOString(),
      };

      if (user) {
        setAlert(t('auth.loginSuccess'), 'success');
        if (subscription.id) {
          await updateItem(subscription.id, subscription);
          back();
        } else {
          const sub = await saveItem(subscription);

          if (sub) {
            user.subscriptions = [...user.subscriptions, sub];
            await updateUser(user.id, user);
          }
          back();
        }
      }
    }
  }, [currentStep, subscriptionsTypes, user, setAlert, t, updateItem, back, saveItem, updateUser]);

  return (
    <>
      <PageHeader
        title={`${t('common.account')} -  ${t('subscription.subscribeNow')}`}
        subLink={{
          url: '/account', icon: 'arrow-left', goBack: true, label: t('common.back'),
        }}
      />
      <section className="section create-account-page">
        <Card>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              type: '',
              card_code: '',
              card_security: '',
              card_expiration: '',
              datas_gestion: '',
            }}
            render={({ handleSubmit }) => (
              <>
                <form onSubmit={handleSubmit} noValidate>
                  <Wizard displayButtons={false} steps={steps} currentStep={currentStep} changeStep={() => {}}>
                    { steps.map((step) => (
                      <div style={{ display: currentStep === step.id ? 'block' : 'none' }}>
                        <WizardStep
                          id={step.id}
                          name={step.name}
                          total={steps.length}
                          isCurrent={currentStep === step.id}
                        >
                          <step.fields onSuccess={step.onSuccess} />
                        </WizardStep>
                      </div>

                    ))}
                  </Wizard>
                </form>
              </>
            )}
          />
        </Card>
      </section>
    </>
  );
};

export default Subscribe;
