import Card from 'common/components/Card/Card';
import Loader from 'common/components/Loader/Loader';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useAuth from 'common/contexts/auth';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const MyTodos = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  /* TODOS */

  const {
    fetchItems: fetchTodoItems,
    items: todos,
    isFetching: isTodoFetching,
  } = useList('todos', {
    defaultFilters: {
      user: currentUser.id,
    },
    defaultSort: 'updated_at:DESC',
    cachePrefix: 'mytodos',
  });

  const {
    updateItem: updateTodoItem,
  } = useItem('todos');

  const handleChangeTodoStatus = useCallback(async (todo) => {
    await updateTodoItem(todo.id, { ...todo, state: !todo.state });
    fetchTodoItems();
  }, [updateTodoItem, fetchTodoItems]);

  const handleChangeSubitemStatus = useCallback(async (todo, index) => {
    const updatedSubitems = todo.subitems;

    updatedSubitems[index].state = !updatedSubitems[index].state;
    const missingCheck = updatedSubitems.filter(val => val.state).length < updatedSubitems.length;

    await updateTodoItem(todo.id, { ...todo, state: !missingCheck, subitems: updatedSubitems });

    fetchTodoItems();
  }, [updateTodoItem, fetchTodoItems]);

  const [showDone, setShowDone] = useState(false);

  const todoDone = todos?.filter((todo) => todo.state);
  const todoPending = todos?.filter((todo) => !todo.state);

  const renderTodosList = (list, emptyMessage) => {
    if (!list.length) {
      return (
        <p>{emptyMessage}</p>
      );
    }
    return (
      <div className="todolist">
        { list.map((todo, key) => (
          <div key={key} className={`todo-item todo-item ${todo.state ? 'is-done' : 'is-pending'}`}>
            <div className="todo-top">
              <div
                className="todo-top-main"
                data-tooltip-bottom={todo.subitems.length ? t('todolist.automaticCheck') : null}
              >
                <div
                  className="pseudo-checkbox"
                  onClick={() => handleChangeTodoStatus(todo)}
                  style={{ pointerEvents: todo.subitems.length ? 'none' : 'all' }}
                >
                  <input
                    id={todo.name}
                    type="checkbox"
                    className="is-checkradio"
                        // defaultChecked={todo.state}
                    checked={todo.state}
                    disabled={todo.subitems.length}
                  />
                  <label>
                    {todo.name}
                  </label>
                </div>
              </div>
              <div className="todo-button-group">
                <span>
                  <i className="fas fa-clock color-primary" />
                  {t('common.updated_at')}&nbsp;
                  {moment(todo.updated_at).format('DD/MM, HH:mm')}
                </span>
              </div>
            </div>

            {todo.description && (
              <ReactQuill
                value={todo.description}
                readOnly
                theme="bubble"
              />
            )}

            {todo.subitems.length > 0 && (
              <ul className="subitems">
                { todo.subitems.map((subitem, index) => (
                  <li
                    key={index}
                    className={`todo-subitem 
                          ${subitem.state ? 'is-done' : 'is-pending'}`}
                  >
                    <div
                      className="pseudo-checkbox"
                      onClick={() => handleChangeSubitemStatus(todo, index)}
                    >
                      <input
                        id={subitem.name}
                        type="checkbox"
                        className="is-checkradio"
                            // defaultChecked={subitem.state}
                        checked={subitem.state}
                      />
                      <label>
                        {subitem.name}
                      </label>
                    </div>
                    {subitem.description && (
                    <ReactQuill
                      value={subitem.description}
                      readOnly
                      theme="bubble"
                    />
                    )}
                  </li>
                ))}

              </ul>
            )}

          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <PageHeader
        title={t('common.todos')}
      />

      <section className="section my-todos-page welcome-card">
        <Card>
          <img src="assets/welcome-picture.png" alt="Welcome person" className="welcome-picture" />
          <div className="welcome-top">
            <h1><b>{t('common.welcome')}, </b>
              {currentUser?.name}
            </h1>
            <div className="tabs">
              <button
                className={`tab-button ${!showDone && 'tab-button-active'}`}
                onClick={() => setShowDone(false)}
              >{t('todolist.tasksPending')} ({todoPending?.length || 0})
              </button>
              <button
                className={`tab-button ${showDone && 'tab-button-active'}`}
                onClick={() => setShowDone(true)}
              >{t('todolist.tasksDone')} ({todoDone?.length || 0})
              </button>
            </div>

          </div>

          <div className="welcome-bottom">
            {isTodoFetching && (
            <Loader isScreen permanant />
            )}

            {!showDone
              ? todos && renderTodosList(todoPending, t('todolist.homeNoTasks'))
              : todos && renderTodosList(todoDone, t('todolist.noTaskDone'))}

          </div>
        </Card>
      </section>
    </>
  );
};

export default MyTodos;
