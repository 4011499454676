import HolidaysSoldesByTypes from 'common/components/HolidaysSoldesByTypes/HolidaysSoldesByTypes';
import HolidaysUserHistorical from 'common/components/HolidaysUserHistorical/HolidaysUserHistorical';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import { useAuth } from '../../../contexts/auth';
import useFetch from '../../../hooks/useFetch';

const HomeSoldes = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { data: userHistoricalHolidays } = useFetch(
    `holidays?user=${currentUser.id}`,
  );

  const balances = useMemo(() => {
    const tab = [];

    currentUser && currentUser.holidayBalances && currentUser.holidayBalances.forEach((balance) => {
      if (balance.holidays_type.has_balance) {
        // on recherche dans le tableau si une balance du même type existe déjà
        const exist = tab.findIndex(item => item.holidays_type.id === balance.holidays_type.id);

        // si elle existe on la rajoute à celle déjà existante dans le tableau
        // sinon on la rajoute
        if (exist !== -1) {
          tab[exist] = { ...tab[exist], balance: tab[exist].balance + balance.balance };
        } else {
          tab.push(balance);
        }
      }
    });

    return tab;
  }, [currentUser]);

  return (
    <Card>
      <div className="card-scroller">
        <div className="card-custom-header">
          <h2 className="title is-2">
            {t('dashboard.lastLeaves')}
          </h2>
          <Link className="button is-light" to="/holidays">
            <i className="fas fa-eye" style={{ marginRight: '0.5rem' }} />
            {t('dashboard.seeHistorical')}
          </Link>
        </div>
        <div style={{ marginTop: '1.35rem' }}>
          <HolidaysUserHistorical limit="2" balances={balances} holidays={userHistoricalHolidays} />
        </div>
        <hr />
        <h2 className="title is-2">{t('holidays.holidaysSolde')}</h2>
        <div style={{ marginTop: '1.35rem' }}>
          <HolidaysSoldesByTypes balances={balances} user={currentUser} />
        </div>
      </div>

    </Card>
  );
};

export default HomeSoldes;
