import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({
  value, onRemoveFile, paddingTop, width,
}) => {
  const {
    name,
    percentage,
    url,
    isToRemove,
  } = value;

  const itemStyle = { paddingTop, backgroundImage: `url(${process.env.REACT_APP_API_URL}${url})` };

  if (isToRemove) return <div style={{ width: 0 }} />;

  return (
    <div className="image-item" title={name} style={{ width }}>
      <div className="image-item-inside" style={itemStyle}>
        {percentage < 100 && (
          <div className="ProgressBar">
            <div className="Progress" style={{ width: `${percentage}%` }} />
          </div>
        )}
        <div className="image-delete">
          <button type="button" className="button is-danger" onClick={onRemoveFile(value)}>
            <span className="icon">
              <i className="fas fa-trash-alt" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({
  items, onRemoveFile,
}) => (
  <div className={`image-list ${items.length ? 'has-items' : ''}`}>
    {items.map((value, index) => (
      <SortableItem key={`item-${index}`} index={index} value={value} onRemoveFile={onRemoveFile} />
    ))}
  </div>
));

const FilesList = (props) => <SortableList {...props} />;

FilesList.propTypes = {
  items: PropTypes.array,
  onRemoveFile: PropTypes.func,
};

FilesList.defaultProps = {
  items: [],
  onRemoveFile() {},
};

export default FilesList;
