/* eslint-disable react/prop-types */
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';

const processOptions = (result) => result.map((site) => ({ value: site.id, label: site.name, site }));

const SiteSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="sites"
      filters={['company']}
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.site') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default SiteSelect;
