import React from 'react';
import DP, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import {
  addDays, startOfWeek,
} from 'date-fns';
import { PropTypes } from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

registerLocale('fr', fr);

function DatePicker({
  placeholder,
  value,
  onChange,
  selectedTimeUnit,
  ...other
}) {
  const formattedValue = !value ? null : new Date(value);
  const handleChange = (newDate) => {
    if (newDate) {
      newDate.setHours(12);
    }

    if (selectedTimeUnit === 'week') {
      onChange(startOfWeek(newDate, { locale: fr }));
    } else {
      onChange(newDate);
    }
  };

  return (
    <DP
      dateFormat="dd/MM/yyyy"
      locale={fr}
      selected={formattedValue}
      onChange={handleChange}
      placeholderText={placeholder}
      portalId="date-portal"
      renderCustomHeader={undefined}
      showMonthYearPicker={selectedTimeUnit === 'month'}
      calendarClassName={selectedTimeUnit === 'week' ? 'react-datepicker-week-select' : undefined}
      highlightDates={selectedTimeUnit === 'week' && value && [
        {
          'react-datepicker__day--week-select': [
            addDays(startOfWeek(value, { locale: fr }), 0),
            addDays(startOfWeek(value, { locale: fr }), 1),
            addDays(startOfWeek(value, { locale: fr }), 2),
            addDays(startOfWeek(value, { locale: fr }), 3),
            addDays(startOfWeek(value, { locale: fr }), 4),
            addDays(startOfWeek(value, { locale: fr }), 5),
            addDays(startOfWeek(value, { locale: fr }), 6),
          ],
        },
      ]}
      {...other}
    />
  );
}

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholder: PropTypes.string,
  selectedTimeUnit: PropTypes.string,
};

DatePicker.defaultProps = {
  placeholder: '',
  value: null,
  onChange: () => {},
  selectedTimeUnit: 'day',
};

export default DatePicker;
