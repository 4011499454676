import useAuth from 'common/contexts/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import MenuLink from '../MenuLink';

const EmployeeMenu = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();

  const { user } = useAuth();

  return (
    <>
      <ul className="menu-list">
        <li>
          <MenuLink to="/">
            <span className="icon">
              <i className="fas fa-table" />
            </span>
            <span>{t('dashboard.title')}</span>
          </MenuLink>

          <hr />

          { !currentCompany.disabledModules?.holidays
          && (
          <MenuLink to="/holidays">
            <span className="icon">
              <i className="fas fa-plane-departure" />
            </span>
            <span>{t('common.vacations')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.timeReport && user.time_report === true && (
          <MenuLink to="/working-days">
            <span className="icon">
              <i className="fas fa-clock" />
            </span>
            <span>{t('common.enter')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.tasks && (
          <MenuLink to="/my-tasks">
            <span className="icon">
              <i className="fas fa-check" />
            </span>
            <span>{t('common.mytodos')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.holidays && (
          <MenuLink to="/holidays-planner">
            <span className="icon">
              <i className="fas fa-calendar" />
            </span>
            <span>{t('common.planning')}</span>
          </MenuLink>
          )}

          { !currentCompany.disabledModules?.trombi && (
          <MenuLink to="/trombinoscope">
            <span className="icon">
              <i className="fas fa-users" />
            </span>
            <span>{t('common.trombinoscope')}</span>
          </MenuLink>
          )}

          <hr />

          { !currentCompany.disabledModules?.blog && (
          <MenuLink to="/blog">
            <span className="icon">
              <i className="fas fa-bookmark" />
            </span>
            <span>{t('blog.headingTitle')}</span>
          </MenuLink>
          )}
          { !currentCompany.disabledModules?.wiki && (
          <MenuLink to="/wiki">
            <span className="icon">
              <i className="fas fa-info" />
            </span>
            <span>{t('blog.wiki')}</span>
          </MenuLink>
          )}

        </li>
      </ul>

    </>
  );
};

export default EmployeeMenu;
