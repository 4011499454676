const filterBySite = (apiUrl, url, method, role, companyId) => {
  let baseUrl = `${apiUrl}/${url}${url.includes('?') ? '&' : '?'}`;

  if (companyId && (role === 'superadmin') && method === 'GET') {
    if (url.includes('task-types')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('holidays-types')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('contract-types')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('jobs')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('users')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('teams')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('sites')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('wiki')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('blog')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('projects')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('summary')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('working-days')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('holidays-planner')) {
      baseUrl += `company=${companyId}`;
    } else if (url.includes('holidays')) {
      // todo : désactivé pour avoir accès aux holidaysStatuses (qui ne sont plus liés à une entreprise),
      // MAIS du coup il faudrait quand même filtrer les holidays par company..
      // baseUrl += `company=${companyId}`;
    } else {
      // baseUrl += `company=${companyId}`;
    }
  }

  return baseUrl;
};

export const fetchJSON = async ({
  url, method, payload, signal,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = window.localStorage.getItem('jwt');
  const headers = new Headers();
  const companyId = JSON.parse(window.localStorage.getItem('currentCompany'))?.id;
  const role = JSON.parse(window.localStorage.getItem('user'))?.role.name;

  headers.append('Content-Type', 'application/json');

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const res = await fetch(
    filterBySite(apiUrl, url, method, role, companyId),
    {
      method,
      headers,
      signal,
      body: JSON.stringify(payload),
    },
  );

  if (!res.ok) {
    let message = await res.text();

    try {
      message = JSON.parse(message).message;
    } catch (e) {
      // Not JSON
    }
    throw new Error(message);
  }

  return res.json();
};

export default fetchJSON;
