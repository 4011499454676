import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { computeDays } from 'requests/pages/Summary/summary.utils';

const HolidaysUserHistorical = ({
  filterByPending,
  filterByConfirmed,
  limit,
  holidays,
  balances,
}) => {
  const { t } = useTranslation();

  const renderHistoricList = (holidays) => {
    let userHolidays = holidays?.sort((a, b) => {
      const y = moment(a.end_date);
      const z = moment(b.end_date);

      return z - y;
    });

    if (filterByConfirmed) {
      userHolidays = userHolidays.filter(holiday => holiday.holidays_status.name === 'agreed');
    } else if (filterByPending) {
      userHolidays = userHolidays.filter(holiday => holiday.holidays_status.name === 'pending');
    }

    if (limit) { userHolidays = userHolidays?.slice(0, limit); }

    return (
      <>
        {filterByConfirmed && (
          <h2 className="title is-2">{t('holidays.holidaysHistoric')}</h2>
        )}

        {filterByPending && (
          <h2 className="title is-2">{t('holidays.holidaysPending')}</h2>
        )}
        {(userHolidays?.length > 0 && balances) ? userHolidays.map(holiday => {
          const balance = balances.find((balance) => balance.holidays_type.id === holiday.holidays_type.id);
          const duration = computeDays({
            start_date: holiday.start_date,
            end_date: holiday.end_date,
            start_date_type: holiday.start_date_type,
            end_date_type: holiday.end_date_type,
          });
          const initialSolde = balance?.balance + duration;
          const newSolde = balance?.balance;

          return (
            <div key={holiday.id} className="historic-item">
              <div
                className="icon-picker-circle is-edited"
                style={{
                  backgroundColor: `${holiday.holidays_type.color}`,
                }}
              >
                <i className={`fas fa-${holiday.holidays_type.icon}`} />
              </div>
              <div className="historic-item-main">
                <p className="historic-item-main-title">
                  {holiday.holidays_type.name}
                  {!filterByConfirmed && !filterByPending && (
                    <span className={`status-tag ${holiday.holidays_status.name}`}>
                      {t(`common.${holiday.holidays_status.name}`)}
                    </span>
                  )}
                </p>
                {balance && (
                <p className="historic-item-main-soldes">
                  {t('holidays.initialSolde')} : {initialSolde} {t('common.days')},
                  &nbsp;
                  {t('holidays.newSolde')} : {newSolde} {t('common.days')}
                </p>
                )}
              </div>
              <div className="historic-item-right">
                <div className="historic-item-dates">
                  <div className="historic-item-date-from">
                    <span>{moment(holiday.start_date).format('DD')}</span>
                    <span>{moment(holiday.start_date).format('MMM')}</span>
                    <span>{moment(holiday.start_date).format('YYYY')}</span>
                  </div>
                  <i className="fas fa-angle-right" />
                  <div className="historic-item-date-to">
                    <span>{moment(holiday.end_date).format('DD')}</span>
                    <span>{moment(holiday.end_date).format('MMM')}</span>
                    <span>{moment(holiday.end_date).format('YYYY')}</span>
                  </div>
                </div>
                <div className="historic-item-solde">
                  {duration}
                  <span>{t('common.days')}</span>
                </div>
              </div>

            </div>
          );
        }) : (
          <p>
            {t('holidays.noHolidaysUserHistorical')}
          </p>
        )}
      </>
    );
  };

  return (
    <div className="historics-list">
      {renderHistoricList(holidays)}
    </div>
  );
};

HolidaysUserHistorical.prototypes = {
  filterByPending: PropTypes.bool,
  filterByConfirmed: PropTypes.bool,
  limit: PropTypes.number,
  holidays: PropTypes.array.isRequired,
};

HolidaysUserHistorical.defaultProps = {
  allowAsk: false,
  filterByPending: false,
  filterByConfirmed: false,
  limit: null,
};

export default HolidaysUserHistorical;
