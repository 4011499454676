import ButtonGroup from 'common/components/ButtonGroup/ButtonGroup';
import CircleColorPicker from 'common/components/CircleColorPicker/CircleColorPicker';
import ColorPicker from 'common/components/ColorPicker/ColorPicker';
import Geolocation from 'common/components/Geolocation/Geolocation';
import IconPicker from 'common/components/IconPicker/IconPicker';
import ImageUploader from 'common/components/ImageUploader/ImageUploader';
import Select from 'common/components/Select/Select';
import TextArea from 'common/components/TextArea/TextArea';
import TimePicker from 'common/components/TimePicker/TimePicker';
import Uploader from 'common/components/Uploader/Uploader';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CheckboxCardGroup from '../CheckboxCardGroup/CheckboxCardGroup';
import RichTextarea from '../RichTextArea/RichTextArea';
import DatePicker from 'common/components/DatePicker/DatePicker';

const keepEmptyValue = (value) => (value === '' ? null : value);

export const Input = ({
  type, icon, children, onChange, ...otherProps
}) => {
  const className = otherProps.className ? otherProps.className : '';

  const handleInputChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  const handleCheckboxChange = useCallback((e) => {
    onChange(e.target.checked);
  }, [onChange]);

  otherProps.onChange = onChange;

  switch (type) {
    case 'submit':
      return (
        <button
          className="button is-submit"
          data-cy="submit"
          type="submit"
          disabled={otherProps.disabled}
        >
          {!otherProps.isSubmitting && icon && (
            <span className="icon">
              <i className={`fas fa-${icon}`} />
            </span>
          )}
          {
            otherProps.isSubmitting && (
              <span className="icon icon-loading">
                <i className="fas fa-spinner" />
              </span>
            )
          }
          <span>{otherProps.label}</span>
        </button>
      );

    case 'date':
      return <DatePicker data-cy={otherProps.name} {...otherProps} />;

    case 'datetime':
      return (<DatePicker showTimeSelect data-cy={otherProps.name} {...otherProps} />);

    case 'time':
      return (<TimePicker {...otherProps} />);

    case 'select':
      return <Select data-cy={otherProps.name} {...otherProps} />;

    case 'image':
      return <ImageUploader data-cy={otherProps.name} {...otherProps} />;

    case 'file':
      return <Uploader data-cy={otherProps.name} {...otherProps} />;

    case 'geolocation':
      return <Geolocation data-cy={otherProps.name} {...otherProps} />;

    case 'buttongroup':
      return <ButtonGroup data-cy={otherProps.name} {...otherProps} />;

    case 'checkboxcardgroup':
      return <CheckboxCardGroup data-cy={otherProps.name} {...otherProps} />;

    case 'icon':
      return <IconPicker data-cy={otherProps.name} {...otherProps} />;

    case 'checkbox':
      return (
        <div className="checkbox-input">
          <input
            id={otherProps.name}
            type="checkbox"
            data-cy={otherProps.name}
            {...otherProps}
            onChange={handleCheckboxChange}
          />
          <label htmlFor={otherProps.name}>{otherProps.label}</label>
        </div>
      );

    case 'custom':
      return React.cloneElement(children, {
        ...otherProps,
        'data-cy': otherProps.name,
      });

    case 'circle-color':
      return <CircleColorPicker {...otherProps} />;

    case 'color':
      return <ColorPicker data-cy={otherProps.name} {...otherProps} />;

    case 'textarea':
      return <TextArea {...otherProps} />;

    case 'richtext':
      return <RichTextarea {...otherProps} />;

    default:
      return (
        <input
          type={type}
          data-cy={otherProps.name.split('.')[0]}
          {...otherProps}
          onChange={handleInputChange}
          className={`input ${className} `}
        />
      );
  }
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.element,
};

Input.defaultProps = {
  name: 'input',
  type: 'text',
  onChange() { },
  icon: '',
  children: null,
};

// eslint-disable-next-line max-len
const composeValidators = (validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined);

const FormInput = ({
  icon,
  validate,
  required,
  label,
  type,
  onChange,
  children,
  isHorizontal,
  finalForm,
  name,
  isLinear,
  ...rest
}) => {
  const { t } = useTranslation();

  if (finalForm === false) {
    return (
      <Input
        name={name}
        icon={icon}
        required={required}
        label={label}
        type={type}
        onChange={onChange}
        {...rest}
      >
        {children}
      </Input>
    );
  }

  const reqRule = (value) => (value !== '' && value != null ? undefined : t('common.fieldRequired'));
  const checkboxReq = (value) => value === true ? undefined : t('common.fieldRequired');

  let validator = !validate && required ? reqRule : validate;

  validator = (type === 'checkbox' && required) ? checkboxReq : validator;

  if (Array.isArray(validator)) {
    if (validator.length) {
      validator = composeValidators(validator);
    } else {
      validator = null;
    }
  }

  const formLabel = label && required ? `${label} *` : label;

  if (type === 'submit') {
    return (
      <div className="field">
        <div className="control">
          <Input
            type={type}
            icon={icon}
            label={formLabel}
            name={name}
            {...rest}
          />
        </div>
      </div>
    );
  }

  return (
    <Field
      type={type}
      icon={icon}
      validate={validator}
      label={label}
      required={required}
      parse={keepEmptyValue}
      name={name}
      {...rest}
    >
      {({ input, meta, ...extra }) => {
        let { className } = extra;

        if (meta.touched && meta.error) {
          className = className ? `${className} is-danger` : 'is-danger';
        }

        return (
          <div className={`field ${isHorizontal ? 'is-horizontal' : ''} 
          ${meta.error ? 'fieldError' : ''} ${isLinear ? 'is-linear' : ''}`}
          >
            {type !== 'checkbox' && label && <label className="label">{label}</label>}
            <div
              className={`control ${icon && 'has-icons-left has-icons-right '}`}
            >
              <Input
                {...input}
                {...extra}
                className={className}
                onChange={(val, row) => {
                  onChange(val, row);
                  input.onChange(val);
                }}
              >
                {children}
              </Input>
              {icon && (
                <span className="icon is-small is-left">
                  <i className={`fas fa-${icon}`} />
                </span>
              )}
              {icon && meta.touched && meta.error && (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle" />
                </span>
              )}
            </div>
            {meta.touched && meta.error && (
              <p className="help is-danger">{meta.error}</p>
            )}
          </div>
        );
      }}
    </Field>
  );
};

FormInput.propTypes = {
  finalForm: PropTypes.bool,
  icon: PropTypes.string,
  isHorizontal: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.element,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  required: PropTypes.bool,
  isLinear: PropTypes.bool,

};

FormInput.defaultProps = {
  finalForm: true,
  isHorizontal: false,
  type: 'text',
  icon: '',
  label: null,
  onChange() { },
  children: null,
  validate: null,
  required: false,
  isLinear: false,
};

export default FormInput;
