import Alerts from 'common/components/Alerts/Alerts';
import Menu from 'common/components/Menu/Menu';
import useAuth from 'common/contexts/auth';
import MenuContext from 'common/contexts/menu';
import useWindowSize from 'common/hooks/useWindowSize';
import AlertHeader from 'common/components/Alerts/AlertHeader';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import moment from 'moment';

const Layout = ({ children }) => {
  const { user } = useAuth();
  const { menuActive } = useContext(MenuContext);
  const { t } = useTranslation();
  const size = useWindowSize();
  const { currentCompany } = useCompanies();

  // If Demo
  let status = 'active';

  let nbDays = 0;

  if (currentCompany) {
    if (!currentCompany.stripePaymentMethod && currentCompany.active) {
      status = 'demo';
      nbDays = moment(currentCompany.endDate).diff(moment(), 'day');
    } else if (currentCompany.active === false) {
      status = 'expired';
    }
  }

  return user ? (
    <>
      {size.isDesktop && status === 'demo' && (
      <AlertHeader actionPath="/subscribe" actionText={t('subscription.subscribeNow')}>
        <b>{t('subscription.freePeriodTitle')}</b>&nbsp;
        <span className="font-light">
          {t('subscription.freePeriodRestingBefore')}
        </span>
    &nbsp;
        <b className="day-count">{nbDays} {t('common.days')}</b>&nbsp;
        {t('subscription.freePeriodRestingAfter')}
      </AlertHeader>
      )}
      {size.isDesktop && status === 'expired' && (
      <AlertHeader actionPath="/subscribe" actionText="Payer">
        <b>{t('subscription.freePeriodTitle')}</b>&nbsp;
        <span className="font-light">
          EXPIRE
        </span>
    &nbsp;
        <b className="day-count">{nbDays} {t('common.days')}</b>&nbsp;
        {t('subscription.freePeriodRestingAfter')}
      </AlertHeader>
      )}
      <Menu />
      <main className={`${menuActive && size.isDesktop && 'menu-open'}`}>
        <Alerts />
        {children}
      </main>
    </>
  ) : (
    <>
      <Alerts />
      {children}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
