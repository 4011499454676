import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CircleProgress from '../CircleProgress/CircleProgress';

const HolidaysSoldesByTypes = ({
  allowAsk, balances,
}) => {
  const { t } = useTranslation();

  const renderSoldesList = (balances) => (
    <>
      {balances && balances.length > 0 ? balances.map(balance => {
        if (balance.holidays_type.has_balance !== true) { return null; }
        // todo get the maximum amount of day for that type of holiday
        const progress = 100;

        return (
          <div key={balance.id} className={`solde-item ${allowAsk && 'solde-item-ask'}`}>
            <div className="circle-progress-box">
              <CircleProgress progress={progress} color={balance.holidays_type?.color} />

              <div className="icon">
                <i
                  className={`fas fa-${balance.holidays_type?.icon}`}
                  style={{
                    color: `${balance.holidays_type?.color}`,
                  }}
                />
              </div>
            </div>

            <div className="solde-item-legend">
              <p className="type">{balance.holidays_type?.name}</p>
              <p
                style={{ fontWeight: 'normal' }}
              >
                <span className="big">{balance.balance}</span> {t('common.leavesLeft')}
              </p>

              {allowAsk && (
                <Link as="button" to="/holidays/add" className="discret-button">
                  {t('user.askLeave')}
                </Link>
              )}
            </div>

          </div>
        );
      }) : (
        <p>
          {t('holidays.noHolidaysUserSoldes')}
        </p>
      )}
    </>
  );

  return (
    <div className="soldes-list">
      {renderSoldesList(balances)}
    </div>
  );
};

HolidaysSoldesByTypes.prototypes = {
  user: PropTypes.object.isRequired,
  allowAsk: PropTypes.bool,
};

HolidaysSoldesByTypes.defaultProps = {
  allowAsk: false,
};

export default HolidaysSoldesByTypes;
