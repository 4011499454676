import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const Jobs = () => {
  const { t } = useTranslation();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
    removeListItem,
  } = useList('jobs', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'jobs' });
  const {
    removeItem,
  } = useItem('jobs');
  const { push } = useRouter();

  const handleDelete = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    fetchItems();
  }, [removeListItem, fetchItems, removeItem]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      className: 'table-start',
      Cell: ({ row: { original: { id, name } } }) => (
        <>
          <div
            className="crud-item-avatar"
            onClick={() => push(`/jobs/edit/${id}`)}
          >
            <span>{name[0]}</span>
          </div>
          <span className="clickable" onClick={() => push(`/jobs/edit/${id}`)}>{name}</span>
        </>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line
      Cell: ({ row: { original: { id } } }) => {

        return (
          <>
            <Button
              onClick={() => push(`/jobs/edit/${id}`)}
              icon="fa-pencil-alt"
              color="primary"
              transparent
            />
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={() => handleDelete(id)}
              icon="fa-trash-alt"
              color="danger"
              confirm
              transparent
              confirmMessage={t('common.delete')}
            />
          </>
        );
      },
    },
  ], [t, push, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('common.jobs')}
        link={{ url: '/jobs/add', icon: 'plus', label: t('common.add') }}
        subLink={{
          url: '/configure',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section list-page list-action">
        <Table
          data={items ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default Jobs;
