import fetchJSON from 'common/utils/fetchJSON';
import { useCallback, useEffect } from 'react';
import useFetch from './useFetch';
import usePersistedState from './usePersistedState';

const SORT_KEY = '_sort';
const START_KEY = '_start';
const LIMIT_KEY = '_limit';

const useList = (url, options = {}) => {
  const {
    defaultFilters = {}, defaultSort, defaultGroupBy, defaultLimit = 20, ...opts
  } = options;

  opts.sharePromise = false;
  opts.cachePrefix = opts.cachePrefix ?? `list_${url.replace(/[/?&]/g, '_')}`;

  const [itemsCount, setItemsCount] = usePersistedState(0, `${opts.cachePrefix}_itemsCount`);
  const [pageIndex, setPageIndex] = usePersistedState(0, `${opts.cachePrefix}_pageIndex`);
  const [itemsPerPage, setItemsPerPage] = usePersistedState(defaultLimit, `${opts.cachePrefix}_itemsPerPage`);

  const [filters, setFilters] = usePersistedState(defaultFilters, `${opts.cachePrefix}_filters`);
  const [sort, setSort] = usePersistedState(defaultSort, `${opts.cachePrefix}_sort`);
  const [groupBy, setGroupBy] = usePersistedState(defaultGroupBy, `${opts.cachePrefix}_groupBy`);

  const getUrl = () => {
    const params = {
      [START_KEY]: itemsPerPage * pageIndex,
      [LIMIT_KEY]: itemsPerPage,
      ...filters,
    };

    if (groupBy) {
      params[SORT_KEY] = groupBy;
    }
    if (sort) {
      params[SORT_KEY] = params[SORT_KEY] ? `${params[SORT_KEY]},${sort}` : sort;
    }

    const esc = encodeURIComponent;
    const queryParams = Object.keys(params)
      .map((key) => `${esc(key)}=${esc(params[key])}`)
      .join('&');

    return `${url}${queryParams ? '?' : ''}${queryParams}`;
  };

  const {
    data,
    error,
    setData,
    isFetching,
    isPreviousData,
    fetchData,
  } = useFetch(getUrl(), opts);

  const setFilter = useCallback(
    (key, value) => {
      setFilters((prevFilters) => {
        if (value) {
          return { ...prevFilters, [key]: value };
        }
        const newFilters = { ...prevFilters };

        delete newFilters[key];
        return newFilters;
      });
      setPageIndex(0);
      console.log('setFilter');
    },
    [setFilters, setPageIndex],
  );

  const removeListItem = useCallback(
    (id) => {
      setData((oldData) => oldData.filter(d => d.id !== id));
    },
    [setData],
  );
  const addListItem = useCallback(
    (item) => {
      setData((oldData) => oldData.push(item));
    },
    [setData],
  );

  useEffect(() => {
    const getItemsCount = async () => {
      const esc = encodeURIComponent;
      const queryParams = Object.keys(filters)
        .map((key) => `${esc(key)}=${esc(filters[key])}`)
        .join('&');

      try {
        const count = await fetchJSON({
          url: `${url}/count${queryParams ? '?' : ''}${queryParams}`,
          method: 'GET',
        });

        setItemsCount(count);
      } catch (e) {
        console.log(e);
      }
    };

    getItemsCount();
  }, [url, filters, setItemsCount]);

  return {
    items: data,
    itemsCount,
    isPreviousItems: isPreviousData,
    error,
    isFetching,
    fetchItems: fetchData,

    pageCount: Math.ceil(itemsCount / itemsPerPage),
    pageIndex,
    setPageIndex,
    itemsPerPage,
    setItemsPerPage,

    sort,
    setSort,

    groupBy,
    setGroupBy,

    filters,
    setFilter,
    setFilters,

    removeListItem,
    addListItem,
  };
};

export default useList;
