import defaultLogo from 'assets/default-logo.jpg';
import Card from 'common/components/Card/Card';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useAuth from 'common/contexts/auth';
import useList from 'common/hooks/useList';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useCompanies from 'requests/contexts/companies';

const Configure = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();

  const {
    isAdmin,
  } = useAuth();

  const { items: sites, isFetching: isSitesFetching } = useList('sites', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configuresites',
  });

  const { items: teams, isFetching: isTeamsFetching } = useList('teams', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configureteams',
  });

  const { items: todoTemplates, isFetching: isTodoTemplatesFetching } = useList('todo-templates', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configuretodotemplates',
  });

  const { items: tasks, isFetching: isTasksTypesFetching } = useList('task-types', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configuretasktypes',
  });

  const { items: jobs, isFetching: isJobsFetching } = useList('jobs', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configurejobs',
  });

  const { items: contractTypes, isFetching: isContractTypesFetching } = useList('contract-types', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configurecontracttypes',
  });

  const { items: holidaysTypes, isFetching: isHolidaysTypesFetching } = useList('holidays-types', {
    defaultFilters: {},
    defaultSort: 'name:ASC',
    cachePrefix: 'configuretypes',
  });

  const hasCompanyLogo = currentCompany?.image !== undefined
    ? currentCompany?.image[0]?.url : 'default-logo.png';

  return (
    <>
      <PageHeader
        title={t('common.configure')}
        link={isAdmin ? { url: '/account', icon: 'cog', label: t('common.account') } : null}
      />
      <section className="section section-configure">
        <h2 className="title-section">
          {t('configure.companySeparator')}
        </h2>
        <div className="cards-list columns">
          <div className="column is-two-thirds">
            <Card>
              <div className="card-custom-header">
                <div
                  style={{
                    backgroundImage: hasCompanyLogo
                      ? `url(${process.env.REACT_APP_API_URL}${currentCompany?.image !== undefined
                        ? currentCompany?.image[0]?.url : 'default-logo.png'}`
                      : `url(${defaultLogo}`,
                  }}
                  className="company-logo"
                />

                <h2 className="title is-2" style={{ paddingLeft: 140 }}>
                  {`${t('common.company')} ${currentCompany.name}`}
                  <br />
                  <small
                    style={{ fontSize: '0.8em', fontWeight: 'normal' }}
                  >
                    {currentCompany.address} {currentCompany.zipcode} {currentCompany.city}
                  </small>
                </h2>
                <Link to={`/companies/edit/${currentCompany.id}`} className="discret-button">
                  <span>{t('common.edit')}</span>
                </Link>
              </div>

              <hr />

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className="title is-2">{t('configure.titleSites')}</h2>
                <div className="flex-bar">
                  <Link to="/sites/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/sites" className="discret-button" style={{ margin: 0 }}>
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>

              {sites && sites.length ? (
                <ul style={{ display: 'flex', flexFlow: 'wrap' }}>
                  {!isSitesFetching && sites.slice(0, 4).map((site, index) => (
                    <li key={`site-${index}`} style={{ minWidth: '50%', maxWidth: '50%' }}>
                      <Link to={`/sites/edit/${site.id}`}>
                        <b>{site.name}</b>
                      </Link>
                    </li>
                  ))}
                  {sites.length > 5 && (
                  <li style={{ minWidth: '50%', maxWidth: '50%' }}>
                    <Link to="/sites" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noSites')}</p>
              )}

            </Card>
          </div>

          <div className="column is-one-third">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2">{t('configure.titleTeams')}</h2>
                <div className="flex-bar">
                  <Link to="/teams/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/teams" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>

              {teams && teams.length ? (
                <ul>
                  {!isTeamsFetching && teams.slice(0, 4).map((team, index) => (
                    <li key={`team-${index}`}>
                      <Link to={`/teams/edit/${team.id}`}>
                        <b>{team.name}</b>
                      </Link>
                    </li>
                  ))}
                  {teams.length > 5 && (
                  <li>
                    <Link to="/teams" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noTeams')}</p>
              )}

            </Card>
          </div>
        </div>

        <h2 className="title-section">
          {t('configure.activitySeparator')}
        </h2>

        <div className="cards-list columns">
          { !currentCompany.disabledModules?.tasks && (
          <div className="column is-one-third">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2" style={{ display: 'flex' }}>
                  {t('configure.titleActions')}
                  <div data-tooltip-bottom={t('configure.legendeActions')}>
                    &nbsp;
                    <i className="fas fas fa-question-circle" />
                  </div>
                </h2>
                <div className="flex-bar">
                  <Link to="/todo-templates/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/todo-templates" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>

              {todoTemplates && todoTemplates.length ? (
                <ul>
                  {!isTodoTemplatesFetching && todoTemplates.slice(0, 4).map((todoTemplate, index) => (
                    <li key={`todoTemplate-${index}`}>
                      <Link to={`/todo-templates/edit/${todoTemplate.id}`}>
                        <b>{todoTemplate.name}</b>
                      </Link>
                    </li>
                  ))}
                  {todoTemplates.length > 5 && (
                  <li>
                    <Link to="/todo-templates" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noActions')}</p>
              )}
            </Card>
          </div>
          )}

          { !currentCompany.disabledModules?.timeReport && (
          <div className="column is-one-third">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2" style={{ display: 'flex' }}>
                  {t('configure.titleActivities')}
                  <div data-tooltip-bottom={t('configure.legendeActivities')}>
                    &nbsp;
                    <i className="fas fas fa-question-circle" />
                  </div>
                </h2>
                <div className="flex-bar">
                  <Link to="/task-types/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/task-types" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>
              {tasks && tasks.length ? (
                <ul>
                  {!isTasksTypesFetching && tasks.slice(0, 4).map((task, index) => (
                    <li key={`task-${index}`}>
                      <Link to={`/task-types/edit/${task.id}`}>
                        <b>{task.name}</b>
                      </Link>
                    </li>
                  ))}
                  {tasks.length > 5 && (
                  <li>
                    <Link to="/task-types" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noActivities')}</p>
              )}

            </Card>
          </div>
          )}

          <div className="column is-one-third">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2" style={{ display: 'flex' }}>
                  {t('configure.titleJobs')}
                  <div data-tooltip-bottom={t('configure.legendeJobs')}>
                    &nbsp;
                    <i className="fas fas fa-question-circle" />
                  </div>
                </h2>
                <div className="flex-bar">
                  <Link to="/jobs/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/jobs" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>
              {jobs && jobs.length ? (
                <ul>
                  {!isJobsFetching && jobs.slice(0, 4).map((job, index) => (
                    <li key={`job-${index}`}>
                      <Link to={`/jobs/edit/${job.id}`}>
                        <b>{job.name}</b>
                      </Link>
                    </li>
                  ))}
                  {jobs.length > 5 && (
                  <li>
                    <Link to="/jobs" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noJobs')}</p>
              )}

            </Card>
          </div>

        </div>

        <h2 className="title-section">
          {t('configure.userSeparator')}
        </h2>

        <div className="cards-list columns">
          <div className="column is-half">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2">{t('configure.titleContracts')}</h2>
                <div className="flex-bar">
                  <Link to="/contract-types/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/contract-types" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>
              {contractTypes && contractTypes.length ? (
                <ul>
                  {!isContractTypesFetching && contractTypes.slice(0, 4).map((contractType, index) => (
                    <li key={`contractType-${index}`}>
                      <Link to={`/contract-types/edit/${contractType.id}`}>
                        <b>{contractType.name}</b>
                      </Link>
                    </li>
                  ))}
                  {contractTypes.length > 5 && (
                    <li>
                      <Link to="/contract-types" className="button-see-all">
                        <b>{t('configure.seeAll')}</b>
                      </Link>
                    </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noContracts')}</p>
              )}

            </Card>
          </div>
          { !currentCompany.disabledModules?.holidays && (
          <div className="column is-half">
            <Card>
              <div className="card-custom-header">
                <h2 className="title is-2">{t('configure.titleHolidays')}</h2>
                <div className="flex-bar">
                  <Link to="/holidays-types/add" style={{ paddingRight: '0.5rem' }}>
                    <i className="fas fa-plus" />
                  </Link>
                  <Link to="/holidays-types" className="discret-button">
                    <span>{t('configure.manage')}</span>
                  </Link>

                </div>
              </div>

              {holidaysTypes && holidaysTypes.length ? (
                <ul>
                  {!isHolidaysTypesFetching && holidaysTypes.slice(0, 4).map((holidaysType, index) => (
                    <li key={`holidaysType-${index}`}>
                      <Link to={`/holidays-types/edit/${holidaysType.id}`}>
                        <b>{holidaysType.name}</b>
                      </Link>
                    </li>
                  ))}
                  {holidaysTypes.length > 5 && (
                  <li>
                    <Link to="/holidays-types" className="button-see-all">
                      <b>{t('configure.seeAll')}</b>
                    </Link>
                  </li>
                  )}
                </ul>
              ) : (
                <p>{t('configure.noHolidays')}</p>
              )}

            </Card>
          </div>
          )}
        </div>

      </section>
    </>

  );
};

export default Configure;
