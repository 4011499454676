import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useAuth from 'common/contexts/auth';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import UserSelect from 'users/components/UserSelect';

const TeamForm = ({ onSubmit, team }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { currentCompany } = useCompanies();

  const initialValues = useMemo(() => team?.id ? {
    ...team,
    company: team?.company?.id,
    users: team?.users.map((user) => user.id),
  } : {
    name: '',
    company: currentCompany.id,
    users: [],
  }, [team, currentCompany]);

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({
        handleSubmit, submitting, pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <div className="columns">
            <div className="column">
              <Card>
                { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                <FormInput
                  type="custom"
                  name="company"
                  label={t('common.company')}
                >
                  <CompanySelect />
                </FormInput>
                )}
                <FormInput
                  name="name"
                  label={t('common.name')}
                  required
                />
                <FormInput
                  type="custom"
                  name="users"
                  label={t('common.users')}
                >
                  <UserSelect multiple active={1} />
                </FormInput>
                <FormInput
                  type="submit"
                  label={t('common.send')}
                  icon="paper-plane"
                  disabled={submitting || pristine}
                  isSubmitting={submitting}
                />
              </Card>
            </div>
          </div>

        </form>
      )}
    />
  );
};

TeamForm.propTypes = {
  onSubmit: PropTypes.func,
  team: PropTypes.object,
};

TeamForm.defaultProps = {
  onSubmit() { },
  team: {},
};

export default TeamForm;
