import Account from 'account/pages/Account/Account';
import Subscribe from 'account/pages/Subscribe/Subscribe';
import CreateAccount from 'common/pages/CreateAccount/CreateAccount';
import ForgotPassword from 'common/pages/ForgotPassword/ForgotPassword';
import Home from 'common/pages/Home/Home';
import Error404 from 'common/pages/Page404';
import ResetPassword from 'common/pages/ResetPassword/ResetPassword';
import SignIn from 'common/pages/SignIn/SignIn';
import ContractTypeForm from 'contractTypes/pages/ContractTypeForm';
import ContractTypesList from 'contractTypes/pages/ContractTypesList';
import HolidaysTypesForm from 'holidaysTypes/pages/holidaysTypesForm';
import HolidaysTypesList from 'holidaysTypes/pages/holidaysTypesList';
import HolidaysPlanner from 'planners/pages/HolidaysPlanner/HolidaysPlanner';
import Profile from 'profile/pages/Profile/Profile';
import ActivityReport from 'requests/pages/ActivityReport/ActivityReport';
import CompanyList from 'requests/pages/Companies/Companies';
import CompanyPage from 'requests/pages/Companies/Company';
import Configure from 'requests/pages/Configure/Configure';
import Holidays from 'requests/pages/Holidays/Holidays';
import HolidaysList from 'requests/pages/Holidays/HolidaysList';
import JobPage from 'requests/pages/Jobs/Job';
import JobList from 'requests/pages/Jobs/Jobs';
import Project from 'requests/pages/Projects/Project';
import ProjectDetails from 'requests/pages/Projects/ProjectDetails';
import Projects from 'requests/pages/Projects/Projects';
import SitePage from 'requests/pages/Sites/Site';
import SiteList from 'requests/pages/Sites/Sites';
import Summary from 'requests/pages/Summary/Summary';
import TaskType from 'requests/pages/TaskTypes/TaskType';
import TaskTypes from 'requests/pages/TaskTypes/TaskTypes';
import TeamPage from 'requests/pages/Teams/Team';
import TeamList from 'requests/pages/Teams/Teams';
import MyTodos from 'requests/pages/Todos/MyTodos';
import TodoPage from 'requests/pages/Todos/Todo';
import TodoList from 'requests/pages/Todos/Todos';
import TodoTemplatePage from 'requests/pages/TodoTemplates/TodoTemplate';
import TodoTemplateList from 'requests/pages/TodoTemplates/TodoTemplates';
import BlogArticle from 'requests/pages/Wiki/BlogArticle';
import Wiki from 'requests/pages/Wiki/Wiki';
import UserDetail from 'users/pages/UserDetail/UserDetail';
import UserFormPage from 'users/pages/UserFormPage/UserFormPage';
import UsersList from 'users/pages/UsersList/UsersList';
import UsersTrombinoscope from 'users/pages/UsersTrombinoscope/UsersTrombinoscope';
import BlogForm from './requests/pages/Wiki/BlogForm';
import CategoryForm from './requests/pages/Wiki/CategoryFrom';
import WikiForm from './requests/pages/Wiki/WikiForm';

// const admins = ['superadmin', 'admin'];
const managers = ['superadmin', 'admin', 'manager'];
const all = ['superadmin', 'admin', 'manager', 'Authenticated'];

const routes = [
  { path: '/reset-password/:id', component: ResetPassword },
  { path: '/login', component: SignIn },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/create-account', component: CreateAccount },
  { path: '/', roles: all, component: Home },
  { path: '/trombinoscope', roles: all, component: UsersTrombinoscope },
  { path: '/users', roles: managers, component: UsersList },
  { path: '/users/profile/:id', roles: all, component: UserDetail },
  { path: '/users/add', roles: managers, component: UserFormPage },
  { path: '/users/edit/:id', roles: all, component: UserFormPage },
  { path: '/holidays', roles: all, component: HolidaysList },
  { path: '/holidays/add', roles: all, component: Holidays },
  { path: '/holidays/edit/:id', roles: all, component: Holidays },
  { path: '/projects', roles: managers, component: Projects },
  { path: '/projects/detail/:id', roles: managers, component: ProjectDetails },
  { path: '/projects/add', roles: managers, component: Project },
  { path: '/projects/edit/:id', roles: managers, component: Project },
  { path: '/task-types', roles: managers, component: TaskTypes },
  { path: '/task-types/add', roles: managers, component: TaskType },
  { path: '/task-types/edit/:id', roles: managers, component: TaskType },
  { path: '/working-days', roles: all, component: ActivityReport },
  { path: '/profile', roles: all, component: Profile },
  { path: '/account', component: Account },
  { path: '/subscribe', component: Subscribe },
  { path: '/summary', roles: managers, component: Summary },
  { path: '/holidays-planner', roles: all, component: HolidaysPlanner },
  { path: '/contract-types', roles: managers, component: ContractTypesList },
  { path: '/contract-types/add', roles: managers, component: ContractTypeForm },
  { path: '/contract-types/edit/:id', roles: managers, component: ContractTypeForm },
  { path: '/holidays-types', roles: managers, component: HolidaysTypesList },
  { path: '/holidays-types/add', roles: managers, component: HolidaysTypesForm },
  { path: '/holidays-types/edit/:id', roles: managers, component: HolidaysTypesForm },
  { path: '/blog', roles: all, component: Wiki },
  { path: '/blog/article/:id', roles: all, component: BlogArticle },
  { path: '/blog/add', roles: managers, component: BlogForm },
  { path: '/blog/edit/:id', roles: managers, component: BlogForm },
  { path: '/wiki', roles: all, component: Wiki },
  { path: '/wiki/add', roles: managers, component: WikiForm },
  { path: '/wiki/addCategory', roles: managers, component: CategoryForm },
  { path: '/wiki/edit/category/:id', roles: managers, component: CategoryForm },
  { path: '/wiki/edit/article/:id', roles: managers, component: WikiForm },
  { path: '/configure', roles: managers, component: Configure },
  { path: '/companies', roles: managers, component: CompanyList },
  { path: '/companies/add', roles: managers, component: CompanyPage },
  { path: '/companies/edit/:id', roles: managers, component: CompanyPage },
  { path: '/sites', roles: managers, component: SiteList },
  { path: '/sites/add', roles: managers, component: SitePage },
  { path: '/sites/edit/:id', roles: managers, component: SitePage },
  { path: '/teams', roles: managers, component: TeamList },
  { path: '/teams/add', roles: managers, component: TeamPage },
  { path: '/teams/edit/:id', roles: managers, component: TeamPage },
  { path: '/my-tasks', roles: all, component: MyTodos },
  { path: '/my-tasks/add', roles: all, component: TodoPage },
  { path: '/my-tasks/edit/:id', roles: all, component: TodoPage },
  { path: '/todos', roles: managers, component: TodoList },
  { path: '/todos/add', roles: managers, component: TodoPage },
  { path: '/todos/edit/:id', roles: managers, component: TodoPage },
  { path: '/todo-templates', roles: managers, component: TodoTemplateList },
  { path: '/todo-templates/add', roles: managers, component: TodoTemplatePage },
  { path: '/todo-templates/edit/:id', roles: managers, component: TodoTemplatePage },
  { path: '/jobs', roles: managers, component: JobList },
  { path: '/jobs/add', roles: managers, component: JobPage },
  { path: '/jobs/edit/:id', roles: managers, component: JobPage },
  { path: '*', exact: false, component: Error404 },
];

export default routes;
