import useItem from 'common/hooks/useItem';
import useList from 'common/hooks/useList';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import Loader from '../../../components/Loader/Loader';
import { useAuth } from '../../../contexts/auth';

const HomeTodos = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const today = new Date();

  const getDateToday = () => {
    const date = moment(today).format('LL');
    const slidingPassedDay = moment(today).subtract(24, 'hour').toDate();

    return { date, slidingPassedDay };
  };

  const {
    fetchItems: fetchTodoItems,
    items: todos,
    isFetching: isTodoFetching,
  } = useList('todos', {
    defaultFilters: {
      user: currentUser.id,
      updated_at_gte: moment(today).subtract(1, 'month').toISOString(),
    },
    defaultSort: 'updated_at:DESC',
    cachePrefix: 'hometodos',
  });

  const [filteredTodos, setFilteredTodos] = useState([]);

  useEffect(() => {
    const today = new Date();
    const slidingPassedDay = moment(today).subtract(24, 'hour').toDate();

    setFilteredTodos(todos?.filter(
      (t) => (t.state !== true
        || moment(t.updated_at).toDate() > slidingPassedDay),
    ));
  }, [todos]);

  const {
    updateItem: updateTodoItem,
  } = useItem('todos');

  const handleChangeTodoStatus = useCallback(async (todo) => {
    await updateTodoItem(todo.id, { ...todo, state: !todo.state });
    fetchTodoItems();
  }, [updateTodoItem, fetchTodoItems]);

  const handleChangeSubitemStatus = useCallback(async (todo, index) => {
    const updatedSubitems = todo.subitems;

    updatedSubitems[index].state = !updatedSubitems[index].state;
    const missingCheck = updatedSubitems.filter(val => val.state).length < updatedSubitems.length;

    await updateTodoItem(todo.id, { ...todo, state: !missingCheck, subitems: updatedSubitems });

    fetchTodoItems();
  }, [updateTodoItem, fetchTodoItems]);

  return (

    <Card>
      <img src="assets/welcome-picture.png" alt="Welcome person" className="welcome-picture" />
      <div className="welcome-top">
        <h1><b>{t('common.welcome')}, &nbsp;</b>
          {currentUser?.name}
        </h1>
        <p>
          <small>{t('dashboard.dateTodayLabel')} : </small><br />
          {getDateToday().date}
        </p>
      </div>

      <div className="welcome-bottom">
        {isTodoFetching && (
          <Loader isScreen permanant />
        )}
        {filteredTodos && filteredTodos.length > 0 ? (
          <div className="todolist">
            { filteredTodos.map((todo, key) => (
              <div key={key} className={`todo-item todo-item ${todo.state ? 'is-done' : 'is-pending'}`}>
                <div
                  data-tooltip-bottom={todo.subitems && todo.subitems.length ? t('todolist.automaticCheck') : null}
                >
                  <div
                    className="pseudo-checkbox"
                    onClick={() => handleChangeTodoStatus(todo)}
                    style={{ pointerEvents: todo.subitems && todo.subitems.length ? 'none' : 'all' }}
                  >
                    <input
                      id={todo.name}
                      type="checkbox"
                      className="is-checkradio"
                                // defaultChecked={todo.state}
                      checked={todo.state}
                      disabled={todo.subitems && todo.subitems.length}
                    />
                    <label>
                      {todo.name}
                    </label>
                  </div>
                </div>
                {todo.description && (
                  <ReactQuill
                    value={todo.description}
                    readOnly
                    theme="bubble"
                  />
                )}

                {todo.subitems && todo.subitems.length > 0 && (
                  <ul className="subitems">
                    { todo.subitems.map((subitem, index) => (
                      <li
                        key={index}
                        className={`todo-subitem 
                                  ${subitem.state ? 'is-done' : 'is-pending'}`}
                      >
                        <div
                          className="pseudo-checkbox"
                          onClick={() => handleChangeSubitemStatus(todo, index)}
                        >
                          <input
                            id={subitem.name}
                            type="checkbox"
                            className="is-checkradio"
                                    // defaultChecked={subitem.state}
                            checked={subitem.state}
                          />
                          <label>
                            {subitem.name}
                          </label>
                        </div>
                        {subitem.description && (
                        <ReactQuill
                          value={subitem.description}
                          readOnly
                          theme="bubble"
                        />
                        )}
                      </li>
                    ))}

                  </ul>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>{t('todolist.homeNoTasks')}</p>
        )}
      </div>

      <div className="card-footer">
        <Link className="button is-light" to={{ pathname: '/my-tasks' }}>
          <i className="fas fa-eye" style={{ marginRight: '0.5rem' }} />
          {t('todolist.allTodos') }
        </Link>
      </div>

    </Card>

  );
};

export default HomeTodos;
