import Confirm from 'common/components/Confirm/Confirm';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmWrap = ({
  children, message, disabled,
}) => {
  const [modalActive, setModalActive] = useState(false);
  const { t } = useTranslation();
  const child = React.Children.only(children);
  const confirmMessage = message ?? t('common.confirmMessage');

  const handleShow = () => setModalActive(true);
  const closeModal = () => setModalActive(false);

  const handleConfirm = () => {
    if (child.props.onClick) child.props.onClick();
    setModalActive(false);
  };

  if (disabled) {
    return child;
  }

  return (
    <>
      {React.cloneElement(child, { onClick: handleShow })}
      {modalActive && (<Confirm message={confirmMessage} confirmModal={handleConfirm} closeModal={closeModal} />) }
    </>
  );
};

ConfirmWrap.propTypes = {
  message: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ConfirmWrap.defaultProps = {
  message: null,
  disabled: false,
};

export default ConfirmWrap;
