import useAuth from 'common/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useHolidays from 'requests/contexts/holidays';
import Card from '../../../components/Card/Card';

const HomePendingVacations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user: currentUser, isAdmin } = useAuth();
  const [pendingVacations, setPendingVacations] = useState([]);
  const [holidayTypes, setHolidayTypes] = useState([]);
  const {
    items: holidays,
  } = useHolidays();

  useEffect(() => {
    fetchJSON({
      url: 'holidays-types',
      method: 'GET',
    }).then((results) => {
      setHolidayTypes(results);
    });
  }, []);

  useEffect(() => {
    setPendingVacations([]);
    holidays.forEach(holiday => {
      if (holiday.holidays_status.id === 1) {
        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };
        const startDate = Intl.DateTimeFormat('fr', options)
          .format(new Date(holiday.start_date));
        const endDate = Intl.DateTimeFormat('fr', options)
          .format(new Date(holiday.end_date));
        const type = holidayTypes.find(item => item.id === holiday.holidays_type.id);

        if (!isAdmin) {
          if (holiday.user.id === currentUser?.id) {
            setPendingVacations(pendingVacations => [...pendingVacations, {
              startDate,
              endDate,
              holidayType: type?.name,
              username: holiday.user.username,
            }]);
          }
        } else {
          setPendingVacations(pendingVacations => [...pendingVacations, {
            startDate,
            endDate,
            holidayType: type?.name,
            username: holiday.user.username,
          }]);
        }
      }
    });
  }, [isAdmin, holidayTypes, holidays, currentUser]);

  return (
    <>
      { // TODO, design manager
      currentUser?.role.name === 'manager' && pendingVacations.length > 0 && (
        <Card
          title={isAdmin ? t('dashboard.holidaysToValidate') : t('dashboard.pendingHolidays')}
          type="pendingHolidays"
          list={pendingVacations}
          onClick={() => history.push('/holidays')}
        />
      )
    }
    </>
  );
};

export default HomePendingVacations;
