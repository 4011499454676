import DebounceInput from 'common/components/DebounceInput/DebounceInput';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, useSortBy, useTable } from 'react-table';
import Loader from '../Loader/Loader';

const DesktopTable = ({
  data,
  columns,
  isFetching,
  pageCount: controlledPageCount,
  filters,
  setFilter,
  pageIndex,
  setPageIndex,
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex: currentPageIndex },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      manualPagination: true,
      initialState: { pageIndex, pageSize: 10 },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (pageIndex !== currentPageIndex) {
      setPageIndex(currentPageIndex);
    }
  }, [currentPageIndex, pageIndex, setPageIndex]);

  const handleChangeFilter = useCallback(
    (name, value) => {
      // TODO ca va faire deux fetchData, mais super compliqué a optimiser,
      // il faut un debounce mais aussi modifier le fetchData pour que la fonction ne change jamais
      gotoPage(0);
      setFilter(name, value);
    },
    [gotoPage, setFilter],
  );

  return (
    <div className={controlledPageCount !== 1 ? 'table-box has-paginator' : 'table-box'}>
      <div className="table-padding">
        <table className="table is-striped is-fullwidth" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`header-${index}`}>
                {headerGroup.headers.map((column, key) => column.hidden ? null : (
                  <th key={`column-${key}`}>
                    <span
                      className="th-label"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {!column.filterName && column.render('Header')}
                      {column.isSorted
                        && (column.isSortedDesc ? (
                          <i className="ml fas fa-sort-down" />
                        ) : (
                          <i className="ml fas fa-sort-up" />
                        ))}
                    </span>
                    {column.filterName
                      && (column.filterComponent ? (
                        <column.filterComponent
                          value={filters[column.filterName]}
                          data-cy={column.filterName}
                          onChange={(value) => {
                            column.customFormat
                              ? handleChangeFilter(
                                column.filterName,
                                column.customFormat(value),
                              )
                              : handleChangeFilter(column.filterName, value);
                          }}
                        />
                      ) : (
                        <DebounceInput
                          className="input is-filter"
                          placeholder={column.render('Header')}
                          value={filters[column.filterName]}
                          onChange={(value) => handleChangeFilter(column.filterName, value)}
                        />
                      ))}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {data.length === 0 && isFetching && (
            <tr>
              <td>
                <Loader isScreen permanant />
              </td>
            </tr>
            )}
            {!isFetching && !page.length && (
              <tr>
                <td>
                  <span className="table-no-result">{t('common.noResult')}</span>
                </td>
              </tr>
            ) }
            {
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr key={`row-${index}`} data-tr={row.name} {...row.getRowProps()}>
                    {row.cells.map((cell, key) => cell.column.hidden ? null : (
                      <td
                        key={`cell-${key}`}
                        data-cy={row.name}
                        data-row={cell.column.name}
                        {...cell.getCellProps({
                          className: cell.column.className,
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            }

            {page.length > 0 && controlledPageCount !== 1 && (
            <tr className="paginator-tr">
              <td>
                <nav className="pagination">
                  <ul className="pagination-nav">
                    <li>
                      <button
                        className="pagination-previous"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage || isFetching}
                      >
                        <span className="icon">
                          <i className="fas fa-angle-double-left" />
                        </span>
                      </button>
                    </li>
                  </ul>
                  <ul className="pagination-list">
                    {Array.from({ length: pageOptions.length }, (page, i) => (
                      <li key={`page-${i}`}>
                        <button
                          className={`pagination-link ${
                            pageIndex === i && 'is-current'
                          }`}
                          disabled={pageIndex !== i && isFetching}
                          onClick={() => gotoPage(i)}
                        >
                          <span>{pageIndex === i && isFetching ? <i className="fas fa-spinner rotate" /> : i + 1}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                  <ul className="pagination-nav">
                    <li>
                      <button
                        className="pagination-next"
                        onClick={() => nextPage()}
                        disabled={!canNextPage || isFetching}
                      >
                        <span className="icon">
                          <i className="fas fa-angle-double-right" />
                        </span>
                      </button>
                    </li>
                  </ul>
                </nav>

              </td>
            </tr>

            )}

          </tbody>
        </table>
      </div>

    </div>
  );
};

DesktopTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetchData: PropTypes.func,
  isFetching: PropTypes.bool,
  pageCount: PropTypes.number,
  filters: PropTypes.object,
  setFilter: PropTypes.func,
  setPageIndex: PropTypes.func,
};

DesktopTable.defaultProps = {
  filters: {},
  setFilter() {},
  fetchData() {},
  setPageIndex() {},
  isFetching: false,
  pageCount: 1,
};

export default DesktopTable;
