import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import moment from 'moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useMemo
} from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import UserSelect from 'users/components/UserSelect';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import useRouter from '../../../common/hooks/use-router';
import useItem from '../../../common/hooks/useItem';

const BlogForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { user: currentUser } = useAuth();
  const { setAlert } = useContext(AlertsContext);
  const { id: articleId } = router.match.params;
  const {
    saveItem, updateItem, item, error,
  } = useItem('article', articleId);

  const { currentCompany } = useCompanies();

  const initialValues = useMemo(() => item?.id ? ({
    ...item,
    publishedat: item.publishedat ?? Date.now(),
    published_by: item.published_by?.id,
    company: item.company?.id,
    user: item.user?.id,
  })
    : ({
      user: currentUser.id,
      company: currentCompany.id,
      title: '',
      publishedat: moment().startOf('day').format(),
      published_by: currentUser.id,
      content: '',
      isWiki: false,
      image: [],
      ...item,
    }), [item, currentUser, currentCompany]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/blog');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (data) => {
    const article = { ...data };

    article.isWiki = false;
    article.company = currentUser.company;

    if (article.id) {
      await updateItem(article.id, article);
      back();
    } else {
      await saveItem(article);
      back();
    }
  }, [currentUser, updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t('common.add')}
        subLink={{
          url: '/blog',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <Form
          onSubmit={onSubmit}
          subscription={{ values: true, submitting: true, pristine: true }}
          initialValues={initialValues}
          render={({
            handleSubmit, submitting, pristine,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />
              <div className="columns">
                <div className="column is-two-thirds">
                  <Card>
                    <FormInput
                      name="title"
                      label={t('blog.title')}
                      required
                    />
                    <FormInput
                      name="content"
                      type="richtext"
                      label={t('blog.content')}
                      required
                    />
                  </Card>
                </div>
                <div className="column is-one-third contains-image-modal">
                  <Card>
                    { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                    <FormInput
                      type="custom"
                      name="company"
                      label={t('common.company')}
                    >
                      <CompanySelect />
                    </FormInput>
                    )}
                    <FormInput
                      name="published"
                      type="checkbox"
                      label={t('blog.publish')}
                    />
                    <FormInput
                      name="publishedat"
                      label={t('common.published_at')}
                      type="datetime"
                      required
                    />
                    <FormInput
                      type="custom"
                      name="published_by"
                      label={t('common.published_by')}
                    >
                      <UserSelect active={1} />
                    </FormInput>
                    <FormInput
                      name="image"
                      label={t('blog.image')}
                      type="image"
                    />
                  </Card>
                </div>
              </div>

              <div className="stickySubmit">
                <FormInput
                  type="submit"
                  label={t('common.send')}
                  icon="paper-plane"
                  disabled={submitting || pristine}
                  isSubmitting={submitting}
                />
              </div>

            </form>
          )}
        />
      </section>
    </>
  );
};

export default BlogForm;
