import PageHeader from 'common/components/PageHeader/PageHeader';
import CardLayoutTable from 'common/components/Table/CardLayoutTable';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import JobSelect from 'requests/components/JobSelect';
import SiteSelect from 'requests/components/SiteSelect';
import TeamSelect from 'requests/components/TeamSelect';
import useList from '../../../common/hooks/useList';

const UsersTrombinoscope = () => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { isAdmin, isManager } = useAuth();

  const {
    items: users,
    isFetching,
    filters,
    setFilter,
    setGroupBy,
    groupBy,
    setPageIndex,
    pageIndex,
    pageCount,
  } = useList('users', {
    defaultFilters: { active: true },
    defaultSort: 'username:ASC',
    cachePrefix: 'trombinoscope',
  });

  const activeUsers = users?.filter((user) => user.active);

  const groupByOptions = [
    { value: '', label: t('common.none') },
    { value: 'role.name:ASC', label: t('common.role') },
    { value: 'site.name:ASC', label: t('common.site') },
    { value: 'job.name:ASC', label: t('common.job') },
  ];

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'username',
      filterName: 'username_contains',
      className: 'table-start',
      Cell: ({
        row: {
          original: {
            id, username, avatar,
          },
        },
      }) => (
        <div className="trombinoscope-item">
          <div
            className="user-avatar"
            style={{
              backgroundImage: avatar
                ? `url(${process.env.REACT_APP_API_URL}${avatar.url})` : 'none',
            }}
            onClick={(isManager || isAdmin) ? () => push(`/users/profile/${id}`) : null}
          >
            {!avatar && (
            <span>{username[0]}</span>
            )}
          </div>

          <div className="card-custom-header">
            <h2 className="title is-2">
              {username}
            </h2>
          </div>
        </div>
      ),
    },
    {
      Header: t('common.role'),
      accessor: 'role',
      Cell: ({ row: { original: { role } } }) => (
        role ? (
          <span>{t(`roles.${role.name}`)}</span>
        ) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },
    {
      Header: t('common.email'),
      accessor: 'email',
      filterName: 'email_contains',
      Cell: ({ row: { original: { email } } }) => (
        email ? (
          <a href={`mailto:${email}`}>{email}</a>
        ) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },
    {
      Header: t('user.phone'),
      Cell: ({ row: { original: { phone } } }) => (
        phone ? (
          <a href={`tel:${phone}`}>{phone}</a>
        ) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },
    {
      Header: t('common.job'),
      filterName: 'job',
      accessor: 'job',
      filterComponent: (props) => <JobSelect isFilter {...props} />,
      Cell: ({ row: { original: { job } } }) => (
        job ? (
          <span>{job.name}</span>
        ) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site',
      accessor: 'site',
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { site } } }) => (
        site ? (
          <span>{site.name}</span>
        ) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },
    {
      Header: t('common.teams'),
      filterName: 'teams',
      accessor: 'teams',
      filterComponent: (props) => <TeamSelect isFilter {...props} />,
      Cell: ({ row: { original: { teams } } }) => (
        teams.length > 0 ? teams.map((team, key) => (
          <div className="status-tag pending" key={`team-${key}`}>{team.name}</div>
        )) : (
          <span>{t('common.undefined')}</span>
        )
      ),
    },

  ], [t, push, isAdmin, isManager]);

  return (
    <>
      <PageHeader
        title={t('common.trombinoscope')}
        link={{ url: '/users/add', icon: 'user-plus', label: t('users.addUser') }}
      />
      <section className="section">

        <div className="directory-list">
          <CardLayoutTable
            data={activeUsers ?? []}
            columns={columns}
            isFetching={isFetching}
            pageCount={pageCount}
            filters={filters}
            setFilter={setFilter}
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            forceMobileLayout
            showDesktopFilters
            groupByOptions={groupByOptions}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
          />
        </div>

      </section>
    </>
  );
};

export default UsersTrombinoscope;
