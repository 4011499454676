import React, {
  useMemo, useState,
} from 'react';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import useFetch from 'common/hooks/useFetch';
import useCompanies from 'requests/contexts/companies';

const useOptions = () => {
  // const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '18px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const CardContent = ({
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { currentCompany } = useCompanies();
  const { data: intent } = useFetch(`companies/${currentCompany.id}/intent`);
  const { fetchData } = useFetch(`companies/${currentCompany.id}/paid`, { manual: true });
  const handleSubmit = async event => {
    setError(null);
    setLoading(true);

    try {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: currentCompany.name,
          },
        },
        setup_future_usage: 'off_session',
      });

      if (result.paymentIntent) {
        const payload = {
          transactionId: result.paymentIntent.id,
          payment_method: result.paymentIntent.payment_method,
        };

        await fetchData({ method: 'POST', payload });
        onSuccess();
      } else {
        setError(result.error.message);
        console.log('ERROR', result.error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="payment-resume">
        <div className="payment-resume-left">
          <p className="is-valid">{t('subscription.paymentDescriptionCard')}</p>
          <p className="is-valid">{t('subscription.paymentPrice')}</p>
        </div>
        <div className="payment-resume-right">
          <form>
            {t('subscription.subscriptionLabelCardCode')}
            <CardNumberElement
              options={options}
              onReady={() => {
                // console.log('CardNumberElement [ready]');
              }}
              onChange={() => {
                // console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                // console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                // console.log('CardNumberElement [focus]');
              }}
            />
            {t('subscription.subscriptionLabelCardExpiration')}
            <CardExpiryElement
              options={options}
              onReady={() => {
                // console.log('CardNumberElement [ready]');
              }}
              onChange={() => {
                // console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                // console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                // console.log('CardNumberElement [focus]');
              }}
            />
            {t('subscription.subscriptionLabelCardSecurity')}
            <CardCvcElement
              options={options}
              onReady={() => {
                // console.log('CardNumberElement [ready]');
              }}
              onChange={() => {
                // console.log('CardNumberElement [change]', event);
              }}
              onBlur={() => {
                // console.log('CardNumberElement [blur]');
              }}
              onFocus={() => {
                // console.log('CardNumberElement [focus]');
              }}
            />

          </form>

        </div>
      </div>
      { Boolean(error) && (
      <div className="is-error">{error}</div>
      )}
      <div className="wizard-footer">
        <button className="button is-submit" disabled={isLoading} onClick={handleSubmit}>
          {isLoading ? t('common.loading') : t('common.save')}
        </button>
      </div>
    </>
  );
};

export default CardContent;
