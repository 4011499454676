import useRouter from 'common/hooks/use-router';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useArticles from 'requests/contexts/article';
import Article from '../../../common/components/Article/Article';
import PageHeader from '../../../common/components/PageHeader/PageHeader';

const BlogArticle = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    item: article,
    fetchItem: fetchArticle,
    remove: removeArticle,
  } = useArticles();
  const { id: articleId } = router.match.params;
  const history = useHistory();

  useEffect(() => {
    fetchArticle(articleId);
  }, [fetchArticle, articleId]);

  const handleDeleteArticle = useCallback(async (id) => {
    await removeArticle(id);
    history.push('/blog');
  }, [history, removeArticle]);

  return (
    <>
      <PageHeader
        title={article ? `${t('blog.currentReading')} : ${article.title}` : t('blog.headingTitle')}
        link={{
          url: `/blog/edit/${articleId}`,
          icon: 'edit',
          label: t('common.edit'),
        }}
        subLink={{
          url: '/blog',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}

      />
      {article && (
      <div className="blog-page">
        <div className="blog-page-item">
          <Article
            isWiki
            isBlog
            title={article.title}
            content={article.content}
            image={article.image}
            contributor={(article?.user?.username)
              ? `${article?.user.username}` : t('blog.unknown')}
            date={article.updated_at}
            isClicked
            handleDelete={() => handleDeleteArticle(article.id)}
            handleModify={() => history.push(`/blog/edit/${article.id}`)}
          />
        </div>
      </div>
      )}
    </>
  );
};

export default BlogArticle;
