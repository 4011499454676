import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import AlertsContext from 'common/contexts/alerts';
import { useAuth } from 'common/contexts/auth';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SignIn = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { setAlert } = useContext(AlertsContext);

  const onSubmit = async ({ email, password }) => {
    const user = await auth.signin(email, password);

    if (user) {
      setAlert(t('auth.loginSuccess'), 'success');
    }
  };

  return (
    <section className="section login-page">
      <div className="offline-logo" style={{ backgroundImage: 'url(/assets/default-logo.jpg)' }} />
      <Card>
        <Form
          onSubmit={onSubmit}
          initialValues={{ email: '', password: '' }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <form onSubmit={handleSubmit} noValidate>
                <FormInput
                  type="email"
                  name="email"
                  label={t('common.email')}
                  icon="envelope"
                />
                <FormInput
                  type="password"
                  name="password"
                  label={t('common.password')}
                  icon="lock"
                />
                <div className="form-footer">
                  <FormInput
                    className="submit-btn"
                    type="submit"
                    label={t('auth.login')}
                    disabled={submitting || pristine}
                    isSubmitting={submitting}
                  />
                  <p>
                    <Link to="/forgot-password" className="lead">{t('auth.forgotPassword')}</Link>
                  </p>
                </div>
              </form>
            </>
          )}
        />
      </Card>
    </section>
  );
};

export default SignIn;
