import ConfirmWrap from 'common/components/ConfirmWrap/ConfirmWrap';
import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  onClick,
  icon,
  color,
  confirm,
  transparent,
  confirmMessage,
  label,
  outlined,
  additionnalClasses,
  loading,
  ...rest
}) => {
  const classNames = ['button', additionnalClasses];

  if (transparent) classNames.push('is-transparent');
  if (outlined) classNames.push('is-outlined');
  if (color) classNames.push(`is-${color}`);

  const btn = (
    <button
      type="button"
      onClick={onClick}
      className={classNames.join(' ')}
      disabled={loading}
      {...rest}
    >
      { icon && (
        <span className="icon">
          <i className={`fas ${icon}`} />
        </span>
      ) }
      { label && (
        <span className="button-label">{ label }</span>
      ) }
      { loading && (
        <span className="icon">
          <i className="fas fa-spinner fa-spin" />
        </span>
      ) }
    </button>
  );

  if (confirm) {
    return (<ConfirmWrap message={confirmMessage}>{ btn }</ConfirmWrap>);
  }
  return btn;
};

Button.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  color: PropTypes.string,
  confirm: PropTypes.bool,
  confirmMessage: PropTypes.string,
  label: PropTypes.string,
  transparent: PropTypes.bool,
  outlined: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  confirm: false,
  icon: null,
  color: null,
  confirmMessage: null,
  label: null,
  onClick() {},
  transparent: false,
  outlined: false,
  loading: false,
};

export default Button;
