/* eslint-disable object-curly-newline */
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import fetchJSON from 'common/utils/fetchJSON';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import Button from '../../common/components/Button/Button';
import Card from '../../common/components/Card/Card';
import useItem from '../../common/hooks/useItem';
import ConfirmWrap from 'common/components/ConfirmWrap/ConfirmWrap';

const ConfigHolidayForm = ({
  name, holidayTypes, values,
}) => {
  const { t } = useTranslation();

  const periods = [
    { value: 'jandec', label: t('holidays.jandec') },
    { value: 'junemay', label: t('holidays.junemay') },
  ];

  const usedPeriods = [
    { value: 'nn-1', label: t('holidays.nn1') },
    { value: 'n-1', label: t('holidays.n1') },
    { value: 'n', label: t('holidays.n') },
  ];

  const unlimited = [
    { value: 'limited', label: t('common.no') },
    { value: 'unlimited', label: t('common.yes') },
  ];

  const attributions = [
    { value: 'all', label: t('holidays.all') },
    { value: 'endofmonth', label: t('holidays.endofmonth') },
  ];

  return (
    <Card>
      <FormInput
        type="select"
        name={`${name}.holidays_type`}
        label={t('common.holidaysTypes')}
        options={holidayTypes}
        disabled
        required
      />

      <FormInput
        type="buttongroup"
        name={`${name}.config.unlimited`}
        label={t('holidays.unlimited')}
        options={unlimited}
        required
      />
      { values.config.unlimited === 'limited' && (
      <>
        <FormInput
          type="number"
          name={`${name}.config.nb_days`}
          label={t('holidays.nb_days')}
          required
        />

        <FormInput
          type="buttongroup"
          name={`${name}.config.accumulation_period`}
          label={t('holidays.accumulation_period')}
          options={periods}
          required
        />

        <FormInput
          type="buttongroup"
          name={`${name}.config.days_attribution`}
          label={t('holidays.days_attribution')}
          options={attributions}
          required
        />

        <FormInput
          type="buttongroup"
          name={`${name}.config.used_period`}
          label={t('holidays.used_period')}
          options={usedPeriods}
          required
        />
      </>
      )}

    </Card>
  );
};

const ContractTypeForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const [openItemIndex, setopenItemIndex] = useState(false);
  const { id: contractTypeId } = router.match.params;
  const {
    saveItem, updateItem, item, error,
  } = useItem('contract-types', contractTypeId);
  const { user: currentUser } = useAuth();
  const { currentCompany } = useCompanies();

  const [holidaysTypes, setHolidaysTypes] = useState([]);

  useEffect(() => {
    const fetchHolidaysType = async () => {
      try {
        const results = await fetchJSON({
          url: 'holidays-types',
          method: 'GET',
        });
        const options = results.map((type) => ({ value: type.id, label: type.name, type }));

        setHolidaysTypes(options);
      } catch (err) {
        console.log(err);
      }
    };

    fetchHolidaysType();
  }, [contractTypeId]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/contract-types');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async item => {
    if (item.id) {
      await updateItem(item.id, item);
      back();
    } else {
      await saveItem(item);
      back();
    }
  }, [updateItem, back, saveItem]);

  const initialValues = useMemo(() => item?.id ? ({
    ...item,
    name: item?.name,
    company: item?.company?.id,
    holidayTypeConfig:
        item?.holidayTypeConfig?.map((holiday) => ({ ...holiday, holidays_type: holiday?.holidays_type?.id })),
  }) : ({
    company: currentCompany.id,
    holidayTypeConfig: [],
  }), [item, currentCompany]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/contract-types',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <Form
          onSubmit={onSubmit}
          subscription={{ values: true, submitting: true, pristine: true }}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit, submitting, pristine, values,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />
              <div className="columns">
                <div className="column is-full">
                  <Card title={t('user.memberInformations')}>
                    { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                    <FormInput
                      type="custom"
                      name="company"
                      label={t('common.company')}
                    >
                      <CompanySelect />
                    </FormInput>
                    )}
                    <FormInput
                      name="name"
                      label={t('contractType.name')}
                      required
                    />
                    <FormInput
                      name="has_meal_voucher"
                      label={t('contractType.hasMealVoucher')}
                      type="checkbox"
                    />
                  </Card>
                </div>
              </div>
              <FieldArray name="holidayTypeConfig">
                {({ fields }) => (
                  <div className="columns" style={{ flex: 2 }}>
                    <div className="column is-one-thirds">
                      <Card title={t('common.holidays')}>
                        { holidaysTypes?.map(type => {
                          const index = values.holidayTypeConfig.findIndex(config => (
                            config.holidays_type === type.value
                          ));

                          return (
                            <div
                              key={type.value}
                              className="lineHover"
                              style={{ display: 'flex' }}
                            >
                              <ConfirmWrap disabled={index === -1}>
                                <div
                                  style={{ flex: 1 }}
                                  className="checkbox-input"
                                  onClick={() => {
                                    if (index === -1) {
                                      fields.push({ holidays_type: type.value, config: {} });
                                    } else {
                                      if (index === openItemIndex) {
                                        setopenItemIndex(false);
                                      }

                                      fields.remove(index);
                                    }
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    className="is-checkradio"
                                    checked={index !== -1}
                                  />
                                  <label>
                                    {type.label}
                                  </label>
                                </div>
                              </ConfirmWrap>
                              { index !== -1 && (
                                <Button
                                  label={t('common.configure')}
                                  icon="fa-edit"
                                  onClick={() => { setopenItemIndex(index); }}
                                />
                              ) }
                            </div>
                          );
                        })}
                      </Card>
                    </div>
                    <div className="column is-two-thirds">
                      { openItemIndex !== false && (
                      <ConfigHolidayForm
                        key={`item-${openItemIndex}`}
                        index={openItemIndex}
                        values={values.holidayTypeConfig[openItemIndex]}
                        holidayTypes={holidaysTypes}
                        name={`${fields.name}[${openItemIndex}]`}
                      />
                      )}
                    </div>
                  </div>
                )}
              </FieldArray>

              <div className="stickySubmit">
                <FormInput
                  type="submit"
                  label={t('common.send')}
                  icon="paper-plane"
                  disabled={submitting || pristine}
                  isSubmitting={submitting}
                />
              </div>
            </form>
          )}
        />
      </section>
    </>
  );
};

export default ContractTypeForm;
