/* eslint-disable react/prop-types */
import React from 'react';
import Select from '../../common/components/Select/Select';
import { useAuth } from '../../common/contexts/auth';
import { useTranslation } from 'react-i18next';

const RoleSelect = (props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const test = currentUser.role.name === 'admin'
    ? [
      { value: 1, label: t('roles.Authenticated') },
      { value: 5, label: t('roles.manager') },
      { value: 4, label: t('roles.admin') },
    ]
    : (currentUser.role.name === 'manager'
      ? [
        { value: 1, label: t('roles.Authenticated') },
        { value: 5, label: t('roles.manager') },
      ]
      : [
        { value: 1, label: t('roles.Authenticated') },
        { value: 5, label: t('roles.manager') },
        { value: 4, label: t('roles.admin') },
        { value: 3, label: t('roles.superadmin') },
      ]);

  return (
    <Select
      options={test}
      {...props}
    />
  );
};

export default RoleSelect;
