import PropTypes from 'prop-types';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({
  value, onRemoveFile, paddingTop, width,
}) => {
  const {
    name,
    percentage,
    isToRemove,
  } = value;

  const itemStyle = { paddingTop };

  if (isToRemove) return <div style={{ width: 0 }} />;

  return (
    <div className="file-item" title={name} style={{ width }}>
      <div className="file-item-inside" style={itemStyle}>
        <div className="content">
          <span className="icon">
            <i className="fas fa-file-pdf" />
          </span>
          <span className="title">{name.length > 25 ? `${name.substr(0, 24)}...` : name}</span>
        </div>
        {percentage < 100 && (
        <div className="ProgressBar">
          <div className="Progress" style={{ width: `${percentage}%` }} />
        </div>
        )}
        <div className="file-delete">
          <button type="button" className="button is-danger" onClick={onRemoveFile(value)}>
            <span className="icon">
              <i className="fas fa-times" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({
  items, onRemoveFile,
}) => (
  <div className={`file-list ${items.length ? 'has-items' : ''}`}>
    {items.map((value, index) => (
      <SortableItem key={`item-${index}`} index={index} value={value} onRemoveFile={onRemoveFile} />
    ))}
  </div>
));

const FilesList = (props) => <SortableList {...props} />;

FilesList.propTypes = {
  items: PropTypes.array,
  onRemoveFile: PropTypes.func,
};

FilesList.defaultProps = {
  items: [],
  onRemoveFile() { },
};

export default FilesList;
