import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Select from 'common/components/Select/Select';

export const Geolocation = ({
  value,
  onChange,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const getOptions = useCallback((address, callback) => {
    if (address) {
      try {
        const request = {
          query: address,
          fields: ['formatted_address', 'geometry'],
        };

        // eslint-disable-next-line no-undef
        const service = new google.maps.places.PlacesService(document.createElement('div'));

        service.textSearch(request, (results) => {
          if (!results || !results.length) {
            return;
          }

          const list = results.map(({ formatted_address: val, geometry: { location } }) => (
            { label: val, value: val, location }));

          callback(list);
        });
      } catch (e) {
        console.log(e);
        return [];
      }
    } else {
      return [];
    }
  }, []);

  /* On est obligé de passer par le script google car si on essaye de faire une requete fetch sur l'url problème de CORS
   * sur l'url que l'on veut utiliser
   */
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&libraries=places`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const defaultOptions = value ? [{ label: value, value }] : [];

  return (
    <>
      <Select
        placeholder={placeholder || t('geo.search')}
        value={value}
        defaultOptions={defaultOptions}
        cacheOptions
        onChange={(val, row) => onChange(val, row)}
        loadOptions={getOptions}
        {...props}
      />
    </>
  );
};

Geolocation.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

Geolocation.defaultProps = {
  value: '',
  placeholder: null,
  onChange: () => {},
};

export default Geolocation;
