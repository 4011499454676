import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getOpenDays } from 'requests/pages/Summary/summary.utils';
import Card from '../../../components/Card/Card';
import { useAuth } from '../../../contexts/auth';
import useFetch from '../../../hooks/useFetch';

const HomeCra = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  const monthRangeStart = moment(new Date()).startOf('month');
  const monthRangeEnd = moment(new Date()).endOf('month');

  // Get holidays of the user in the month
  const {
    data: userHolidays,
  } = useFetch(
    // eslint-disable-next-line max-len
    `holidays?user=${currentUser.id}&start_date_gt=${encodeURIComponent(monthRangeStart.toISOString())}&end_date_lte=${encodeURIComponent(monthRangeEnd.toISOString())}`,
  );

  const {
    data: activityReports,
  } = useFetch(
    // eslint-disable-next-line max-len
    `activity-reports?user=${currentUser.id}&date_gte=${encodeURIComponent(monthRangeStart.toISOString())}&date_lte=${encodeURIComponent(monthRangeEnd.toISOString())}`,
  );

  const total = useMemo(() => {
    let t = 0;

    if (activityReports) {
      activityReports.forEach((report) => {
        t += report.timeSpent;
      });
    }
    return t;
  }, [activityReports]);

  // Const used to count the number of open days in the CRA card
  const openDays = useMemo(() => {
    const userHolidaysCheck = userHolidays || [];

    return (getOpenDays({
      start_date: monthRangeStart,
      end_date: monthRangeEnd,
    }) - (userHolidaysCheck.length));
  },
  [monthRangeEnd, monthRangeStart, userHolidays]);

  return (
    <>
      {currentUser.time_report === true && (
      <div style={{ flex: 1, padding: '0 0 0 0.75rem ', maxWidth: '50%' }}>
        <Card title="CRA">
          <div className="card-footer">
            <div className="cra-counter">
              <span className="counter">
                <span className="main-number">{total}</span>/{openDays}
              </span>
              <span>{t('dashboard.editedDays')}</span>
            </div>
            <Link className="button is-submit" to="/working-days">
              <i className="fas fa-pen" style={{ marginRight: '0.5rem' }} />
              {t('dashboard.editCra')}
            </Link>
          </div>
        </Card>
      </div>
      )}
    </>
  );
};

export default HomeCra;
