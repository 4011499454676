import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useAuth from 'common/contexts/auth';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import TaskTypeSelect from 'requests/components/TaskTypeSelect';
import useCompanies from 'requests/contexts/companies';

const ProjectForm = ({ onSubmit, project }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { currentCompany } = useCompanies();

  const initialValues = useMemo(() => project?.id ? {
    ...project,
    company: project?.company?.id,
    task_types: project?.task_types?.map((task) => task.id),
  } : {
    name: '',
    description: '',
    daily_billing: false,
    in_progress: true,
    company: currentCompany.id,
    icon: 'pen',
    color: '#aaaaaa',
    task_types: [],
  }, [project, currentCompany]);

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({
        handleSubmit, submitting, pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <div className="columns">
            <div className="column is-two-thirds">
              <Card>
                { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                <FormInput
                  type="custom"
                  name="company"
                  label={t('common.company')}
                >
                  <CompanySelect />
                </FormInput>
                )}
                <FormInput
                  name="name"
                  label={t('common.name')}
                  required
                />
                <FormInput
                  name="description"
                  label={t('common.description')}
                />
                <FormInput
                  name="color"
                  label={t('projects.color')}
                  required
                  type="circle-color"
                />

                <FormInput
                  type="icon"
                  name="icon"
                  label={t('projects.icon')}
                  options={[
                    { label: 'pen', value: 'pen' },
                    { label: 'bookmark', value: 'bookmark' },
                    { label: 'chalkboard-teacher', value: 'chalkboard-teacher' },
                    { label: 'briefcase', value: 'briefcase' },
                    { label: 'eye', value: 'eye' },
                    { label: 'gamepad', value: 'gamepad' },
                    { label: 'laptop', value: 'laptop' },
                    { label: 'shopping-basket', value: 'shopping-basket' },
                    { label: 'star', value: 'star' },
                    { label: 'car', value: 'car' },
                  ]}
                  required
                />
              </Card>
            </div>
            <div className="column is-one-third">

              <Card>
                <FormInput
                  name="daily_billing"
                  type="checkbox"
                  label={t('common.dailyBilling')}
                />
                <FormInput
                  name="days_estimated"
                  type="number"
                  label={t('projects.daysEstimated')}
                />
                <FormInput
                  name="in_progress"
                  type="checkbox"
                  label={t('projects.inProgress')}
                />
              </Card>

              <Card>
                <FormInput
                  type="custom"
                  name="task_types"
                  label={t('common.taskTypes')}
                >
                  <TaskTypeSelect multiple />
                </FormInput>
              </Card>
            </div>

          </div>

          <div className="stickySubmit">
            <FormInput
              type="submit"
              label={t('common.send')}
              icon="paper-plane"
              disabled={submitting || pristine}
              isSubmitting={submitting}
            />
          </div>

        </form>
      )}
    />
  );
};

ProjectForm.propTypes = {
  onSubmit: PropTypes.func,
  project: PropTypes.object,
};

ProjectForm.defaultProps = {
  onSubmit() {},
  project: {},
};

export default ProjectForm;
