import PageHeader from 'common/components/PageHeader/PageHeader';
import DesktopTable from 'common/components/Table/DesktopTable';
import useAuth from 'common/contexts/auth';
import holidays from 'common/utils/holidays';
import moment from 'common/utils/moment';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState,
  forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import SiteSelect from 'requests/components/SiteSelect';
import TeamSelect from 'requests/components/TeamSelect';
import Loader from '../../../common/components/Loader/Loader';
import useFetch from '../../../common/hooks/useFetch';
import useList from '../../../common/hooks/useList';
import DatePicker from '../../../common/components/DatePicker/DatePicker';

const HolidaysPlanner = () => {
  const { t } = useTranslation();
  const { isAdmin, isManager } = useAuth();
  const [date, setDate] = useState(new Date());
  const {
    items: users,
    filters: userFilters,
    setFilter: setUserFilter,
    setItemsPerPage: setUsersPerPage,
    pageIndex,
    setPageIndex,
    pageCount,
  } = useList('users', { defaultFilters: { active: true }, defaultSort: 'username:ASC', cachePrefix: 'plannerusers' });
  const {
    items,
    isFetching: dataIsFetching,
    setFilters: setHolidayFilters,
    setItemsPerPage,
  } = useList('holidays', {
    defaultLimit: -1,
    defaultFilters: {
      'holidays_status.name_ne': 'rejected',
      start_date_lte: moment(date)
        .endOf('month')
        .toISOString(),
      end_date_gte: moment(date)
        .startOf('month')
        .toISOString(),
    },
    defaultSort: 'created_at:DESC',
    cachePrefix: 'plannerholidays',
  });

  const DateButton = forwardRef(({ onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      style={{
        textAlign: 'middle',
      }}
    >
      {t('activityReports.month', {
        month: days[0].format('MMMM YYYY'),
      })}
    </div>
  ));

  const {
    data: holidaysTypesItem,
  } = useFetch('holidays-types');

  const increaseDate = useCallback(() => {
    setDate((date) => moment(date).add(1, 'month').toDate());
  }, [setDate]);

  const decreaseDate = useCallback(() => {
    setDate((date) => moment(date).subtract(1, 'month').toDate());
  }, [setDate]);

  const days = useMemo(() => Array.from(
    moment.range(moment(date).startOf('month'), moment(date).endOf('month')).by('days'),
  ), [date]);

  useEffect(() => {
    const endMonth = moment(date).endOf('month').toISOString();
    const startMonth = moment(date).startOf('month').toISOString();

    setHolidayFilters({
      'holidays_status.name_ne': 'rejected',
      start_date_lte: endMonth,
      end_date_gte: startMonth,
    });
  }, [date, setHolidayFilters]);

  const getDateToday = () => {
    const today = new Date();
    const date = moment(today).format('YYMMDD');

    return date;
  };

  const handleTeamChange = (team) => {
    setUserFilter('teams_contains', team);
  };

  const handleSiteChange = (site) => {
    setUserFilter('site', site);
  };

  const data = useMemo(() => {
    if (users && (users.length === 0 || dataIsFetching)) {
      return [];
    }
    const results = [];
    const dayInfos = [];

    /* On initialise le tableau par 0 */
    days.forEach((day) => {
      dayInfos[`day_${Number(moment(day).format('D'))}`] = 0;
    });

    users && users.forEach(user => {
      if (user.active && !user.blocked) {
        results.push({ username: user.username });
      }
    });

    /* On parse chaque demande de congés pour colorier le tableau */
    if (items) {
      items.forEach(item => {
        const user = results.find(({ username }) => username === item.user.username);

        if (user) {
          /* on parse toutes les demandes d'un utilisateur */
          const startDate = moment(item.start_date);
          const endDate = moment(item.end_date);

          /* Liste des jours de congés dans la demande */
          const offDays = Array.from(
            moment.range(startDate, endDate).by('days'),
          );

          offDays.forEach((day) => {
            let dateType = 'fullday';

            if (day.isSame(startDate)) {
              if (day.isSame(endDate)) {
                dateType = item.end_date_type === 'morning'
                  ? 'morning'
                  : item.start_date_type === 'fullday' ? 'fullday' : 'afternoon';
              } else {
                dateType = item.start_date_type;
              }
            } else if (day.isSame(endDate)) {
              dateType = item.end_date_type;
            }

            /** Si on a déjà des vacances pour ce jour c'est que l'on a JNT + congés par exemple */
            if (user[`day_${Number(day.format('YYMMDD'))}`]) {
              dateType = 'fullday';
            }

            user[`day_${Number(day.format('YYMMDD'))}`] = {
              holidayType: item?.holidays_type ? item?.holidays_type.name : 'JNT',
              holidayIcon: item?.holidays_type ? item?.holidays_type?.icon : 'plane-departure',
              holidayColor: item?.holidays_type ? item?.holidays_type?.color : '#64c8ad',
              holidayStatus: item?.holidays_status?.name,
              dateType,
              description: item?.description,
            };
          });
        }
      });
    }

    return results;
  }, [users, dataIsFetching, days, items]);

  const columns = useMemo(() => {
    const days = Array.from(
      moment.range(moment(date).startOf('month'), moment(date).endOf('month')).by('days'),
    );

    const holidayCells = days.map((day) => {
      const dayCell = Number(day.format('YYMMDD'));
      const dayNumber = `${day.format('ddd')} ${Number(day.format('D'))}`;
      const dayOfWeek = day.day();

      return ({
        Header: () => (
          <div
            className={getDateToday() === day.format('YYMMDD') ? 'dateTh dateTh-isToday' : 'dateTh'}
            style={{
              color: dayOfWeek === 6 || dayOfWeek === 0
          || holidays.isHoliday(day) ? '#a5adc4' : '#ffffff',
            }}
          >
            <span className="day-word">
              {day.format('ddd')}
            </span>
            <span className="day-number">
              {Number(day.format('D'))}
            </span>
          </div>
        ),
        accessor: dayNumber,
        Cell: ({ row: { original } }) => {
          if (dayOfWeek === 6 || dayOfWeek === 0 || holidays.isHoliday(day)) {
            return <div className="normal-square" style={{ backgroundColor: '#a5adc4' }} />;
          }

          if (original[`day_${dayCell}`]) {
            let color = '#64c8ad';

            let icon = 'plane-departure';

            if (isAdmin || isManager) {
              color = original[`day_${dayCell}`].holidayColor;
              icon = original[`day_${dayCell}`].holidayIcon;
            }

            let position = {};

            if (original[`day_${dayCell}`].dateType === 'afternoon') {
              position = { left: '50%' };
            } else if (original[`day_${dayCell}`].dateType === 'morning') {
              position = { left: '-50%' };
            }

            return (
              (isAdmin || isManager) ? (
                <div
                  style={{ width: '100%', height: '100%' }}
                  data-tooltip-top={original[`day_${dayCell}`].holidayType}
                >
                  <div
                    className="normal-square"
                    style={{
                      width: '100%', height: '100%', overflow: 'hidden',
                    }}
                  >
                    <div
                      className="tooltip-square"
                      style={original[`day_${dayCell}`].holidayStatus === 'pending'
                        ? { backgroundColor: color, ...position, opacity: '0.5' }
                        : { backgroundColor: color, ...position }}
                    >
                      <i className={`fas fa-${icon}`} />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="normal-square"
                  style={{ width: '100%', height: '100%', overflow: 'hidden' }}
                >
                  <div className="normal-square" style={{ backgroundColor: color, ...position }}>
                    <i className={`fas fa-${icon}`} />
                  </div>
                </div>
              )
            );
          }
          return null;
        },
      });
    });

    return [{
      Header: t('common.name'),
      filterName: 'username_contains',
      Cell: ({ row: { original: { username } } }) => (
        <span>{username}</span>
      ),
    },
    ...holidayCells,
    ];
  }, [t, date, isAdmin, isManager]);

  useEffect(() => {
    setUsersPerPage(10);
    setItemsPerPage(-1);
  });

  return (
    <>
      <PageHeader title={t('common.holidaysPlanner')} />
      <section className="section list-page">
        <div className="divUpperInfosContainer">
          <p className="legend"><i className="we fas fa-ban" /> {t('common.weekend')}</p>
          <p className="legend"><i className="wp fas fa-plane-departure" /> {t('common.dayOff')}</p>
          {(isAdmin || isManager) && holidaysTypesItem && holidaysTypesItem.map(({ name, color, icon }) => (
            <p key={name} className="legend">
              <i className={`fas fa-${icon}`} style={{ color }} />
              {name}
            </p>
          ))}
        </div>

        <div className="head-filter-box">
          <div className="head-filter-item">
            <TeamSelect isFilter value={userFilters?.teams_contains} onChange={handleTeamChange} />
          </div>

          <div className="head-filter-item">
            <SiteSelect isFilter value={userFilters?.site} onChange={handleSiteChange} />
          </div>

          <div className="head-filter-item">
            <div className="dateGhostSelector">
              <i className="fas fa-angle-left" onClick={decreaseDate} />
              <div className="dateGhostSelector-element">
                <DatePicker
                  className="react-date-picker"
                  minDetail="month"
                  maxDetail="year"
                  value={date}
                  onChange={setDate}
                  clearIcon={null}
                  customInput={<DateButton />}
                  selectedTimeUnit="month"
                />
              </div>
              <i className="fas fa-angle-right" onClick={increaseDate} />
            </div>
          </div>
        </div>

        {dataIsFetching ? (
          <Loader isScreen processing={dataIsFetching} />
        ) : (
          <div className="custom-cell holidays-planner-cell">
            <DesktopTable
              data={data ?? []}
              columns={columns}
              isFetching={dataIsFetching}
              pageCount={pageCount}
              filters={userFilters}
              setFilter={setUserFilter}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
            />
          </div>
        )}
      </section>
    </>
  );
};

export default HolidaysPlanner;
