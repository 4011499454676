import ContextProvider from 'common/contexts/crud';
import useRouter from 'common/hooks/use-router';
import PropTypes from 'prop-types';
import React, {
  // eslint-disable-next-line comma-dangle
  createContext, useCallback, useContext, useMemo
} from 'react';
import createPersistedState from 'use-persisted-state';

const SitesContext = createContext();
const usePersistedState = createPersistedState('currentSite');

export const SitesProvider = ({ children }, props) => {
  const [currentSite, setCurrentSite] = usePersistedState(null);
  const router = useRouter();

  const selectCurrentSite = useCallback((site) => {
    setCurrentSite(site);
    router.push('/');
  }, [setCurrentSite, router]);

  const value = useMemo(() => ({
    currentSite,
    selectCurrentSite,
    setCurrentSite,
  }), [
    currentSite,
    selectCurrentSite,
    setCurrentSite,
  ]);

  return (
    <ContextProvider
      url="sites"
      value={value}
      context={SitesContext}
      {...props}
    >
      {children}
    </ContextProvider>
  );
};

SitesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const useSites = () => useContext(SitesContext);

export default useSites;
