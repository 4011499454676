import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserForm from 'users/pages/UserFormPage/UserForm';
import useItem from '../../../common/hooks/useItem';

const UserFormPage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { id: userId } = router.match.params;
  const { user: currentUser, isAdmin, isManager } = useAuth();
  const {
    saveItem, updateItem, item: user,
  } = useItem('users', userId);

  const onSubmit = useCallback(async (user) => {
    const redirection = (isAdmin || isManager) ? '/users' : `/users/profile/${user.id}`;

    if (user.id) {
      const res = await updateItem(user.id, user);

      if (!res.error) {
        setAlert(t('users.successEdit'), 'success');
        router.push(redirection);
      } else {
        setAlert(res.message, 'danger');
      }
    } else {
      const res = await saveItem(user);

      if (!res.error) {
        setAlert(t('users.successAdd'), 'success');
        router.push(redirection);
      } else {
        setAlert(res.message, 'danger');
      }
    }
  }, [router, updateItem, saveItem, setAlert, t, isAdmin, isManager]);

  return (
    <>
      <PageHeader
        title={user && user.id
          ? (user.id === currentUser.id
            ? t('user.editMyself') : `${t('user.editUser')} ${user.username}`)
          : t('users.addUser')}
        subLink={{
          url: '/users',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
        link={user && user.id ? { url: `/users/profile/${user.id}`, icon: 'eye', label: t('user.seeUser') } : null}
      />
      <section className="section form-page has-cards">
        <UserForm
          onSubmit={onSubmit}
          user={user}
        />
      </section>
    </>
  );
};

export default UserFormPage;
