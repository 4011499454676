import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Article from '../../../common/components/Article/Article';
import Button from '../../../common/components/Button/Button';
import Loader from '../../../common/components/Loader/Loader';
import PageHeader from '../../../common/components/PageHeader/PageHeader';
import { useAuth } from '../../../common/contexts/auth';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const Wiki = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAdmin } = useAuth();
  const location = window.location.pathname;

  const publishFilters = isAdmin ? {} : { published: true };

  const {
    items: articles,
    fetchItems: fetchArticles,
    isFetching: isArticleFetching,
    pageCount,
    removeListItem,
  } = useList('article', location === '/blog'
    ? {
      defaultFilters: { isWiki: false, ...publishFilters },
      defaultSort: 'publishedat:DESC',
      cachePrefix: 'wikiarticle',
    }
    : {
      defaultFilters: { isWiki: true, ...publishFilters },
      defaultSort: 'title:ASC',
      cachePrefix: 'wikiarticle',
    });
  const {
    removeItem,
  } = useItem('article');

  const {
    items: categories,
    fetchItems: fetchCategories,
    isFetching: isCategoryFetching,
    removeListItem: removeCategoryList,
  } = useList('category', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'wikicategory' });
  const {
    removeItem: removeCategory,
  } = useItem('category');

  const [isClicked, setIsClicked] = useState(false);
  const [displayArticle, setDisplayArticle] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canNextPage, setCanNextPage] = useState(false);
  const [maxPages, setMaxPages] = useState(1);

  useEffect(() => {
    if (!isArticleFetching) {
      if (articles) {
        const articleList = isAdmin ? articles : articles.filter((article) => article.published);

        if (location === '/wiki' && articleList) {
          let recent = 0;

          for (let i = 0; i < articleList.length - 1; i++) {
            const d1 = new Date(articleList[recent].updated_at);
            const d2 = new Date(articleList[i + 1].updated_at);

            if (d1 < d2) {
              recent = i + 1;
            }
          }
          showArticle(articleList[recent]);
        }

        if (articleList) {
          const max = articleList.length / 5;

          setMaxPages(Math.ceil(max));
          if (max > 1) {
            setCanNextPage(true);
          }
        }
      }
    }
  }, [isArticleFetching, articles, location, isAdmin]);

  const showArticle = (article) => {
    setIsClicked(true);
    setDisplayArticle(article);
  };

  const handleDeleteCategory = useCallback(async (id) => {
    removeCategoryList(id);
    await removeCategory(id);
    fetchCategories();
  }, [fetchCategories, removeCategory, removeCategoryList]);

  const handleDeleteArticle = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    fetchArticles();
  }, [removeListItem, fetchArticles, removeItem]);

  const previousPage = useCallback(() => {
    if (pageIndex > 0) {
      const newIndex = pageIndex - 1;

      setPageIndex(newIndex);
      setCanNextPage(true);
      if (newIndex === 0) {
        setCanPreviousPage(false);
      }
    } else {
      setCanPreviousPage(false);
    }
  }, [pageIndex]);

  const nextPage = useCallback(() => {
    if (pageIndex + 1 < maxPages) {
      const newIndex = pageIndex + 1;

      setPageIndex(newIndex);
      setCanPreviousPage(true);
      if (newIndex + 1 === maxPages) {
        setCanNextPage(false);
      }
    } else {
      setCanNextPage(false);
    }
  }, [maxPages, pageIndex]);

  const goToPage = (i) => {
    setPageIndex(i);
  };

  const blogArticles = useCallback(() => {
    if (articles !== undefined && !isArticleFetching) {
      const start = pageIndex * 5;
      const end = ((pageIndex * 5) + 5) > articles?.length ? articles?.length : ((pageIndex * 5) + 5);

      const tab = [];

      for (let i = start; i < end; i++) {
        if (articles && !articles[i]?.isWiki && !isClicked) {
          const article = articles[i];

          // onClick={() => history.push(`/blog/article/${article?.id}`)}
          tab.push(
            <div className="blog-page-item" key={i}>
              <Article
                key={article?.id}
                isWiki={article?.isWiki}
                isBlog
                isPublished={article?.published}
                title={article?.title}
                content={article?.content}
                contributor={(article?.published_by?.username)
                  ? `${article?.published_by.username}` : t('blog.unknown')}
                date={article?.publishedat}
                image={article?.image}
                isClicked
                handleDelete={() => handleDeleteArticle(article.id)}
                handleModify={() => history.push(`/blog/edit/${article.id}`)}
              />
            </div>,
          );
        }
      }
      return tab;
    }
  }, [articles, history, isArticleFetching, isClicked, handleDeleteArticle, pageIndex, t]);

  return (
    <>
      {location === '/wiki' ? (
        <>
          {isAdmin ? (
            <PageHeader
              title={t('blog.wiki')}
              link={{
                url: '/wiki/add',
                icon: 'plus',
                label: t('common.add'),
              }}
            />
          ) : (
            <PageHeader
              title={t('blog.wiki')}
            />
          )}
          {(isArticleFetching && isCategoryFetching)
            ? (
              <Loader isScreen processing={(isArticleFetching && isCategoryFetching)} />
            )
            : (
              <div className="wiki-page">
                <aside>
                  <div className="sidebar sidebar-container" style={{ paddingBottom: isAdmin ? 0 : '1rem' }}>
                    {categories && categories.map((category, key) => (
                      <>
                        <div className="sidebar-category">
                          <p className="menu-label">
                            <span ket={key}>{category.name}</span>
                          </p>
                          {isAdmin && (
                            <div className="blog-actions">
                              <Button
                                onClick={() => history.push(`/wiki/edit/category/${category.id}`)}
                                icon="fa-pencil-alt"
                                color="primary"
                                transparent
                              />
                              <Button
                                onClick={() => handleDeleteCategory(category.id)}
                                icon="fa-trash-alt"
                                color="danger"
                                transparent
                                confirmMessage={t('common.delete')}
                              />
                            </div>
                          )}
                        </div>

                        <ul className="menu-list">
                          {/* eslint-disable-next-line array-callback-return */}
                          {articles?.map((article) => {
                            if (article.category && (article.category.name === category.name)) {
                              return (
                                <li onClick={() => showArticle(article)}>
                                  {article.published === false && isAdmin && (
                                    <span
                                      className="clickable clickable_not-publish"
                                      key={article.id}
                                    >
                                      <i className="fas fa-ban" style={{ opacity: 0.4 }} />
                                      &nbsp;
                                      {article.title}
                                    </span>
                                  )}
                                  {article.published && (
                                    <span
                                      className="clickable"
                                      key={article.id}
                                    >
                                      {article.title}
                                    </span>
                                  )}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </>
                    ))}
                    {isAdmin && (
                    <div className="sublink">
                      <Link to="/wiki/addCategory">
                        <span className="icon">
                          <i className="fas fa-plus" />
                        </span>
                        <span>{t('blog.newCategory')}</span>
                      </Link>
                    </div>
                    )}
                  </div>
                </aside>
                <div className="wiki-container">
                  {isClicked && displayArticle && (
                    <div className="wiki-page-item">
                      <Article
                        isWiki={displayArticle.isWiki}
                        title={displayArticle.title}
                        content={displayArticle.content}
                        image={displayArticle.image}
                        isClicked
                        handleDelete={() => handleDeleteArticle(displayArticle.id)}
                        handleModify={() => history.push(`/wiki/edit/article/${displayArticle.id}`)}
                        isPublished={displayArticle.published}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
        </>
      ) : (
        <>
          {!isClicked && (
          <>
            {isAdmin ? (
              <PageHeader
                title={t('blog.headingTitle')}
                link={{
                  url: '/blog/add',
                  icon: 'plus',
                  label: t('common.add'),
                }}
              />
            ) : (
              <PageHeader
                title={t('blog.headingTitle')}
              />
            )}
            {isArticleFetching ? (
              <Loader isScreen processing={isArticleFetching} />
            ) : (
              <div className="blog-page">

                <div className="blog-scroller">
                  {blogArticles()}

                  {pageCount === 1 && (
                    <>
                      <div className="blog-spacer" />
                      <div className="paginator-box">
                        <nav className="pagination">
                          <ul className="pagination-nav">
                            <li>
                              <button
                                className="pagination-previous"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                              >
                                <span className="icon">
                                  <i className="fas fa-angle-double-left" />
                                </span>
                              </button>
                            </li>
                          </ul>
                          <ul className="pagination-list">
                            {Array.from({ length: maxPages }, (page, i) => (
                              <li key={`page-${i}`}>
                                <button
                                  className={`pagination-link ${
                                    pageIndex === i && 'is-current'
                                  }`}
                                  onClick={() => goToPage(i)}
                                >
                                  <span>{i + 1}</span>
                                </button>
                              </li>
                            ))}
                          </ul>
                          <ul className="pagination-nav">
                            <li>
                              <button
                                className="pagination-next"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                              >
                                <span className="icon">
                                  <i className="fas fa-angle-double-right" />
                                </span>
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </>
                  )}

                </div>
              </div>
            )}
          </>
          )}
        </>
      )}
    </>
  );
};

export default Wiki;
