import React from 'react';
import { useTranslation } from 'react-i18next';
import useCompanies from 'requests/contexts/companies';
import MenuLink from '../MenuLink';

const AdminMenu = () => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();

  return (
    <ul className="menu-list">
      <li>
        <MenuLink to="/users" className="admin-menu-item">
          <span className="icon">
            <i className="fas fa-users" />
          </span>
          <span>{t('common.users')}</span>
        </MenuLink>

        {currentCompany?.id ? (
          <MenuLink to="/configure" className="admin-menu-item">
            <span className="icon">
              <i className="fas fa-cog" />
            </span>
            <span>{t('common.configure')}</span>
          </MenuLink>
     ) : (
       <MenuLink to="/companies" className="admin-menu-item">
         <span className="icon">
           <i className="fas fa-building" />
         </span>
         <span>{t('common.companies')}</span>
       </MenuLink>
     )}
      </li>
    </ul>
  );
};

export default AdminMenu;
