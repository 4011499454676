import iconEmployee from 'assets/icon-employee.png';
import iconTeam from 'assets/icon-team.png';
import iconTie from 'assets/icon-tie.png';
import useAuth from 'common/contexts/auth';
import { useTranslation } from 'react-i18next';

const useRoles = () => {
  const { user: currentUser } = useAuth();
  const { t } = useTranslation();

  // manager (basic)
  const roleContents = [
    {
      description: t('roles.AuthenticatedDesc'),
      iconType: 'img',
      iconUrl: iconTeam,
    },
    {
      description: t('roles.ManagerDesc'),
      iconType: 'img',
      iconUrl: iconEmployee,
    },
  ];

  const roleOptions = [
    { value: 1, label: t('roles.Authenticated') },
    { value: 5, label: t('roles.manager') },
  ];

  // admin
  if (currentUser.role.name === 'admin') {
    roleContents.push({
      description: t('roles.AdminDesc'),
      iconType: 'img',
      iconUrl: iconTeam,
    });

    roleOptions.push({ value: 4, label: 'Admin' });
  }

  // super admin
  if (currentUser.role.name === 'superadmin') {
    roleContents.push({
      description: t('roles.AdminDesc'),
      iconType: 'img',
      iconUrl: iconTie,
    },
    {
      description: t('roles.superadminDesc'),
      iconType: 'img',
      iconUrl: iconTie,
    });

    roleOptions.push({ value: 4, label: 'Admin' }, { value: 3, label: t('roles.superadmin') });
  }

  return { roleOptions, roleContents };
};

export default useRoles;
