/* eslint-disable react/prop-types */
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';

const processOptions = (result) => result.map((team) => ({ value: team.id, label: team.name, team }));

// TaskTypeSelect
const TaskTypeSelect = (props) => {
  const { t } = useTranslation();

  return (
    <RemoteSelect
      url="task-types"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.taskTypes') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default TaskTypeSelect;
