/* eslint-disable camelcase */
import holidaysUtils from 'common/utils/holidays';
import moment from 'common/utils/moment';

export const isValidDate = date => moment(date).isoWeekday() < 6 && !holidaysUtils.isHoliday(moment(date));

export const getOpenDays = ({ start_date, end_date }) => {
  const range = moment.range(start_date, end_date);
  const days = Array.from(range.by('days'));
  const holidays = days.filter(d => holidaysUtils.isHoliday(d));

  const calcDays = moment().isoWeekdayCalc({
    rangeStart: start_date,
    rangeEnd: end_date,
    weekdays: [1, 2, 3, 4, 5],
    exclusions: holidays,
  });

  return calcDays;
};

export const computeDays = ({
  start_date,
  end_date,
  start_date_type,
  end_date_type,
}) => {
  let weekdays = getOpenDays({ start_date, end_date });

  if (start_date_type !== 'fullday' && isValidDate(start_date)) {
    weekdays -= 0.5;
  }

  if (end_date_type !== 'fullday' && isValidDate(end_date)) {
    weekdays -= 0.5;
  }

  return weekdays;
};

const getHolidays = (holidays, date) => {
  const accepted = holidays?.filter(h => h.holidays_status.name === 'agreed') ?? [];

  return accepted.map(h => {
    const firstDayOfMonth = moment(date)
      .startOf('month')
      .startOf('day');
    const lastDayOfMonth = moment(date)
      .endOf('month')
      .startOf('day');

    if (moment(h.start_date).startOf('month').startOf('day').isBefore(firstDayOfMonth)) {
      h.start_date = firstDayOfMonth;
      h.start_date_type = 'fullday';
    }

    if (moment(h.end_date).startOf('month').startOf('day').isAfter(lastDayOfMonth)) {
      h.end_date = lastDayOfMonth;
      h.end_date_type = 'fullday';
    }

    const total = computeDays({
      start_date: moment(h.start_date),
      end_date: moment(h.end_date),
      start_date_type: h.start_date_type,
      end_date_type: h.end_date_type,
    });

    return {
      ...h,
      computedDays: total,
    };
  });
};

const getActivityReports = (activityReports) => {
  const projects = [];

  activityReports.forEach(ar => {
    const prj = projects.find(p => p.user === ar.user.username && p.name === ar.project.name);

    if (prj) {
      prj.timeSpent += ar.timeSpent;
    } else {
      projects.push({
        user: ar.user.username,
        name: ar.project.name,
        dailyBill: ar.project.daily_billing,
        timeSpent: ar.timeSpent,
      });
    }
  });

  return projects;
};

const getTotal = (activityReports, holidays, user) => {
  let totalAR = 0;

  let totalHolidays = 0;

  activityReports.forEach(ar => {
    if (ar.user === user.username) totalAR += ar.timeSpent;
  });

  holidays.forEach(h => {
    if (h.user.username === user.username) totalHolidays += h.computedDays;
  });

  return totalAR + totalHolidays;
};

const getItems = (users, holidays, activityReports, date) => {
  const formatedHolidays = getHolidays(holidays, date);
  const formatedAR = getActivityReports(activityReports);

  const items = users.map(user => ({
    username: user.username,
    avatar: user.avatar,
    projects: formatedAR.filter(ar => ar.user === user.username),
    holidays: formatedHolidays.filter(h => h.user.username === user.username),
    total: getTotal(formatedAR, formatedHolidays, user),
  }));

  return items;
};

export default getItems;
