import ContextProvider from 'common/contexts/crud';
import React, { createContext, useContext } from 'react';

const TodoTemplatesContext = createContext();
const useTodoTemplates = () => useContext(TodoTemplatesContext);

// eslint-disable-next-line max-len
export const TodoTemplatesProvider = (props) => <ContextProvider url="todo-templates" context={TodoTemplatesContext} {...props} />;

export default useTodoTemplates;
