import Card from 'common/components/Card/Card';
import PageHeader from 'common/components/PageHeader/PageHeader';
import useAuth from 'common/contexts/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <PageHeader
        title={t('common.profile')}
        link={{ url: `/users/edit/${user.id}`, icon: 'pencil-alt', label: t('user.editUser') }}
        subLink={{
          url: '/users', icon: 'arrow-left', goBack: true, label: t('common.back'),
        }}
      />
      <section className="section profile-page">
        <div style={{ display: 'flex', width: '100%' }}>
          <div className="profile-left">
            <Card>
              <h2 className="title is-2">{user.username}</h2>
              <div className="profile-section">
                <p>
                  <strong>{t('user.phone')} :</strong> {user.phone || t('common.undefined')}
                </p>
                <p>
                  <strong>{t('common.email')} :</strong> {user.email || t('common.undefined')}
                </p>
                {
                  (user.streetAndNumber || user.postalCode || user.city) && (
                    <p>
                      <strong>{t('common.address')} :</strong> {user.streetAndNumber} {user.postalCode} {user.city}
                    </p>
                  )
                }

              </div>

              <h2 className="title is-2">{t('user.complementaryInformations')}</h2>
              <div className="profile-section">
                <p>
                  <strong>{t('user.trustedPersonName')} : </strong> {user.trustedPersonName || t('common.undefined')}
                </p>
                <p>
                  <strong>{t('user.trustedPersonPhone')} :</strong> {user.trustedPersonNumber || t('common.undefined')}
                </p>
                <p>
                  <strong> {t('user.children')} :</strong> {user.children || t('common.undefined')}
                </p>
              </div>

              <h2 className="title is-2">{t('user.diplomasFiles')}</h2>

              <div className="profile-section">
                <p>{user.diplomasFiles?.length}</p>
              </div>

              <h2 className="title is-2">{t('user.contractsFiles')}</h2>
              <div className="profile-section">
                <p>{user.contractsFiles?.length}</p>
              </div>
            </Card>
          </div>
          <div className="profile-right">
            <Card title={t('holidays.holidaysSolde')} />
            <Card title={t('holidays.holidaysHistoric')} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
