import Holidays from 'date-holidays';

const holidays = new Holidays('FR');

const hdays = {};

/** Cette fonction est plus rapide que la fonction de base date-holidays */
const isHoliday = (day) => {
  const year = day.year();

  if (!hdays[year]) {
    hdays[year] = holidays.getHolidays(year).map(({ start }) => start.toISOString());
  }

  return hdays[year].includes(day.toISOString());
};

export default {
  isHoliday,
};
