import Modal from 'common/components/Modal/Modal';
import useAuth from 'common/contexts/auth';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import useCompanies from 'requests/contexts/companies';

const WelcomeModal = ({
  closeModal,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { currentCompany } = useCompanies();

  return (
    <Modal closeModal={closeModal} className="welcome-modal">
      <div>
        <img src="assets/welcome-picture.png" alt="Welcome person" className="welcome-picture" />
        <div className="welcome-top">
          <h1><b>{t('common.welcome')},</b>
            <br />{user.name}, {t('common.from')} {currentCompany.name}
          </h1>
        </div>

        <div className="welcome-bottom">
          <p>
            {t('subscription.freePeriodLegendBefore')}
            &nbsp;<b>7 {t('common.days')}</b>.
            <br />
            {t('subscription.freePeriodLegendAfter')}
          </p>

        </div>

        <div className="card-footer">
          <button className="button is-gradient" onClick={() => closeModal()}>
            {t('common.continue')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

WelcomeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default WelcomeModal;
