import Button from 'common/components/Button/Button';
import { Input } from 'common/components/FormInput/FormInput';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import JobSelect from 'requests/components/JobSelect';
import SiteSelect from 'requests/components/SiteSelect';
import TeamSelect from 'requests/components/TeamSelect';
import useCompanies from 'requests/contexts/companies';
import RoleSelect from 'users/components/RoleSelect';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const UsersList = () => {
  const { t } = useTranslation();
  const { push } = useRouter();
  const {
    fetchItems: fetchUsers,
    items: users,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
  } = useList('users', { defaultFilters: { active: 1 }, defaultSort: 'username:ASC', cachePrefix: 'users' });
  const {
    updateItem,
  } = useItem('users');

  const handleChangeUserStatus = useCallback(async (row) => {
    await updateItem(row.original.id, { ...row.original, active: !row.original.active });
    fetchUsers();
  }, [updateItem, fetchUsers]);

  const handleEdit = useCallback((row) => push(`/users/edit/${row.original.id}`), [push]);

  const handleDetail = useCallback((row) => push(`/users/profile/${row.original.id}`), [push]);

  const { currentCompany } = useCompanies();

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      accessor: 'username',
      filterName: 'username_contains',
      className: 'table-start',
      Cell: ({ row: { original: { id, username, avatar } } }) => (
        <>
          <div
            className="user-avatar"
            style={{
              backgroundImage: avatar
                ? `url(${process.env.REACT_APP_API_URL}${avatar.url})` : 'none',
            }}
            onClick={() => push(`/users/profile/${id}`)}
          >
            {!avatar && (
            <span>{username[0]}</span>
            )}
          </div>
          <span className="clickable" onClick={() => push(`/users/profile/${id}`)}>{username}</span>
        </>
      ),
    },
    {
      Header: t('common.job'),
      accessor: 'job',
      filterName: 'job_contains',
      filterComponent: (props) => <JobSelect isFilter {...props} />,
      Cell: ({ row: { original: { id, job } } }) => (
        <span className="clickable" onClick={() => push(`/users/profile/${id}`)}>{job?.name}</span>
      ),
    },
    {
      Header: t('common.company'),
      accessor: 'company',
      filterName: 'company_contains',
      hidden: currentCompany,
      filterComponent: (props) => (
        <CompanySelect
          isFilter
          {...props}
        />
      ),
      Cell: ({ row: { original: { id, company } } }) => (
        <span className="clickable" onClick={() => push(`/users/profile/${id}`)}>{company?.name}
        </span>
      ),
    },
    {
      Header: t('common.site'),
      filterName: 'site',
      filterComponent: (props) => <SiteSelect isFilter {...props} />,
      Cell: ({ row: { original: { id, site } } }) => (
        <span className="clickable" onClick={() => push(`/users/profile/${id}`)}>{site?.name}
        </span>
      ),
    },
    {
      Header: t('common.teams'),
      filterName: 'teams',
      filterComponent: (props) => <TeamSelect isFilter {...props} />,
      Cell: ({ row: { original: { teams } } }) => (
        <div>
          { teams.length > 0 ? teams.map((team, key) => (
            <div className="status-tag pending" key={`team-${key}`}>{team.name}</div>
          )) : (
            <span>{t('common.undefined')}</span>
          )}
        </div>
      ),
    },
    {
      Header: t('users.role'),
      filterName: 'role',
      filterComponent: (props) => <RoleSelect isFilter {...props} />,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original: { role: { name } } } }) => (
        <span>
          {t(`roles.${name}`)}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => (
        <>
          <Button
            onClick={() => handleDetail(row)}
            icon="fa-user"
            color="primary"
            transparent
          />

          <Button
            onClick={() => handleEdit(row)}
            icon="fa-pencil-alt"
            color="primary"
            transparent
          />
          <Button
            onClick={() => handleChangeUserStatus(row)}
            icon={row.original.active ? 'fas fa-check-circle' : 'dar fa-times-circle'}
            color={row.original.active ? 'success' : 'danger'}
            confirm
            transparent
            confirmMessage={row.original.active ? t('users.confirmDisable') : t('users.confirmUndisable')}
          />
        </>
      ),
    },
  ], [t, handleEdit, handleDetail, handleChangeUserStatus, push, currentCompany]);

  return (
    <>
      <PageHeader
        title={t('common.users')}
        link={{ url: '/users/add', icon: 'user-plus', label: t('users.addUser') }}
      />
      <section className="section list-page list-action">
        <div className="head-filter-box" style={{ justifyContent: 'flex-end', color: 'white' }}>
          <div className="head-filter-item">
            <Input
              type="checkbox"
              label={t('users.displayunactive')}
              value={!filters.active}
              onChange={(val) => { console.log({ val }); setFilter('active', !val); }}
            />
          </div>
        </div>
        <Table
          data={users ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default UsersList;
