import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';

const Modal = ({
  message, confirmModal, closeModal,
}) => {
  const { t } = useTranslation();

  const handleEscape = useCallback((e) => {
    e.keyCode === 27 && closeModal();
  }, [closeModal]);

  useEffect(() => {
    const keyup = document.addEventListener('keyup', handleEscape);

    return () => document.removeEventListener('keyup', keyup);
  }, [handleEscape]);

  return ReactDOM.createPortal(
    <div
      className="modal is-active is-clipped"
    >
      <div className="modal-background" onClick={closeModal} />
      <Card>
        <div className="card-custom-header">
          <h2 className="title is-2">{t('modal.confirmTitle')}</h2>
          <button onClick={closeModal} className="modal-close is-large" aria-label="close" />
        </div>
        <p className="confirm-message">{message}</p>
        <div className="card-footer">
          <button onClick={closeModal} data-cy="cancel" className="button is-light">
            <span className="icon">
              <i className="fas fa-times" />
            </span>
            <span>{t('modal.cancel')}</span>
          </button>
          <button onClick={confirmModal} data-cy="confirm" className="button is-gradient">
            <span className="icon">
              <i className="fas fa-check" />
            </span>
            <span>{t('modal.confirm')}</span>
          </button>
        </div>
      </Card>
    </div>,
    document.getElementById('portal-root'),
  );
};

Modal.propTypes = {
  message: PropTypes.string.isRequired,
  confirmModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Modal;
