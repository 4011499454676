import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const TeamsContext = createContext();
const useTeams = () => useContext(TeamsContext);

export const TeamsProvider = (props) => <ContextProvider url="teams" context={TeamsContext} {...props} />;

export default useTeams;
