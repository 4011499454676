import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Select from 'common/components/Select/Select';
import Table from 'common/components/Table/Table';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import dateRange from 'common/utils/dateRange';
import React, {
  // eslint-disable-next-line comma-dangle
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { computeDays } from 'requests/pages/Summary/summary.utils';
import useFetch from '../../../common/hooks/useFetch';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const HolidaysList = () => {
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const { push } = useRouter();
  const { user, isAdmin, isManager } = useAuth();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
    removeListItem,
  } = useList('holidays', {
    defaultFilters: isAdmin || isManager
      ? {}
      : { user: user.id },
    defaultSort: 'created_at:DESC',
    cachePrefix: 'holidays',
  });

  const {
    removeItem,
    updateItem,
  } = useItem('holidays');
  const {
    data: holidayTypes,
  } = useFetch('holidays-types');
  const {
    data: holidayStatuses,
  } = useFetch('holidays-statuses');

  useEffect(() => {
    setTypes(holidayTypes && holidayTypes?.map((type) => ({ value: type.id, label: type.name, type })));
    // eslint-disable-next-line
    setStatuses(holidayStatuses && holidayStatuses.map((status) => ({ value: status.id, label: t(`common.${status.name}`), status })));
  }, [t, holidayTypes, holidayStatuses]);

  const handleValidate = useCallback(async (holiday, isValidated) => {
    await updateItem(
      holiday.id,
      {
        ...holiday,
        holidays_status: isValidated
          ? statuses.find((s) => s.status.name === 'agreed').status.id
          : statuses.find((s) => s.status.name === 'refused').status.id,
      },
    );
    await fetchItems();
  }, [fetchItems, updateItem, statuses]);

  const handleDelete = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    await fetchItems();
  }, [fetchItems, removeItem, removeListItem]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'user.username_contains',
      className: 'table-start',
      Cell: ({ row: { original: { id, user } } }) => (
        <>
          <div
            className="user-avatar"
            style={{
              backgroundImage: user.avatar
                ? `url(${process.env.REACT_APP_API_URL}${user.avatar.url})` : 'none',
            }}
            onClick={() => push(`/holidays/edit/${id}`)}
          >
            {!user.avatar && user.username && (
            <span>{user?.username[0]}</span>
            )}
          </div>
          <span className="clickable" onClick={() => push(`/holidays/edit/${id}`)}>{user && user.username}</span>
        </>
      ),
    },
    {
      Header: t('common.type'),
      filterName: 'holidays_type',
      filterComponent: (props) => <Select options={types} placeholder={t('common.type')} isFilter {...props} />,
      Cell: ({ row: { original: { holidays_type: holidaysType } } }) => holidaysType?.name,
    },
    {
      Header: t('date.date'),
      // eslint-disable-next-line
      Cell: (
        {
          row: {
            original: {
              start_date: startDate,
              end_date: endDate,
              start_date_type: startDateType,
              end_date_type: endDateType,
            },
          },
        },
      ) => dateRange(t, startDate, endDate, startDateType, endDateType),
    },
    {
      Header: t('common.status'),
      filterName: 'holidays_status',
      filterComponent: (props) => <Select isFilter placeholder={t('common.status')} options={statuses} {...props} />,
      Cell: ({ row: { original: { holidays_status: status } } }) => (
        <span className={`status-tag
        ${status.name === 'agreed' && 'has-background-success'}
        ${status.name === 'refused' && 'has-background-danger'}`}
        >
          {t(`common.${status?.name}`)}
        </span>
      ),
    },
    {
      Header: t('common.daysNumber'),
      Cell: ({ row: { original } }) => t('common.daysCount', { count: computeDays(original) }),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      Cell: ({
        row: {
          original,
        },
      }) => {
        const holidaysStatus = original.holidays_status;

        if ((!isAdmin && !isManager)
        && (holidaysStatus.name === 'agreed' || original.user.id !== user?.id)) {
          return null;
        }

        return (
          <>
            {holidaysStatus.name === 'pending' && (
            <>
              <Button
                onClick={() => handleValidate(original, true)}
                icon="fa-check"
                title={t('common.validate')}
                color="success"
                style={{
                  paddingLeft: '1rem',
                }}
              />
              <Button
                onClick={() => handleValidate(original, false)}
                icon="fa-times"
                title={t('common.refuse')}
                color="danger"
              />
            </>
            )}
            <Button
              onClick={() => push(`/holidays/edit/${original.id}`)}
              icon="fa-pencil-alt"
              color="primary"
              transparent
            />
            {holidaysStatus.name === 'pending' && (
              <Button
                onClick={() => handleDelete(original.id)}
                icon="fa-trash-alt"
                color="danger"
                confirm
                transparent
                confirmMessage={t('common.delete')}
              />
            )}
          </>
        );
      },
    },
  ], [t, push, types, statuses, isAdmin, isManager, user.id, handleValidate, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('common.vacations')}
        link={{ url: '/holidays/add', icon: 'plus', label: t('common.add') }}
      />
      <section className="section list-page list-action">
        <Table
          data={items ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default HolidaysList;
