import poweredBylogo from 'assets/svgFond.svg';
import React from 'react';
import MenuLink from '../MenuLink';

const PositionBottom = () => (
  <div className="menu-bottom">
    <span>Powered by</span>
    <MenuLink className="bottom-brand" to="/">
      <img
        src={poweredBylogo}
        alt="Home"
      />
    </MenuLink>
  </div>
);

export default PositionBottom;
