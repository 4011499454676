import ContextProvider from 'common/contexts/crud';
import React, { createContext, useContext } from 'react';

const HolidaysTypesContext = createContext();
const useHolidaysTypes = () => useContext(HolidaysTypesContext);

// eslint-disable-next-line max-len
export const HolidaysTypesProvider = (props) => <ContextProvider url="holidays-types" context={HolidaysTypesContext} {...props} />;

export default useHolidaysTypes;
