import Article from 'common/components/Article/Article';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import useArticles from '../../../../requests/contexts/article';
import Card from '../../../components/Card/Card';

const HomeArticles = () => {
  const { t } = useTranslation();
  const [article, setArticle] = useState({});
  const [initDone, setInitDone] = useState(false);
  const history = useHistory();

  const {
    items: articles,
    fetchItems: fetchArticles,
    setSort,
    setFilters,
    isFetching,
  } = useArticles();

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  useEffect(() => {
    setFilters({ isWiki: false, published: true });
    setSort('publishedat:DESC');
  }, [setFilters, setSort]);

  useEffect(() => {
    if (!isFetching) {
      setArticle(articles[0]);
      setInitDone(true);
    }
  }, [setArticle, articles, isFetching]);

  return (
    <>
      {article && initDone && (
      <div style={{ padding: '1.3rem 1.35rem 0' }}>
        <Card>
          <div className="card-custom-header">
            <h2 className="title is-2">
              {t('dashboard.actualities')} : {article ? article.title : ''}
            </h2>
            <Link className="button is-light" to="/blog">
              <i className="fas fa-eye" style={{ marginRight: '0.5rem' }} />
              {t('dashboard.allArticles')}
            </Link>
          </div>

          <Article
            isWiki={false}
            isCard
            isBlog
            contributor={(article?.published_by?.username)
              ? `${article?.published_by?.username}` : t('blog.unknown')}
            image={article ? article.image : null}
            date={article.publishedat}
            onClick={() => history.push(`/blog/article/${article.id}`)}
          />
        </Card>
      </div>

      )}
    </>
  );
};

export default HomeArticles;
