import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import AlertsContext from 'common/contexts/alerts';
import { useAuth } from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const onSubmit = async ({ email, confirmed }) => {
    try {
      if (confirmed) {
        const res = await auth.sendConfirmation({ email });

        if (res?.status === 406) {
          setAlert(t('auth.userAlreadyConfirmed'), 'danger');
        } else {
          setAlert(t('auth.confirmationMailSent'), 'success');
          router.push('/login');
        }
      } else {
        await auth.forgotPassword(email);
        setAlert(t('auth.newPasswordSent'), 'success');
        router.push('/login');
      }
    } catch (error) {
      setAlert(t('auth.invalidEmail'), 'danger');
    }
  };

  return (
    <section className="section login-page">
      <div className="offline-logo" style={{ backgroundImage: 'url(/assets/default-logo.jpg)' }} />
      <Card>
        <Form
          onSubmit={onSubmit}
          initialValues={{ email: '', confirmed: false }}
          render={({ handleSubmit, submitting, pristine }) => (
            <>
              <form onSubmit={handleSubmit} noValidate>
                <FormInput
                  type="email"
                  name="email"
                  label={t('common.email')}
                  icon="envelope"
                />
                <div className="form-footer">
                  <FormInput
                    type="checkbox"
                    name="confirmed"
                    label={t('common.hasReceivedConfirmationMail')}
                  />
                  <FormInput
                    type="submit"
                    label={t('common.send')}
                    icon="sign-in-alt"
                    disabled={submitting || pristine}
                    isSubmitting={submitting}
                  />
                  <p>
                    <Link to="/login" className="lead">{t('common.back')}</Link>
                  </p>
                </div>
              </form>
            </>
          )}
        />
      </Card>
    </section>
  );
};

export default ForgotPassword;
