import ErrorBoundary from 'common/components/ErrorBoundary/ErrorBoundary';
import { rootloader } from 'common/components/RootLoader/RootLoader';
import { AlertsProvider } from 'common/contexts/alerts';
import { AuthProvider } from 'common/contexts/auth';
import { MenuProvider } from 'common/contexts/menu';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { ActivitiesReportsProviders } from 'requests/contexts/activitiesReports';
import { ActivityReportProvider } from 'requests/contexts/activityReport';
import { CompaniesProvider } from 'requests/contexts/companies';
import { ContractTypesProvider } from 'requests/contexts/contractTypes';
import { HolidaysProvider } from 'requests/contexts/holidays';
import { HolidaysTypesProvider } from 'requests/contexts/holidaysTypes';
import { JobsProvider } from 'requests/contexts/jobs';
import { ProjectsProvider } from 'requests/contexts/projects';
import { SitesProvider } from 'requests/contexts/sites';
import { TaskTypesProvider } from 'requests/contexts/taskTypes';
import { TeamsProvider } from 'requests/contexts/teams';
import { TodosProvider } from 'requests/contexts/todos';
import { TodoTemplatesProvider } from 'requests/contexts/todoTemplates';
import { UsersProvider } from 'users/contexts/users';
import { ArticlesProvider } from './requests/contexts/article';
import { CategoryProvider } from './requests/contexts/category';

const AppProvider = ({ children }) => (
  <Suspense fallback={rootloader()}>
    <ErrorBoundary>
      <AlertsProvider>
        <CompaniesProvider>
          <SitesProvider>
            <AuthProvider>
              <MenuProvider>
                <UsersProvider>
                  <HolidaysProvider>
                    <ActivitiesReportsProviders>
                      <ActivityReportProvider>
                        <ProjectsProvider>
                          <TaskTypesProvider>
                            <TeamsProvider>
                              <JobsProvider>
                                <HolidaysTypesProvider>
                                  <ContractTypesProvider>
                                    <ArticlesProvider>
                                      <CategoryProvider>
                                        <TodoTemplatesProvider>
                                          <TodosProvider>
                                            {children}
                                          </TodosProvider>
                                        </TodoTemplatesProvider>
                                      </CategoryProvider>
                                    </ArticlesProvider>
                                  </ContractTypesProvider>
                                </HolidaysTypesProvider>
                              </JobsProvider>
                            </TeamsProvider>
                          </TaskTypesProvider>
                        </ProjectsProvider>
                      </ActivityReportProvider>
                    </ActivitiesReportsProviders>
                  </HolidaysProvider>
                </UsersProvider>
              </MenuProvider>
            </AuthProvider>
          </SitesProvider>
        </CompaniesProvider>
      </AlertsProvider>
    </ErrorBoundary>
  </Suspense>
);

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
