import Button from 'common/components/Button/Button';
import DatePicker from 'common/components/DatePicker/DatePicker';
import FormInput from 'common/components/FormInput/FormInput';
import Loader from 'common/components/Loader/Loader';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Select from 'common/components/Select/Select';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import fetchJSON from 'common/utils/fetchJSON';
import holidayUtils from 'common/utils/holidays';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import React, {
  forwardRef,
  // eslint-disable-next-line comma-dangle
  useCallback, useContext, useEffect, useMemo, useState
} from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { createFilter } from 'react-select';
import useActivityReport from 'requests/contexts/activityReport';
import useFetch from '../../../common/hooks/useFetch';
import useList from '../../../common/hooks/useList';

/* Obligé de mettre un '-' pour final form sinon il va dire c'est un numéro et ca va planter
 ce format est utilisé comme "nom de champ"
*/
const dateFormat = 'YYYYMMDD-HHmmss';

const filterConfig = {
  stringify: option => option.data.search_label,
};

const ActivityReport = () => {
  const { t } = useTranslation();
  const { isAdmin, isManager, user: currentUser } = useAuth();
  const [date, setDate] = useState(new Date());
  const [isSaving, setIsSaving] = useState(false);
  const { setAlert } = useContext(AlertsContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectUser] = useState(currentUser?.id);

  const DateButton = forwardRef(({ onClick }, ref) => (
    <div
      onClick={onClick}
      ref={ref}
      style={{
        textAlign: 'middle',
      }}
    >
      {t('activityReports.period', {
        from: days[0].day.format('LL'),
        to: days.slice(-1)[0].day.format('LL'),
      })}
    </div>
  ));

  /** ********************* Projects ********************* */
  const {
    data: projects,
  } = useFetch(
    `projects?_sort=${encodeURIComponent('name:ASC')}`,
    { cachePrefix: 'cra_projects' },
  );

  const projectOptions = useMemo(() => {
    projects && projects.sort((a, b) => {
      if (a.in_progress) {
        if (b.in_progress) {
          return (a.name > b.name) ? 1 : -1;
        }
        return -1;
      }
      if (b.in_progress) {
        return 1;
      }
      return (a.name > b.name) ? 1 : -1;
    });
    return projects?.map((type) => ({
      value: type.id,
      search_label: type.name,
      label: type.in_progress
        ? (
          <>
            <i
              className="fas fa-circle color-primary"
              style={{
                position: 'relative', top: -1, fontSize: '0.5em', marginRight: '0.5rem',
              }}
            />

            <b>{type.name}</b>
          </>
        ) : <span className="color-grey">{type.name}</span>,
      type,
    }));
  }, [projects]);

  const getProjectTaskTypes = (selectedProject) => {
    if (!projects) {
      return [];
    }
    if (projects.find((p) => p.id === selectedProject)) {
      if (projects.find((p) => p.id === selectedProject).task_types.length) {
        return projects.find((p) => p.id === selectedProject).task_types.map(
          (option) => ({ value: option.id, label: option.name, option }),
        );
      }
    }
    return [];
  };

  /** *********************  Users ********************* */

  useEffect(() => {
    if (isAdmin) {
      fetchJSON({
        url: 'users',
        method: 'GET',
      }).then((results) => {
        setUsers(results.filter((user) => (user.time_report === true))
          .map((user) => ({ value: user.id, label: user.username, user })));
      });
    } else if (isManager) {
      if (currentUser?.managedTeams) {
        currentUser.managedTeams.forEach((team) => {
          fetchJSON({
            url: `teams/${team?.id}`,
            method: 'GET',
          }).then((result) => {
            const res = (result.users?.filter((user) => (user.time_report === true))
              .map((user) => ({ value: user.id, label: user.username, user })));

            setUsers((users) => ([...users, ...res]));
          });
        });
      } else if (!currentUser?.managedTeams && currentUser?.managedSites) {
        fetchJSON({
          url: `sites/${currentUser?.managedSites}`,
          method: 'GET',
        }).then((result) => {
          setUsers(result.users?.filter((user) => (user.time_report === true))
            .map((user) => ({ value: user.id, label: user.username, user })));
        });
      } else {
        setUsers(currentUser);
      }
    } else {
      setUsers((users) => ([...users, { value: currentUser.id, label: currentUser.username, currentUser }]));
    }
  }, [isAdmin, isManager, currentUser]);

  /** *********************   Activity Report ********************* */
  const {
    setFilters,
    fetchItems,
    filters,
    items,
    saveActivityReports,
    mode,
    setMode,
    isFetching,
  } = useActivityReport();

  const {
    items: holidays,
    setFilters: setHolidayFilters,
  } = useList('holidays', {
    defaultFilters: {
      start_date_lte: moment(date)
        .endOf('month')
        .toISOString(),
      end_date_gte: moment(date)
        .startOf('month')
        .toISOString(),
      user: selectedUser,
    },
    defaultSort: 'created_at:DESC',
    cachePrefix: 'craholidays',
  });

  const increaseDate = () => {
    const newDate = moment(date).add(1, mode).toDate();

    setDate(newDate);
  };

  const decreaseDate = () => {
    const newDate = moment(date).subtract(1, mode).toDate();

    setDate(newDate);
  };

  useEffect(() => {
    if (mode === 'week') {
      setFilters({
        user: selectedUser,
        date_gte: moment(date)
          .startOf('isoWeek')
          .startOf('day')
          .weekday(0)
          .format('x'),
        date_lte: moment(date)
          .startOf('isoWeek')
          .startOf('day')
          .weekday(6)
          .format('x'),
      });
    } else {
      setFilters({
        user: selectedUser,
        date_gte: moment(date)
          .startOf('month')
          .format('x'),
        date_lte: moment(date)
          .endOf('month')
          .format('x'),
      });
    }
    setHolidayFilters({
      user: selectedUser,
      start_date_lte: moment(date).endOf('month').toISOString(),
      end_date_gte: moment(date).startOf('month').toISOString(),
    });
  }, [setFilters, date, mode, selectedUser, setHolidayFilters]);

  /* Force refresh */
  useEffect(() => {
    console.log(filters);
    if (filters.user && filters.date_lte && filters.date_gte) {
      console.log('fetch');
      fetchItems({ pageSize: -1 });
    }
  }, [filters, fetchItems]);

  const initFormValues = useMemo(() => {
    /**
     * Les données arrivent sous le format
     * { project :{id}, task_type : {id}, user :{id}, date, timeSpent }
     * les données du formulaire sont sous la forme
     * [{project, taskType, date1 : timeSpent, date2 : timeSpent,....}]
     */
    const values = { activities: [] };

    items.forEach(item => {
      const activity = values.activities.find(({ project, taskType }) => (
        item.project?.id === project && item.task_type?.id === taskType
      ));

      const date = moment(item.date).format(dateFormat);

      if (activity) {
        activity[date] = item.timeSpent;
      } else {
        values.activities.push({
          project: item.project?.id,
          taskType: item.task_type?.id,
          [date]: item.timeSpent,
        });
      }
    });

    /* Par default on veut une ligne vide */
    if (values.activities.length === 0) {
      values.activities.push({});
    }
    return values;
  }, [items]);

  /** ******************** Days ********************** */
  const getDateToday = () => {
    const today = new Date();
    const date = moment(today).format('YYMMDD');

    return date;
  };

  const days = useMemo(() => {
    const isDayAvailable = (day) => {
      if (holidayUtils.isHoliday(day) || [0, 6].includes(day.day())) {
        return false;
      }

      if (holidays) {
        // eslint-disable-next-line no-restricted-syntax
        for (const holiday of holidays) {
          // "[]" in moment().isBetween() means inclusive
          if (day.isBetween(holiday.start_date, holiday.end_date, undefined, '[]')) {
            return false;
          }
        }
      }

      return true;
    };

    if (mode === 'week') {
      return Array.from({ length: 7 }).map((a, index) => {
        const day = moment(date).startOf('isoWeek').weekday(index);

        return {
          day,
          available: isDayAvailable(day),
        };
      });
    }
    return Array.from({ length: moment(date).daysInMonth() }).map((a, index) => {
      const day = moment(date).startOf('month').date(index + 1);

      return {
        day,
        available: isDayAvailable(day),
      };
    });
  }, [date, mode, holidays]);

  const handleUserChange = (u) => {
    setSelectUser(u);
  };

  const onSubmit = useCallback((values) => {
    setIsSaving(true);

    const submit = async () => {
      const daySlots = [];
      const timeSpentPerDay = [];

      /**
       * Les données doivent être enregistrées sous ce format
       * { project, task_type, user, date, timeSpent }
       * les données du formulaire sont sous la forme
       * [{project, taskType, date1 : timeSpent, date2 : timeSpent,....}]
       */
      values.activities.forEach((activity) => {
        const template = {
          project: activity.project,
          task_type: activity.taskType,
          user: selectedUser,
          description: '',
          company: currentUser?.company?.id,
        };

        /** on parcours les items du tableau chaque case = 1 item à poster */
        days.forEach(({ day }) => {
          const fieldName = day.format(dateFormat);
          const data = {
            ...template,
            date: day.toISOString(),
            timeSpent: activity[fieldName],
          };
          /* Si on a déjà un id pour la combinaison : project,task_type */
          const existElem = items.find(({ project, task_type: taskType }) => (
            activity.project === project?.id && activity.taskType === taskType?.id
          ));

          if (existElem && existElem.user.id === selectedUser) {
            data.id = existElem.id;
          }
          daySlots.push(data);

          if (data.timeSpent) {
            timeSpentPerDay[day.toISOString()] = timeSpentPerDay[day.toISOString()]
              ? timeSpentPerDay[day.toISOString()] + Number(data.timeSpent)
              : Number(data.timeSpent);
          }
        });
      });

      const invalidDates = Object.keys(timeSpentPerDay).filter(date => timeSpentPerDay[date] > 1);

      if (invalidDates.length > 0) {
        setAlert(t('common.wrongCraEntry', {
          date: invalidDates.map(date => moment(date).format('DD/MM')).join(', '),
        }), 'danger');
        setIsSaving(false);
        return;
      }

      try {
        await saveActivityReports({ days: daySlots, user: selectedUser });
        setAlert(t('common.saved'), 'success');
      } catch (e) {
        console.log(e);
        setAlert(e.message, 'danger');
      } finally {
        setIsSaving(false);
      }
    };

    submit();
  }, [items, days, selectedUser, saveActivityReports, setAlert, t, currentUser]);

  const modeOptions = [
    { label: t('common.week'), value: 'week' },
    { label: t('common.month'), value: 'month' },
  ];

  return (
    <>
      <PageHeader title={t('common.activityReport')} />
      <section className="section list-page">
        <div className="head-filter-box">

          {(isAdmin || isManager) && (
          <div className="head-filter-item">
            <Select
              style={{ width: '150' }}
              options={users}
              value={selectedUser}
              clearable={false}
              onChange={handleUserChange}
            />
          </div>
          )}

          <div className="head-filter-item">
            <Select
              style={{ width: '150' }}
              options={modeOptions}
              value={mode}
              clearable={false}
              onChange={setMode}
            />

            <div className="dateGhostSelector">
              <i className="fas fa-angle-left" onClick={decreaseDate} />
              <div className="dateGhostSelector-element">
                <DatePicker
                  className="react-date-picker"
                  value={date}
                  onChange={setDate}
                  clearIcon={null}
                  customInput={<DateButton />}
                  selectedTimeUnit={mode}
                  showWeekNumbers
                />
              </div>
              <i className="fas fa-angle-right" onClick={increaseDate} />
            </div>

          </div>

        </div>
        {isFetching ? (
          <Loader isScreen processing={isFetching} timeoutDuration={4000} />
        ) : (
          <Form
            onSubmit={onSubmit}
            initialValues={initFormValues}
            mutators={{
              ...arrayMutators,
            }}
            render={({
              handleSubmit,
              values,
              form: { mutators: { push } },
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="custom-cell">
                  <div className="table-box">
                    <div className="table-padding" style={{ alignItems: 'center' }}>
                      <table className="activity-table table is-striped overflow-visible">
                        <thead>
                          <tr>
                            <th className="column-project">
                              {t('common.projects')}
                            </th>
                            { days.map(({ day }) => (
                              <th className="column-day" key={day}>
                                <div className={getDateToday() === day.format('YYMMDD')
                                  ? 'dateTh dateTh-isToday' : 'dateTh'}
                                >
                                  <span className="day-word">
                                    {moment(day).format('ddd')}
                                  </span>
                                  <span className="day-number">
                                    {Number(moment(day).format('D'))}
                                  </span>
                                </div>
                              </th>
                            )) }
                            <th className="column-action">
                              { ' '}
                            </th>
                          </tr>
                        </thead>
                        <FieldArray name="activities">
                          {({ fields }) => {
                            let totalInPeriod = 0;
                            const daysAvailable = days.filter(d => d.available).length;

                            return (
                              <tbody>
                                { fields.map((row, index) => {
                                  let totalInProject = 0;

                                  days.map(({ day }) => {
                                    if (values.activities[index][`${day.format(dateFormat)}`]) {
                                      totalInProject
                                    += parseFloat(values.activities[index][`${day.format(dateFormat)}`]);
                                    }
                                    return totalInProject;
                                  });

                                  return (
                                    <tr key={row}>
                                      <td className="column-project">
                                        <FormInput
                                          name={`${row}.project`}
                                          type="custom"
                                          required
                                          onChange={() => { values.activities[index].taskType = null; }}
                                          placeholder={t('common.project')}
                                        >
                                          <Select
                                            options={projectOptions}
                                            filterOption={createFilter(filterConfig)}
                                          />
                                        </FormInput>
                                        <div className="mission-field">
                                          {/* <i className="fas fa-tag" /> */}
                                          <FormInput
                                            name={`${row}.taskType`}
                                            type="custom"
                                            required
                                            disabled={!values.activities[index].project}
                                            placeholder={t('common.taskType')}
                                          >
                                            <Select
                                              options={getProjectTaskTypes(values.activities[index].project)}
                                            />
                                          </FormInput>
                                        </div>
                                      </td>

                                      {days.map(({ day, available }) => (
                                        <td
                                          key={day}
                                          className={available ? 'column-day' : 'column-day offDay'}
                                        >
                                          <FormInput
                                            name={`${row}.${day.format(dateFormat)}]`}
                                            type="number"
                                            min={0}
                                            max={1}
                                            step={0.1}
                                            className="activityTime"
                                          />
                                        </td>
                                      ))}
                                      <td className="column-action">
                                        <div className="total-in-project">
                                          <span>{totalInProject}</span>
                                          <span>{t('projects.d')}</span>
                                        </div>
                                        <Button
                                          onClick={() => fields.remove(index)}
                                          icon="fa-trash"
                                          color="danger"
                                          outlined
                                          className="delete-button"
                                        />
                                      </td>
                                    </tr>
                                  );
                                }) }
                                <tr className="sticky-table-footer">
                                  <td className="column-project" />
                                  {days.map(({ day, available }) => {
                                    let totalInDay = 0;

                                    fields.map((row, fieldindex) => {
                                      if (values.activities[fieldindex][`${day.format(dateFormat)}`]) {
                                        totalInDay
                                          += parseFloat(values.activities[fieldindex][`${day.format(dateFormat)}`]);
                                        totalInPeriod
                                          += parseFloat(values.activities[fieldindex][`${day.format(dateFormat)}`]);
                                      }

                                      return totalInDay;
                                    });

                                    return (
                                      <td
                                        key={day}
                                        className={available ? 'column-day' : 'column-day offDay'}
                                      >
                                        {available && (
                                        <div className="day-ratio">
                                          <span style={{ color: totalInDay < 1 ? '#ff7575' : '#0e7a92' }}>
                                            {totalInDay}
                                          </span>
                                          <hr style={{ margin: 0 }} />
                                          <span>1</span>
                                        </div>
                                        )}

                                      </td>
                                    );
                                  })}
                                  <td className="column-action">

                                    <div className="day-ratio total-in-period">
                                      <span style={{ color: totalInPeriod < daysAvailable ? '#ff7575' : '#0e7a92' }}>
                                        {totalInPeriod}
                                      </span>
                                      <hr style={{ margin: 0 }} />
                                      <span>{daysAvailable}</span>
                                    </div>

                                  </td>
                                </tr>
                              </tbody>

                            );
                          }}
                        </FieldArray>
                      </table>
                      <Button
                        onClick={() => push('activities', {})}
                        icon="fa-plus"
                        className="button is-light"
                        label={t('common.add')}
                      />
                    </div>

                  </div>
                </div>

                <div className="paginator-box bottom-buttons">
                  <nav className="pagination">
                    <ul>
                      <li>
                        {isSaving ? (
                          <Loader />
                        ) : (
                          <Button
                            type="submit"
                            icon="fa-save"
                            color="primary"
                            label={t('common.save')}
                          />
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </form>
            )}
          />
        )}
      </section>
    </>
  );
};

export default ActivityReport;
