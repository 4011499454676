/* eslint-disable object-curly-newline */
import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useAuth from 'common/contexts/auth';
import useRouter from 'common/hooks/use-router';
import React, { useCallback, useContext, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import useItem from '../../common/hooks/useItem';

const HolidaysTypesForm = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { id: holidaysTypeId } = router.match.params;
  const {
    saveItem, updateItem, item, error,
  } = useItem('holidays-types', holidaysTypeId);

  const { user: currentUser } = useAuth();
  const { currentCompany } = useCompanies();

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/holidays-types');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async item => {
    if (item.id) {
      await updateItem(item.id, item);
      back();
    } else {
      await saveItem(item);
      back();
    }
  }, [updateItem, back, saveItem]);

  const initialValues = useMemo(() => item?.id ? ({
    ...item,
    name: item?.name,
    company: item?.company?.id,
  }) : ({
    company: currentCompany.id,
  }), [item, currentCompany]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/holidays-types',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <Form
          onSubmit={onSubmit}
          subscription={{ values: true, submitting: true, pristine: true }}
          initialValues={initialValues}
          render={({
            handleSubmit, submitting, pristine,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />
              <div className="columns">
                <div className="column">
                  <Card>
                    { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                    <FormInput
                      type="custom"
                      name="company"
                      label={t('common.company')}
                    >
                      <CompanySelect />
                    </FormInput>
                    )}
                    <FormInput
                      name="name"
                      label={t('holidaysType.name')}
                      required
                    />
                    <FormInput
                      name="has_balance"
                      label={t('contractType.hasBalance')}
                      type="checkbox"
                    />

                    <FormInput
                      name="color"
                      label={t('holidaysType.color')}
                      required
                      type="circle-color"
                    />

                    <FormInput
                      type="icon"
                      name="icon"
                      label={t('holidaysType.icon')}
                      required
                    />
                    <FormInput
                      type="submit"
                      label={t('common.send')}
                      icon="paper-plane"
                      disabled={submitting || pristine}
                      isSubmitting={submitting}
                    />
                  </Card>
                </div>
              </div>

            </form>
          )}
        />
      </section>
    </>
  );
};

export default HolidaysTypesForm;
