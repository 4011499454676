import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Card from '../Card/Card';

const Modal = ({
  confirmModal, closeModal, children, isConfirm, className,
}) => {
  const { t } = useTranslation();

  const handleEscape = useCallback((e) => {
    e.keyCode === 27 && closeModal();
  }, [closeModal]);

  useEffect(() => {
    const keyup = document.addEventListener('keyup', handleEscape);

    return () => document.removeEventListener('keyup', keyup);
  }, [handleEscape]);

  return ReactDOM.createPortal(
    <div className={`modal is-active is-clipped ${className}`}>
      <div className="modal-background" onClick={closeModal} />
      <Card>
        <button onClick={closeModal} className="modal-close is-large modal-close-absolute" aria-label="close" />
        { children }
        {isConfirm && (
        <div className="buttons">
          <button onClick={closeModal} data-cy="cancel" className="button is-dark is-outlined">
            <span className="icon">
              <i className="fas fa-times" />
            </span>
            <span>{t('modal.cancel')}</span>
          </button>
          <button onClick={confirmModal} data-cy="confirm" className="button is-warning">
            <span className="icon">
              <i className="fas fa-exclamation-triangle" />
            </span>
            <span>{t('modal.confirm')}</span>
          </button>
        </div>
        )}
      </Card>

    </div>,
    document.getElementById('portal-root'),
  );
};

Modal.propTypes = {
  isConfirm: PropTypes.bool,
  children: PropTypes.node.isRequired,
  confirmModal: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  isConfirm: false,
  className: null,
  confirmModal: null,
};

export default Modal;
