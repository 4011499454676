import Button from 'common/components/Button/Button';
import PageHeader from 'common/components/PageHeader/PageHeader';
import Table from 'common/components/Table/Table';
import useRouter from 'common/hooks/use-router';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useItem from '../../../common/hooks/useItem';
import useList from '../../../common/hooks/useList';

const TodoTemplates = () => {
  const { t } = useTranslation();
  const dateFormat = 'DD/MM/YY, HH:mm';
  // const { user: currentUser, isAdmin, isManager } = useAuth();
  const {
    fetchItems,
    items,
    isFetching,
    filters,
    setFilter,
    setPageIndex,
    pageIndex,
    pageCount,
    removeListItem,
  } = useList('todo-templates', { defaultFilters: {}, defaultSort: 'name:ASC', cachePrefix: 'todotemplates' });
  const {
    removeItem,
  } = useItem('todo-templates');
  const { push } = useRouter();

  const handleDelete = useCallback(async (id) => {
    removeListItem(id);
    await removeItem(id);
    fetchItems();
  }, [removeListItem, fetchItems, removeItem]);

  const columns = useMemo(() => [
    {
      Header: t('common.name'),
      filterName: 'name_contains',
      className: 'table-start',
      Cell: ({ row: { original: { id, name } } }) => (
        <>
          <div
            onClick={() => push(`/todo-templates/edit/${id}`)}
            className="crud-item-avatar"
          >
            <span>{name[0]}</span>
          </div>

          <span className="clickable" onClick={() => push(`/todo-templates/edit/${id}`)}>{name}</span>
        </>
      ),
    },
    {
      Header: t('todolist.subitems'),
      Cell: ({ row: { original: { subitems } } }) => (
        <div>
          {subitems?.length > 0 ? (
            <ul className="table-inner-list">
              { subitems.map((subitem, index) => (
                <li className="table-inner-list-item" key={`subitem-${index}`}>
                  <i className="fas fa-check color-primary" />
                  <span>{subitem.name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <span>{t('todolist.noSubitems')}</span>
          )}
        </div>
      ),
    },
    {
      Header: t('todolist.modifiedOn'),
      Cell: ({ row: { original: { updated_at: updatedAt } } }) => (
        <span>
          <i className="fas fa-clock color-primary" /> &nbsp;
          {moment(updatedAt).format(dateFormat)}
        </span>
      ),
    },
    {
      Header: t('common.actions'),
      className: 'table-actions',
      // eslint-disable-next-line
      Cell: ({ row: { original: { id } } }) => {

        return (
          <>
            <Button
              onClick={() => push(`/todo-templates/edit/${id}`)}
              icon="fa-pencil-alt"
              color="primary"
              transparent
            />
            <Button
              // eslint-disable-next-line react/prop-types
              onClick={() => handleDelete(id)}
              icon="fa-trash-alt"
              color="danger"
              confirm
              transparent
              confirmMessage={t('common.delete')}
            />
          </>
        );
      },
    },
  ], [t, push, handleDelete]);

  return (
    <>
      <PageHeader
        title={t('common.todoTemplates')}
        link={{ url: '/todo-templates/add', icon: 'plus', label: t('common.add') }}
        subLink={{
          url: '/configure',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section list-page list-action">
        <Table
          data={items ?? []}
          columns={columns}
          isFetching={isFetching}
          pageCount={pageCount}
          filters={filters}
          setFilter={setFilter}
          setPageIndex={setPageIndex}
          pageIndex={pageIndex}
        />
      </section>
    </>
  );
};

export default TodoTemplates;
