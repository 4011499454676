/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';

const HolidaysStatusSelect = (props) => {
  const { t } = useTranslation();

  const processOptions = useCallback((result) => result.map((status) => ({
    value: status.id, label: t(`common.${status.name}`), status,
  })), [t]);

  return (
    <RemoteSelect
      url="holidays-statuses"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.type') : null}
      processOptions={processOptions}
      {...props}
    />
  );
};

export default HolidaysStatusSelect;
