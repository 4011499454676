/* eslint-disable react/prop-types */
import RemoteSelect from 'common/components/RemoteSelect/RemoteSelect';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const processOptions = (items, balances) => items.map((type) => {
  const balance = balances?.find(balance => balance.holidays_type?.id === type.id);

  return {
    value: type.id,
    label:
    (
      <div className="holiday-type-select-item">
        <span
          className="icon-picker-circle is-edited"
          style={{
            color: `${type.color}`,
          }}
        >
          <i className={`fas fa-${type.icon}`} />
        </span>
        <span className="holiday-type-select-name">{type.name}</span>
        { type.has_balance && Boolean(balance) && (
        <span className="holiday-type-select-solde"><i className="fas fa-hourglass-half" /> {balance.balance}</span>
        )}
      </div>
    ),
    type,
  };
});

const HolidaysTypeSelect = ({ balances, ...props }) => {
  const { t } = useTranslation();

  const handleProcessOptions = useCallback((items) => processOptions(items, balances), [balances]);

  return (
    <RemoteSelect
      url="holidays-types"
      isFilter={props.isFilter}
      placeholder={props.isFilter ? t('common.type') : null}
      processOptions={handleProcessOptions}
      {...props}
    />
  );
};

export default HolidaysTypeSelect;
