import Button from 'common/components/Button/Button';
import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import useAuth from 'common/contexts/auth';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import UserSelect from 'users/components/UserSelect';

const DragHandle = SortableHandle(() => (
  <span className="icon" style={{ cursor: 'move' }}>
    <i className="fas fa-ellipsis-v" />
  </span>
));

const SortableItem = SortableElement(({
  value, name, fields,
}) => {
  const { t } = useTranslation();

  return (
    <div className="sortable-item">
      <div className="sortable-item-start">
        <DragHandle />
        <Button
          onClick={() => fields.remove(value)}
          icon="fa-trash-alt"
          color="danger"
          confirm
          transparent
          confirmMessage={t('common.delete')}
          style={{ padding: 0 }}
        />
      </div>

      <div className="sortable-item-main">
        <div className="sortable-item-main-row">
          <div style={{ flex: 1 }}>
            <FormInput
              name={`${name}.name`}
              placeholder={t('common.name')}
              required
              className="list-item-field"
            />
          </div>

          <div data-tooltip-bottom={t('todolist.state')}>
            <FormInput
              name={`${name}.state`}
              className="list-item-field"
              type="checkbox"
            />
          </div>

        </div>

        <FormInput
          name={`${name}.description`}
          placeholder={t('todolist.description')}
          className="list-item-field"
        />

      </div>

    </div>
  );
});

const SortableList = SortableContainer(({ items }) => (
  <div className="sortable-subform">
    {items && items.map((name, index) => (
      <SortableItem
        key={`item-${index}`}
        index={index}
        value={index}
        name={name}
        fields={items}
      />
    ))}
  </div>
));

const sortEnd = move => ({ oldIndex, newIndex }) => {
  move(oldIndex, newIndex);
};

const missingCheck = (subitems) => {
  const res = subitems.filter(val => val.state).length < subitems.length;

  return (res);
};

const TodoForm = ({ onSubmit, todo }) => {
  const { t } = useTranslation();
  const { user: currentUser, isAdmin, isManager } = useAuth();
  const { currentCompany } = useCompanies();
  const canEdit = (isAdmin || isManager || currentUser?.id === todo?.author);
  const assignedToMe = currentUser?.id === todo?.user?.id;
  const { query } = useLocation();

  const initialValues = useMemo(() => todo?.id ? {
    ...todo,
    company: todo?.company?.id,
    user: todo?.user?.id,
  } : {
    name: query?.item ? query.item.name : '',
    description: query?.item ? query.item.description : null,
    subitems: query?.item?.subitems ? query.item.subitems : [],
    company: currentCompany.id,
    user: currentUser.id,
    author: currentUser.id,
  }, [todo, currentCompany, currentUser, query]);

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit, submitting, pristine, values,
      }) => (
        <form onSubmit={handleSubmit} noValidate className="todo-form">
          <FormScrollError />
          <div className="columns">
            <div className="column if-two-thirds">
              <Card>
                <div className="card-custom-header">
                  <h2 className="title is-2">
                    {t('todolist.editMainTask')}
                  </h2>
                </div>
                { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                  <FormInput
                    type="custom"
                    name="company"
                    label={t('common.company')}
                  >
                    <CompanySelect />
                  </FormInput>
                )}

                <div className="columns">
                  <div className="column is-two-thirds">
                    <FormInput
                      name="name"
                      label={t('common.name')}
                      disabled={!canEdit}
                      required
                    />
                  </div>
                  <div className="column is-one-third" style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div style={{ flex: 1, marginRight: '1rem' }}>
                      <FormInput
                        type="custom"
                        name="user"
                        label={t('common.user')}
                        disabled={!canEdit}
                        required
                      >
                        <UserSelect active={1} />
                      </FormInput>
                    </div>

                    {!values.subitems.length && (
                    <div data-tooltip-bottom={t('todolist.state')} style={{ paddingBottom: '0.3rem' }}>
                      <FormInput
                        name="state"
                        className="list-item-field"
                        type="checkbox"
                        disabled={!assignedToMe && !canEdit}
                      />
                    </div>
                    )}

                  </div>
                </div>

                <FormInput
                  name="description"
                  type="richtext"
                  placeholder={t('todolist.description')}
                  disabled={!canEdit}
                />

              </Card>
            </div>

            <div className="column is-one-third">
              <Card title={t('todolist.editSubTasks')} prepareScrollRight>
                <FieldArray name="subitems" onChanges={missingCheck(values.subitems)}>
                  {({ fields }) => (
                    <div className="sortable-box">
                      <div className="card-scroller">
                        <SortableList
                          items={fields}
                          useDragHandle
                          onSortEnd={sortEnd(fields.move)}
                        />
                      </div>
                      <Button
                        onClick={() => fields.push({})}
                        icon="fa-plus"
                        label={t('todolist.addItem')}
                        className="button is-light"
                      />
                    </div>
                  )}
                </FieldArray>

              </Card>
            </div>
          </div>

          <div className="stickySubmit">
            <FormInput
              type="submit"
              label={t('common.send')}
              icon="paper-plane"
              disabled={submitting || pristine}
              isSubmitting={submitting}
            />
          </div>

        </form>
      )}
    />
  );
};

TodoForm.propTypes = {
  onSubmit: PropTypes.func,
  todo: PropTypes.object,
};

TodoForm.defaultProps = {
  onSubmit() { },
  todo: {},
};

export default TodoForm;
