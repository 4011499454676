import { useEffect, useCallback } from 'react';
import useAsync from './useAsync';
import fetchJSON from 'common/utils/fetchJSON';

// Tableau pour enregistrer les promises de chaque url
const fetchPromises = {};
// Tableau pour savoir le nombre de subscribers a une url
const nbSubscribers = {};

const useFetch = (url, options = {}) => {
  const opts = {
    sharePromise: true,
    cachePrefix: options.cachePrefix ?? url.replace(/[/?&]/g, '_'),
    ...options,
  };

  const fetchFn = useCallback((fetchOptions = {}) => {
    const controller = new AbortController();
    const { signal } = controller;

    let promise;

    const fetchOpts = {
      url, method: 'GET', signal, ...fetchOptions,
    };

    if (fetchOpts.method === 'GET') {
      if (!opts.sharePromise || !fetchPromises[url]) {
        fetchPromises[url] = fetchJSON(fetchOpts);
      }
      promise = fetchPromises[url];
    } else {
      promise = fetchJSON(fetchOpts);
    }

    promise.cancel = () => { controller.abort(); };

    return promise;
  }, [url, opts.sharePromise]);

  // Sur le "DidMount" on ajoute +1 au subscribers
  // Sur le "umount" on retire 1 au subscribers
  useEffect(() => {
    if (typeof nbSubscribers[url] === 'undefined') {
      nbSubscribers[url] = 0;
      fetchPromises[url] = null;
    }
    nbSubscribers[url]++;

    return () => {
      nbSubscribers[url]--;
      // Si plus aucun composant mounté n'utilise le useShareFetch
      // on retire le resultat pour reforcer un refresh la prochaine fois
      if (nbSubscribers[url] === 0) {
        fetchPromises[url] = null;
      }
    };
  }, [url]);

  const {
    execute: fetchData,
    isProcessing,
    isPreviousData,
    setData,
    data,
    error,
  } = useAsync(fetchFn, opts);

  return {
    data, isFetching: isProcessing, error, fetchData, isPreviousData, setData,
  };
};

export default useFetch;
