import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const IconPicker = ({
  value,
  onChange,
  multiple,
  clearable,
  disabled,
  required,
  options,
  className,
  ...rest
}) => {
  const handleChange = useCallback((val) => {
    if (multiple) {
      if (!Array.isArray(value)) {
        onChange([val]);
      } else if (value.includes(val)) {
        const newValue = value.filter(v => v !== val);

        if (clearable || newValue.length > 0) {
          onChange(newValue);
        }
      } else {
        onChange([...value, val]);
      }
    } else {
      onChange(val);
    }
  }, [onChange, multiple, clearable, value]);

  const divClassName = `buttons has-addons icons-bar ${className}`;

  const iconList = options || [

    { label: 'baby-carriage', value: 'baby-carriage' },
    { label: 'ban', value: 'ban' },
    { label: 'chalkboard-teacher', value: 'chalkboard-teacher' },
    { label: 'church', value: 'church' },
    { label: 'couch', value: 'couch' },
    // { label: 'hands-heart', value: 'hands-heart' },
    { label: 'pills', value: 'pills' },
    { label: 'plane-departure', value: 'plane-departure' },
    { label: 'procedures', value: 'procedures' },
    // { label: 'rv', value: 'rv' },
  ];

  return (
    <div className={divClassName} {...rest}>
      { iconList.map(({
        value: val, ...button
      }, index) => {
        let selected = false;

        if (multiple) {
          selected = Array.isArray(value) && value.includes(val);
        } else {
          selected = val === value;
        }

        return (
          <div
            key={index}
            {...button}
            onClick={() => { handleChange(val); }}
            className={selected ? 'icon-picker-circle is-selected' : 'icon-picker-circle'}
            style={{
              borderWidth: 1,
              borderColor: 'rgba(0,0,0,0.2)',
              borderStyle: 'solid',
            }}
          >
            <i className={`fas fa-${val}`} />
          </div>
        );
      })}
    </div>
  );
};

IconPicker.propTypes = {
  value: PropTypes.any,
  children: PropTypes.element,
  className: PropTypes.string,
  onChange: PropTypes.func,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array.isRequired,
  'data-cy': PropTypes.string,
};

IconPicker.defaultProps = {
  value: null,
  onChange() {},
  className: null,
  children: null,
  clearable: false,
  multiple: false,
  disabled: false,
  required: false,
  'data-cy': '',
};

export default IconPicker;
