import Loader from 'common/components/Loader/Loader';
import useList from 'common/hooks/useList';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSites from 'requests/contexts/sites';
import Card from '../../../components/Card/Card';
import { useAuth } from '../../../contexts/auth';

const HomeLeaves = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const [absentUsers, setAbsentUsers] = useState([]);
  const { currentSite } = useSites();

  const {
    fetchItems: fetchHolidays,
    items: holidays,
    isFetching: isHolidaysFetching,
    setFilters: setHolidaysFilters,
    setSort: setHolidaysSort,
  } = useList('holidays', {
    defaultFilters: {
    },
    cachePrefix: 'homeleaves',
  });

  useEffect(() => {
    setAbsentUsers([]);
    if (holidays && holidays.length) {
      holidays.forEach(holiday => {
        setAbsentUsers(absentUsers => {
          if (absentUsers?.filter(e => e === holiday.user.username).length === 0) {
            return ([...absentUsers, holiday.user]);
          } return (absentUsers);
        });
      });
    }
  }, [holidays, currentUser]);

  useEffect(() => {
    if (currentUser?.teams?.length) {
      setHolidaysFilters(
        {
          'user.teams.users_in': currentUser.id,
          start_date_lte: moment().endOf('day').toISOString(),
          end_date_gte: moment().startOf('day').toISOString(),
        // 'holidays_status.key': 'validated',
        },
      );
    } else if (currentUser?.site) {
      setHolidaysFilters(
        {
          'user.site_in': currentSite.id,
          start_date_lte: moment().endOf('day').toISOString(),
          end_date_gte: moment().startOf('day').toISOString(),
        //  'holidays_status.key': 'validated',
        },
      );
    } else {
      setHolidaysFilters(
        {
          start_date_lte: moment().endOf('day').toISOString(),
          end_date_gte: moment().startOf('day').toISOString(),
          //   'holidays_status.key': 'validated',
        },
      );
    }

    setHolidaysSort('created_at:DESC');
  }, [setHolidaysFilters, setHolidaysSort, currentUser, currentSite]);

  useEffect(() => {
    fetchHolidays({ pageSize: -1 });
  }, [fetchHolidays]);

  /* On vérifie que les utilisateurs n'apparaissent pas deux fois (pour 2 congés le même jour) */
  /*
   const [uniqueAbsentUsers, setUniqueAbsentUsers] = useState([]);
   const removeDuplicateObjectFromArray = (array, key) => {
        const check = {};
        const res = [];

        array.forEach(element => {
        if (!check[element[key]]) {
            check[element[key]] = true;
            res.push(element);
        }
        });
        return res;
  };

  useEffect(() => {
    setUniqueAbsentUsers(removeDuplicateObjectFromArray(absentUsers, 'name'));
  }, [absentUsers]); */

  const absenceTitle = () => {
    if (currentUser?.teams?.length) {
      return t('dashboard.inTeams');
    } if (currentUser?.site) {
      return t('dashboard.atSite');
    }
    return '';
  };

  return (
    <div style={{
      flex: 1,
      padding: currentUser.time_report === true ? '0 0.75rem 0 0' : 0,
      maxWidth: currentUser.time_report === true ? '50%' : '100%',
    }}
    >
      <Card>
        <div className="card-scroller">
          <div className="card-custom-header">
            <h2 className="is-2 title">
              {`Absents aujourd'hui ${absenceTitle()}`}
            </h2>
          </div>

          {isHolidaysFetching ? (
            <Loader isScreen permanant />
          ) : (
            <ul className="card-users-list">

              {!absentUsers?.length && (
                <li>
                  {t('dashboard.noAbsent')}
                </li>
              )}

              {absentUsers && (
                absentUsers.map((item, key) => (
                  <li key={key} className="card-listItem">
                    <div
                      className="user-avatar"
                      style={{
                        backgroundImage: item.avatar
                          ? `url(${process.env.REACT_APP_API_URL}${item.avatar.url})` : 'none',
                      }}
                      data-tooltip-bottom={item?.username}
                    >
                      {!item.avatar && item.username && (
                        <span>{item?.username[0]}</span>
                      )}
                    </div>
                  </li>
                ))
              )}
            </ul>
          )}

          <div className="card-footer">
            <Link className="button is-light" to="/holidays-planner">
              <i className="fas fa-plane" style={{ marginRight: '0.5rem' }} />
              {t('dashboard.allLeaves')}
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default HomeLeaves;
