import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useAuth } from '../../contexts/auth';
import Button from '../Button/Button';

const Article = ({
  isWiki,
  title,
  published,
  content,
  contributor,
  date,
  image,
  isPublished,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useAuth();
  const [formattedDate, setFormattedDate] = useState();
  const [formattedHour, setFormattedHour] = useState();

  useEffect(() => {
    if (date !== undefined) {
      setFormattedDate(date ? new Intl.DateTimeFormat({ dateStyle: 'full', timeStyle: 'long' })
        .format(new Date(date)) : '');
      setFormattedHour(date ? new Intl.DateTimeFormat('default', { hour: 'numeric', minute: 'numeric' })
        .format(new Date(date)) : '');
    }
  }, [date]);

  return (
    <div className={isWiki ? 'wiki-box' : (otherProps.isCard ? 'blog-card--box' : 'blog-box')}>
      <div className="columns">
        <div className={otherProps.isCard ? 'column' : 'column has-text'}>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <p className={isWiki ? 'wiki-title' : (otherProps.isCard ? 'blog-card--title' : 'blog-title')}>
              {!isPublished && (
                <>
                  <i className="fas fa-ban" style={{ opacity: 0.4 }} />
                &nbsp;
                </>
              )}
              {title}
            </p>

            {!isPublished && (
              <span className="status-tag" style={{ fontSize: '1em' }}>{t('common.draft')}</span>
            )}

            {otherProps.isClicked && isAdmin && (
              <div className="blog-actions">
                <Button
                  onClick={() => otherProps.handleModify(otherProps.id)}
                  icon="fa-pencil-alt"
                  color="primary"
                  transparent
                />
                <Button
                  onClick={() => otherProps.handleDelete(otherProps.id)}
                  icon="fa-trash-alt"
                  color="danger"
                  confirm
                  transparent
                  confirmMessage={t('common.delete')}
                />
              </div>
            )}
          </div>
          {otherProps.isBlog && contributor && (
            <>
              <div className="blog-pusblished">
                <i className="fa fa-clock" />
                {t('blog.publishedBy')}
                &nbsp;
                <span className={otherProps.isCard ? 'blog-card--contributor blog-contributor'
                  : 'blog-contributor'}
                >{contributor}
                </span>,
                &nbsp;
                {t('blog.publishedAt')}
                &nbsp;
                <span className={otherProps.isCard ? 'blog-card--date blog-date' : 'blog-date'}>
                  {`${formattedDate} ${t('common.at')} ${formattedHour}`}
                </span>
              </div>
            </>
          )}

          <ReactQuill
            value={content}
            readOnly
            theme="bubble"
          />

        </div>

        { image && image[0] && image[0].url && (
          <div className="column is-one-third has-image">
            <img src={`${process.env.REACT_APP_API_URL}${image[0].url}`} alt="illustration" />
          </div>
        ) }

      </div>
    </div>
  );
};

Article.prototypes = {
  isWiki: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.array,
  files: PropTypes.string,
  contributor: PropTypes.string,
  date: PropTypes.string,
  isPublished: PropTypes.bool,
};

Article.defaultProps = {
  isWiki: false,
  title: '',
  content: '',
  image: [],
  files: '',
  contributor: 'Unknown',
  date: '',
  isPublished: true,
};

export default Article;
