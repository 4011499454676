import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const CompanyForm = ({ onSubmit, company }) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => company?.id ? {
    ...company,
    sites: company?.sites.map((site) => site.id),
  } : {
    name: '',
    address: '',
    zipcode: '',
    city: '',
    sites: [],
  }, [company]);

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({
        handleSubmit, submitting, pristine,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />

          <div className="columns">
            <div className="column is-one-third">
              <Card>
                <FormInput
                  name="image"
                  label={t('company.image')}
                  type="image"
                />
              </Card>
            </div>
            <div className="column is-two-thirds">
              <Card>
                <FormInput
                  name="name"
                  label={t('company.name')}
                  required
                />
                <FormInput
                  name="address"
                  label={t('common.address')}
                  required
                />
                <FormInput
                  name="zipcode"
                  label={t('common.zipcode')}
                  type="number"
                  required
                />
                <FormInput
                  name="city"
                  label={t('common.city')}
                  required
                />
              </Card>
            </div>
          </div>

          <div className="stickySubmit">
            <FormInput
              type="submit"
              label={t('common.send')}
              icon="paper-plane"
              disabled={submitting || pristine}
              isSubmitting={submitting}
            />
          </div>

        </form>
      )}
    />
  );
};

CompanyForm.propTypes = {
  onSubmit: PropTypes.func,
  company: PropTypes.object,
};

CompanyForm.defaultProps = {
  onSubmit() { },
  company: {},
};

export default CompanyForm;
