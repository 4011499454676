import React, { createContext, useContext } from 'react';

import ContextProvider from 'common/contexts/crud';

const ArticlesContext = createContext();
const useArticles = () => useContext(ArticlesContext);

export const ArticlesProvider = (props) => <ContextProvider url="article" context={ArticlesContext} {...props} />;

export default useArticles;
