import Loader from 'common/components/Loader/Loader';
import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import useItem from 'common/hooks/useItem';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TaskTypeForm from 'requests/pages/TaskTypes/TaskTypeForm';

const TaskType = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const { id: taskTypeId } = router.match.params;

  const {
    saveItem, updateItem, item, error, isFetching,
  } = useItem('task-types', taskTypeId);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/task-types');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (dataTaskType) => {
    const taskType = { ...dataTaskType };

    if (taskType.id) {
      await updateItem(taskType.id, taskType);
    } else {
      await saveItem(taskType);
    }
    back();
  }, [updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/task-types',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      {isFetching ? (
        <Loader isScreen processing={isFetching} />
      ) : (
        <section className="section form-page has-cards">
          <TaskTypeForm onSubmit={onSubmit} taskType={item} />
        </section>
      )}
    </>
  );
};

export default TaskType;
