import PageHeader from 'common/components/PageHeader/PageHeader';
import AlertsContext from 'common/contexts/alerts';
import useRouter from 'common/hooks/use-router';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import HolidaysForm from 'requests/pages/Holidays/HolidaysForm';
import useItem from '../../../common/hooks/useItem';

const Holidays = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);
  const { id: holidayId } = router.match.params;
  const {
    saveItem,
    updateItem,
    item,
    error,
  } = useItem('holidays', holidayId);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t('common.saved'), 'success');
      if (router.history.action === 'POP') {
        router.push('/holidays');
      } else {
        router.history.goBack();
      }
    } else {
      setAlert(error, 'danger');
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(async (data) => {
    const holiday = { ...data };

    holiday.start_date = moment(holiday.start_date).startOf('day').format();
    holiday.end_date = moment(holiday.end_date).startOf('day').format();

    if (holiday.id) {
      await updateItem(holiday.id, holiday);
      back();
    } else {
      await saveItem(holiday);
      back();
    }
  }, [updateItem, back, saveItem]);

  return (
    <>
      <PageHeader
        title={t(`common.${item && item.id ? 'edit' : 'add'}`)}
        subLink={{
          url: '/holidays',
          icon: 'arrow-left',
          goBack: true,
          label: t('common.back'),
        }}
      />
      <section className="section form-page has-cards">
        <HolidaysForm onSubmit={onSubmit} holiday={item} />
      </section>
    </>
  );
};

export default Holidays;
