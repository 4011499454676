import Card from 'common/components/Card/Card';
import FormInput from 'common/components/FormInput/FormInput';
import FormScrollError from 'common/components/FormScrollError/FormScrollError';
// import Select from 'common/components/Select/Select';
import useAuth from 'common/contexts/auth';
import useFetch from 'common/hooks/useFetch';
// import useFetch from 'common/hooks/useFetch';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import HolidaysStatusSelect from 'requests/pages/Holidays/HolidaysStatusSelect';
import { computeDays } from 'requests/pages/Summary/summary.utils';
import UserSelect from 'users/components/UserSelect';
import HolidaysTypeSelect from './HolidaysTypeSelect';

const holidaysType = 'holidays_type';
const holidaysStatus = 'holidays_status';

const HolidaysForm = ({ onSubmit, holiday }) => {
  const { t } = useTranslation();
  const { currentCompany } = useCompanies();
  const { user: currentUser, isAdmin, isManager } = useAuth();
  const [selectedUser, setSelectedUser] = useState(holiday?.id ? holiday.user.id : currentUser.id);
  const { data: users } = useFetch('users', { cachePrefix: 'holidayusers' });
  // Permission TODO users chez tout le monde ??

  const balances = useMemo(() => {
    if (!isAdmin && !isManager) {
      return currentUser.holidayBalances;
    }
    const userTarget = users?.find((user) => user.id === selectedUser);

    return userTarget?.holidayBalances;
  }, [isAdmin, isManager, users, selectedUser, currentUser]);

  const initialValues = useMemo(() => holiday?.id ? {
    ...holiday,
    user: holiday.user.id,
    company: holiday?.company.id,
    holidays_type: holiday[holidaysType]?.id,
    holidays_status: holiday[holidaysStatus]?.id,
    description: holiday?.description,
  } : {
    user: currentUser.id,
    company: currentCompany.id,
    holidays_type: 3,
    holidays_status: 1,
    start_date: '',
    end_date: '',
    description: '',
    start_date_type: 'fullday',
    end_date_type: 'fullday',
    ...holiday,
  }, [holiday, currentUser, currentCompany]);

  const dateTypeStartOptions = useMemo(() => [
    { value: 'fullday', label: t('common.morning') },
    { value: 'afternoon', label: t('common.afternoon') },
  ], [t]);

  const dateTypeEndOptions = useMemo(() => [
    { value: 'morning', label: t('common.afternoon') },
    { value: 'fullday', label: t('common.evening') },
  ], [t]);

  if (holiday?.user?.id && currentUser.id !== holiday?.user?.id && !isAdmin && !isManager) {
    return <h1>{t('common.notAllowed')}</h1>;
  }

  return (
    <Form
      onSubmit={onSubmit}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      render={({
        handleSubmit, submitting, pristine, values,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <FormScrollError />
          <div className="columns">
            <div className="column is-two-thirds">
              <Card title={t('holidays.general')}>
                { !currentCompany && currentUser?.role?.name === 'superadmin' && (
                  <FormInput
                    type="custom"
                    name="company"
                    label={t('common.company')}
                  >
                    <CompanySelect />
                  </FormInput>
                )}

                {isAdmin && (
                  <FormInput
                    type="custom"
                    name="user"
                    label={t('common.user')}
                    required
                    onChange={setSelectedUser}
                  >
                    <UserSelect active={1} />
                  </FormInput>
                )}

                <FormInput
                  type="custom"
                  name="holidays_type"
                  label={t('common.type')}
                  required
                >
                  <HolidaysTypeSelect balances={balances} />
                </FormInput>

                <div className="date-item has-calendars-bottom">
                  <div className="date-item-calendar">
                    <FormInput
                      name="start_date"
                      label={`${t('date.from')}`}
                      type="date"
                      icon="calendar"
                      required
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <FormInput
                      type="buttongroup"
                      name="start_date_type"
                      label=" "
                      options={dateTypeStartOptions}
                      required
                    />
                  </div>
                </div>
                <div className="date-item has-calendars-bottom">
                  <div className="date-item-calendar">
                    <FormInput
                      name="end_date"
                      label={`${t('date.to')}`}
                      type="date"
                      icon="calendar"
                      required
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <FormInput
                      name="end_date_type"
                      label=" "
                      type="buttongroup"
                      options={dateTypeEndOptions}
                      required
                    />
                  </div>
                </div>

                {(values?.start_date && values?.end_date && values?.start_date_type && values?.end_date_type) && (
                  <div className="days-count-box">
                    {t('holidays.leaveDurationLabel')}
                      &nbsp;
                    <span className="solde-number">
                      {
                        computeDays({
                          start_date: values.start_date,
                          end_date: values.end_date,
                          start_date_type: values.start_date_type,
                          end_date_type: values.end_date_type,
                        })
                      }
                    </span>
                    <span>{t('common.days')}</span>
                  </div>
                )}

              </Card>
            </div>
            <div className="column is-one-third">
              {(isAdmin || isManager) && (
                <Card title={
                  t('holidays.validation')
                }
                >
                  <FormInput
                    type="custom"
                    name="holidays_status"
                  >
                    <HolidaysStatusSelect />
                  </FormInput>
                </Card>
              )}
              <Card title={t('holidays.infos')}>
                <FormInput
                  name="description"
                  label="Description"
                  type="textarea"
                  icon="sticky-note"
                />
                <FormInput
                  name="file"
                  label={t('common.attachment')}
                  icon="file"
                  type="file"
                />
              </Card>
            </div>
          </div>

          <div className="stickySubmit">
            <FormInput
              type="submit"
              label={t('common.send')}
              icon="paper-plane"
              disabled={submitting || pristine}
              isSubmitting={submitting}
            />
          </div>
        </form>
      )}
    />
  );
};

export default HolidaysForm;
