import defaultLogo from 'assets/default-logo.jpg';
import useAuth from 'common/contexts/auth';
import MenuContext from 'common/contexts/menu';
import useWindowSize from 'common/hooks/useWindowSize';
import getUserDisplayName from 'common/utils/getUserDisplayName';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CompanySelect from 'requests/components/CompanySelect';
import useCompanies from 'requests/contexts/companies';
import MenuLink from '../MenuLink';

const PositionTop = () => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const { toggleMenu } = useContext(MenuContext);
  const {
    user, signout, isAdmin,
  } = useAuth();

  const {
    currentCompany, selectCurrentCompany,
  } = useCompanies();
  const handleCompanyChange = useCallback((id, row) => {
    selectCurrentCompany(row?.company || null);
  }, [selectCurrentCompany]);

  const hasCompanyLogo = currentCompany?.image !== undefined
    ? currentCompany?.image[0]?.url : 'default-logo.png';

  return (
    <>
      <div className="menu-header">
        <div className="menu-user">
          {user && (
            <>
              <Link to="/login" className="menu-user--logout button is-danger is-transparent" onClick={signout}>
                <span className="icon">
                  <i className="fas fa-power-off" />
                </span>
              </Link>
              <span style={{ flex: 1, paddingLeft: 12 }}>
                <b>
                  {getUserDisplayName(user)}
                </b>
              </span>

              {currentCompany && isAdmin && (
                <MenuLink to="/account" className="menu-user--name">
                  <span className="icon" data-tooltip-bottom={t('common.account')}>
                    <i className="fas fa-cog" />
                  </span>
                </MenuLink>
              )}

              <MenuLink to={`/users/profile/${user.id}`} className="menu-user--name">
                <span className="icon" data-tooltip-bottom={t('common.profile')}>
                  <i className="fas fa-user" />
                </span>
              </MenuLink>
              {!size.isDesktop && (
                <button
                  onClick={toggleMenu}
                  className="menu-close-button"
                ><i className="fas fa-times" />
                </button>
              )}
            </>
          )}
        </div>
        <MenuLink className="menu-brand" to={currentCompany ? `/companies/edit/${currentCompany.id}` : '/'}>
          <div
            style={{
              backgroundImage: hasCompanyLogo
                ? `url(${process.env.REACT_APP_API_URL}${currentCompany?.image !== undefined
                  ? currentCompany?.image[0]?.url : 'default-logo.png'}`
                : `url(${defaultLogo}`,
            }}
            className="menu-brand-logo"
          />
          {currentCompany
            && isAdmin
            && (
              <div className="menu-company-edit-button">
                <i className="fas fa-pen" />
              </div>
            )}
        </MenuLink>
        <div className="menu-header--mask" />
      </div>
      {user?.role?.name === 'superadmin' && (
        <div className="menu-company-select">
          <CompanySelect
            onChange={handleCompanyChange}
            value={currentCompany?.id}
            placeholder={t('menu.selectCompany')}
          />
        </div>
      )}

    </>
  );
};

export default PositionTop;
